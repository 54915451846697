import { Component, OnInit } from '@angular/core';
import { Schedule } from 'src/app/shared/model/schedule';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { Utilities } from 'src/app/shared/utilities';

@Component({
  selector: 'app-report-schedule',
  templateUrl: './report-schedule.component.html',
  styleUrls: ['./report-schedule.component.scss']
})
export class ReportScheduleComponent implements OnInit {

  reportSchedules: Schedule[];
  nextGenerationSchedules: Schedule[];
  futureSchedules: Schedule[];
  completedSchedules: Schedule[];

  futureGenerationTime: number;

  constructor(private itsm: ItsmService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.loadSchedules();
  }

  loadSchedules(): void {
    this.futureGenerationTime = Utilities.getNextRoundHour();
    this.reportSchedules = undefined;
    this.nextGenerationSchedules = undefined;
    this.futureSchedules = undefined;
    this.completedSchedules = undefined;

    this.itsm.getReportSchedules()
      .then(schedules => {
        this.reportSchedules = schedules;
        this.completedSchedules = schedules.filter(s => s.status === 'completed');
        this.nextGenerationSchedules = schedules.filter(s => (s.time <= this.futureGenerationTime && s.status === 'in progress'));
        this.futureSchedules = schedules.filter(s => (s.time > this.futureGenerationTime  && s.status === 'in progress'));
      });
  }

  public deleteSchedule(s: Schedule) {
    // eslint-disable-next-line no-underscore-dangle
    const scheduleId = s._id;
    if(!scheduleId) {
      this.alertService.handlerError('no provided ID for schedule');
    } else {
      this.itsm.deleteSchedule(scheduleId)
        .then(_ => {
          this.loadSchedules();
        });
    }
  }

  public sortCompletedSchedulesByName() {
    this.completedSchedules.sort((s1, s2) => s1.filterParameters.pdfName.localeCompare(s2.filterParameters.pdfName));
  }

  public sortCompletedSchedulesByDate() {
    this.completedSchedules.sort((s1,s2) => s2.time - s1.time);
  }
}
