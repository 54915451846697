import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { OctaToken } from '../model/okta';
import { LocalStorageItem, SessionStorageItem } from '../model/shared-items';
import { oktaConstants } from '../constants';
import { CybersocxdrService } from './xtendedsoc/cybersocxdr.service';

@Injectable({ providedIn: 'root' })
export class UserAuthService {

  /**
   * OKTA AUTH initialisation
   */
  private CLIENT_ID = environment.oktaConfig.clientId;
  private ISSUER = environment.oktaConfig.issuer;
  private LOGIN_REDIRECT_URI = environment.oktaConfig.redirectUri;
  private LOGOUT_REDIRECT_URI = environment.oktaConfig.logoutRedirectUri;
  private ADMIN_REDIRECT_URI = environment.oktaConfig.adminRedirectUri;

  public oktaAuth = new OktaAuth({
    clientId: this.CLIENT_ID,
    issuer: this.ISSUER,
    redirectUri: this.LOGIN_REDIRECT_URI,
    postLogoutRedirectUri: this.LOGOUT_REDIRECT_URI,
    pkce: true,
    tokenManager: {
      storage: 'sessionStorage'
    }
  });

  public oktaOrgAdminAuthServer = new OktaAuth({
    clientId: this.CLIENT_ID,
    issuer: this.ISSUER.split('oauth')[0],
    responseType: 'token',
    redirectUri: this.ADMIN_REDIRECT_URI,
    postLogoutRedirectUri: this.LOGOUT_REDIRECT_URI,
    pkce: true,
    tokenManager: {
      storage: 'sessionStorage'
    }
  });  

  /**
   * USER CONTEXT
   */
  private _userDetails;
  public get userDetails() { return this._userDetails; }
  public set userDetails(value) { this._userDetails = value; }

  private _availableTenants: Array<string>;
  public get availableTenants() { return this._availableTenants; }
  public set availableTenants(value) { this._availableTenants = value; }

  private _selectedTenant: string;
  public get selectedTenant() { return this._selectedTenant; }
  public set selectedTenant(value) { this._selectedTenant = value; }

  public tenantNameAdmin: string;
  public pageMSRAccess = false;
  public userAdmin = false;
  public $isAuthenticated: Observable<boolean>;
  private observer: Observer<boolean>;

  public accesses = {
    analytics: false,
    MSR: false,
    SLA: false,
    microSOCXDR: false
  }  

  constructor(
    private router: Router,
    private alertService: AlertService,
    private cybersocxdrService: CybersocxdrService,
  ) {
    this.oktaAuth.start();
    // Triggered when an OAuthError is returned via the API (typically during token renew)
    this.oktaAuth.tokenManager.on('error', err => {
      if (err && err.errorCode === 'login_required' &&
        err.message === 'The client specified not to prompt, but the user is not logged in.') {
        this.login();
      }
    });

    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(val => {
        observer.next(val);
      });
    });

  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this.oktaAuth.tokenManager.get(OctaToken.accessToken));
  }

  login(originalUrl?: string) {
    // Save current URL before redirect
    sessionStorage.setItem(SessionStorageItem.oktaAppUrl, originalUrl || this.router.url);

    // Launches the login redirect.
    this.oktaAuth.token.getWithRedirect({
      scopes: ['openid', 'email', 'profile', 'dac.admin']
    });
  }

  async loadUserDetails() {
    try {
      if (this.isAuthenticated()) {
        return this.oktaAuth.getUser();
      }
      return undefined;
    } catch (error) {
      this.alertService.handlerError(error);
    }
  }

  /**
   * trigger all methods used for tenants initialisation
   */
  public initUserTenants() {
    this.initAvailableTenants();
    this.initSelectedTenant();
  }

  /**
   * trigger all methods used for roles initialisation
   */
  public initUserRoles() {
    this.setUserAdmin();

    this.accesses.analytics = this.userDetails.groups.some(g => g.endsWith('_Access_Page_Analytics'));
    this.accesses.MSR = this.userDetails.groups.includes('APP_MSR_USERS');
    const hasMSOCXDRRole = this.userDetails.groups.some(g => g.endsWith('_MicroSOCXDR'));
    if(hasMSOCXDRRole) {
      this.cybersocxdrService.isMicrosocXDRosmClient().then(result => {
        this.accesses.microSOCXDR = result;
      });
    }
  }

  /**
   * set availableTenants from userdetails groups
   */
  initAvailableTenants() {
    if (this.userDetails.groups.filter(g => g.startsWith('USERS_')).length > 0) {
      const tenantNames = [];
      this.userDetails.groups.filter(g => g.startsWith('USERS_')).forEach(g => {
        tenantNames.push(g.split('_')[1]);
      });
      this.availableTenants = tenantNames;
    }
  }

  /**
   * Handle the case where selectedTenant in storage is faulty
   * Can happen when user has only one tenant (so won't set their tenant through the select tenant page)
   * and was affected to a new tenant instead of the one stored in local storage.
   * In this case, will affect the unique okta tenant as selected tenant and store it in local storage
   */
  initSelectedTenant() {
    const selectedTenantKey = localStorage.getItem(LocalStorageItem.oktaSelectedTenant);
    let storageSelectedTenant: string;
    if (selectedTenantKey) {
      try {
        storageSelectedTenant = JSON.parse(selectedTenantKey);
      } catch (error) {
        localStorage.removeItem(LocalStorageItem.oktaSelectedTenant);
        this.alertService.handlerError(error);
      }
    }
    if (this.availableTenants.length === 1 && (storageSelectedTenant === undefined || !this.availableTenants.includes(storageSelectedTenant))) {
      this.selectedTenant = this.availableTenants[0];
      localStorage.setItem(LocalStorageItem.oktaSelectedTenant, JSON.stringify(this.selectedTenant));
    } else {
      this.selectedTenant = storageSelectedTenant;
    }
  }


  /**
   * check and initialize value if user is an admin
   */
  private setUserAdmin(): void {
    if (
      this.userDetails.groups.filter((g) => g.startsWith('ADMINS_')).length > 0
    ) {
      this.tenantNameAdmin = this.userDetails.groups
        .filter((g) => g.startsWith('ADMINS_'))[0]
        .split('_')[1];
      // check to see if multiple tenants are available, and if one has been selected
      const availableTenants = this.availableTenants;
      // if the user is an admin, and can see multiple tenants then enable the change tenants feature
      if (availableTenants && availableTenants.length > 1) {
        // if the user is an admin of the selected tenant then allow the admin menu item
        if (this?.selectedTenant === this.tenantNameAdmin) {
          this.userAdmin = true;
        }
      } else {
        // if the user is an admin but is only a user for one tenant then allow it
        this.userAdmin = true;
      }
    } else {
      // if the user is not an admin
      this.userAdmin = false;
    }
  }

  storeOktaAdminTenantInfo(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.oktaAuth.getUser().then((res: any) => {
        if (res.groups.filter(g => g.startsWith('USERS_')).length > 1) {
          const tenantNames = [];
          res.groups.filter(g => g.startsWith('USERS_')).forEach(g => {
            tenantNames.push(g.split('_')[1]);
          });
          this.availableTenants = tenantNames;
        }

        resolve(0);
      });
    });
  }

  storeOktaAdminInfoRedirect(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.oktaAuth.getUser().then((res: any) => {
        if (res.groups.filter(g => g.startsWith('USERS_')).length > 1) {
          const tenantNames = [];
          res.groups.filter(g => g.startsWith('USERS_')).forEach(g => {
            tenantNames.push(g.split('_')[1]);
          });
          this.availableTenants = tenantNames;
        }

        // does the user belong to any admin group
        if (res.groups.filter(g => g.startsWith('ADMINS_')).length === 1) {
          // getting a token for API management scopes can only be done from the org auth server
          this.oktaOrgAdminAuthServer.token.getWithRedirect({
            scopes: ['okta.users.manage', 'okta.groups.manage']
          })
            .catch(err => console.log(err));
        } else if (res.groups.filter(g => g.startsWith('ADMINS_')).length > 1) {
          this.alertService.handlerError('You appear to be an admin on multiple tenants. Please contact Service Management');
        }

        resolve(0);
      });
    });
  }


  getAccessToken(): string {
    return this.oktaAuth.getAccessToken();
  }

  getToken(token: string): Promise<any> {
    return this.oktaAuth.tokenManager.get(token);
  }

  async handleAuthentication() {
    return this.oktaAuth.token.parseFromUrl().then(tokenContainer => {
      this.oktaAuth.tokenManager.add(OctaToken.idToken, tokenContainer.tokens.idToken);
      this.oktaAuth.tokenManager.add(OctaToken.accessToken, tokenContainer.tokens.accessToken);

      if (this.isAuthenticated()) {
        this.observer.next(true);
      }

      // Retrieve the saved URL and navigate back
      const url = sessionStorage.getItem(SessionStorageItem.oktaAppUrl);
      this.router.navigateByUrl(url);
      return true;
    }).catch(err => {
      if (err &&
        (err.errorSummary.includes('Unable to parse a token from the url') ||
          err.errorSummary.includes('User is not assigned to the client application'))) {
        this.observer.next(false);
        this.router.navigateByUrl('/not-assigned');
      }
      return false;
    });
  }

  async logout() {
    this.oktaAuth.stop();
    await this.oktaAuth.signOut();
    this.oktaAuth.tokenManager.clear();

    this.clearStore();
  }

  public clearStore() {

    // get the keys in session storage
    let sessionStorageKeys = Object.keys(sessionStorage);
    let localStorageKeys = Object.keys(localStorage);

    // exclude "okta" related keys (also used for tenant selection)
    sessionStorageKeys = sessionStorageKeys.filter(k => k.indexOf('okta') !== 0);

    localStorageKeys = localStorageKeys.filter(k => {
      const isOktaKey = k.indexOf('okta') === 0;
      const isExcludedKey = oktaConstants.localStorageExcludedKeys.indexOf(k) !== -1;
      return !isOktaKey && !isExcludedKey
    });

    // delete all other keys
    sessionStorageKeys.forEach(k => sessionStorage.removeItem(k));
    localStorageKeys.forEach(k => localStorage.removeItem(k));

  }

  async isUserAdminOnCurrentTenant(): Promise<boolean> {
    const adminTenants = this._userDetails.groups.filter((g: string) => g.startsWith('ADMINS_')).map((g: string) => g.split('_')[1]);
    return (this.selectedTenant && adminTenants.includes(this.selectedTenant));
  }

  getUserGroupsFromDetails(userDetails): string[] | undefined {
    if (userDetails.groups) {
      return userDetails.groups
      .filter((g: string) => g.startsWith('APPROLE_') || g.startsWith('COMPROLE_'))
      .map(g => /^[^_]*_[^_]*_(.*)$/.exec(g)[1]);
    }
    return undefined;
  }

}
