import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Case } from '../../model/itsm';

@Component({
  selector: 'app-support-grid',
  templateUrl: './support-grid.component.html',
  styleUrls: ['./support-grid.component.scss']
})
export class SupportGridComponent implements OnInit {

  @Input()
  cases: Case[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Input()
  loaded: boolean;

  @Input()
  scrollLoading: boolean;

  @Output()
  flagEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  flagCase(event, caseRef: string): boolean {
    event.stopPropagation();
    this.flagEmitter.emit(caseRef);
    return false;
  }

}
