<div [ngClass]="contextService.bgTheme">
  <app-user-navbar [accessRestricted]="true"></app-user-navbar>

  <div class="row">
    <div class="col-xl-1 col-lg-1 hidden-md"></div>
    <div class="col-xl-10 col-lg-10 col-12">
      <div class="">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="col-xl-1 col-lg-1 hidden-md"></div>
  </div>

  <app-alert></app-alert>

  <app-banner></app-banner>

  <div
    class="page"
    [ngClass]="contextService.fullWidth ? 'noMargin' : 'withMargin'"
  >
    <!-- left margin -->
    <div></div>
    <!-- content -->
    <div>
      <router-outlet></router-outlet>
    </div>
    <!-- right margin-->
    <div></div>
  </div>

  <div aria-label="Back to top" class="back-to-top" (click)="scrollUp()">
    <a
      class="back-to-top-link btn btn-icon btn-secondary"
      title="Back to top"
    ></a>
  </div>
  <app-footer></app-footer>
</div>
