<nav role="navigation" aria-labelledby="breadcrumb-intro-3">
    <p class="visually-hidden" id="breadcrumb-intro-3">{{'pages.shared.userLocation' | translate }}</p>
    <ol class="breadcrumb">
        <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                <a [href]='url.url' *ngIf="url.url">{{url.title | translate}}</a>
                <span *ngIf="!url.url">{{url.title | translate}}</span>
            </li>
            <li class="breadcrumb-item active" *ngIf="last">{{url.title | translate}}</li>
        </ng-template>
    </ol>
</nav>
