import { Pipe, PipeTransform } from '@angular/core';
import { TimeStamp } from '../model/worldwatch';

@Pipe({
    name: 'offsetDatetimePipe',
    standalone: true,
    pure: false
})
export class OffsetDatetimePipe implements PipeTransform {

    /**
     * format the CERT offsetdateTime to readable date format
     */
    transform(date: TimeStamp): any {
        const ret = this.format(date.dayOfMonth) + '/' + this.format(date.monthValue) + '/' + this.format(date.year) + " " + this.format(date.hour) + ":" + this.format(date.minute);
        return ret;
    }

    format(value) {
        const updateNumber = typeof value === 'number' && value < 10;
        const updateString = typeof value === 'string' && value.length === 1;
        return updateNumber || updateString ? '0' + value : value;
    }
}
