import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    // if connecting to an endpoint then add okta token
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {

                // unauthorized or forbidden http response
                if([401, 403].includes(error.status)) {
                    this.router.navigateByUrl('/not-assigned');
                }
                //throw error as per requirement
                return throwError(() => error);
            })
        );
    }
    
}
