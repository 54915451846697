<div class="container">
  <!-- Parameters Zone -->
  <div id="mainNavbar" class="mainNavbar">
    <ul class="nav nav-tabs bg-dark">
      <li class="nav-item">
        <a class="nav-link active" data-bs-toggle="tab" type="button" role="tab" id="general-panel"
          href="#general-panel-content" aria-controls="general-nav">{{'pages.updates.certTools.dynamicMFD.upload.general' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" type="button" role="tab" id="dumps-panel" href="#dumps-panel-content"
          aria-controls="dumps-nav">{{'pages.updates.certTools.dynamicMFD.upload.dumps' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" type="button" role="tab" id="advanced-panel"
          href="#advanced-panel-content" aria-controls="advanced-nav">{{'pages.updates.certTools.dynamicMFD.upload.advanced' | translate }}</a>
      </li>
    </ul>
  </div>
  <div id="settings">

    <!-- Tabs content -->
    <div class="tab-content">

      <!-- GENERAL TAB -->
      <div class="tab-pane fade show active" id="general-panel-content" role="tabpanel" aria-labelledby="general-nav">
        <div class="accordion" id="generalAccordionPanels">
          <form [formGroup]="generalForm">
            <!-- ANALYSIS-->
            <div class="accordion-item">
              <span class="accordion-header" id="analysis-panel">
                <span class="accordion-span" data-bs-toggle="collapse" data-bs-target="#analysis-panel-collapse"
                  aria-expanded="true" aria-controls="analysis-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.analysis' | translate }}
                </span>
              </span>
              <div id="analysis-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="analysis-panel">
                <div class="accordion-body">
                  <div class="grid-column-2" id="analysis">
                    <!-- timeout -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.timeout' | translate }}</label>
                      <input class="form-control" type="number" min="10" max="600" step="10" formControlName="timeout"
                        [ngClass]="{'is-invalid': timeout.errors}">
                    </div>
                    <!-- VM templates -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.vm' | translate }}</label>
                      <select class="form-select" aria-label="select-VM" formControlName="vm">
                        <option *ngFor="let vm of vms" value={{vm.value}}>{{vm.label}}</option>
                      </select>
                    </div>
                    <!-- zip password -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.customPassword' | translate }}</label>
                      <div class="inline">
                        <input type="password" id=password class="form-control" placeholder="Password"
                          formControlName="customPassword">
                        <fa-icon icon="eye" size="lg" id="togglePassword" data-toggle="tooltip"
                          (click)="revalPassword()" data-placement="top" title="show password"></fa-icon>
                      </div>
                    </div>
                    <!-- direct interaction VM -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.useVnc' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="useVnc">
                    </div>
                    <!-- Free mode -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.freeMode' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="freeMode">
                    </div>
                    <!-- Light mode -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.light' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="light">
                    </div>
                    <!-- Taint analysis mode -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.useTaintAnalysis' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="useTaintAnalysis">
                    </div>
                    <!-- Auto start upload -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.autoStart' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="autoStart">
                    </div>
                    <!-- Browser to use -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.useBankerEnvironment' | translate }}</label>
                      <select class="form-select" aria-label="select-VM" formControlName="useBankerEnvironment">
                        <option *ngFor="let env of useBankerEnvironment" value={{env.value}}>{{env.label}}</option>
                      </select>
                    </div>
                    <!-- Share with others -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.isPublic' | translate }}</label>
                      <input class="form-check-input active" type="checkbox" formControlName="isPublic">
                    </div>
                    <!-- Shared analysis -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.shared' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="shared">
                    </div>
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.confinedMode' | translate }} -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.confinedMode' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="confinedMode">
                    </div>
                    <!-- Route traffic through tor -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.torMode' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="torMode">
                    </div>
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.targettedCountry' | translate }} -->
                    <div>
                      <div class="inline">
                        <label style="width: 100%;">{{'pages.updates.certTools.dynamicMFD.upload.targettedCountry' | translate }}</label>
                        <a rel="noopener noreferrer" href="https://www.science.co.il/language/Locale-codes.php"
                          target="_blank">
                          <fa-icon icon="info-circle" size="lg" data-toggle="tooltip" (click)="revalPassword()"
                            data-placement="top"
                            title="see https://www.science.co.il/language/Locale-codes.php"></fa-icon>
                        </a>
                      </div>
                      <select class="form-select" aria-label="select-VM" formControlName="targettedCountry">
                        <option *ngFor="let country of targettedCountry" [ngValue]="country.value">{{country.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- TIME -->
            <div class="accordion-item">
              <span class="accordion-header" id="time-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#time-panel-collapse" aria-expanded="true" aria-controls="time-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.time' | translate }}
                </span>
              </span>
              <div id="time-panel-collapse" class="accordion-collapse collapse show" aria-labelledby="time-panel">
                <div class="accordion-body">
                  <div class="grid-column-2" id="time">
                    <!-- Force system time to -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.timeSystemNewDate' | translate }}</label>
                      <input class="form-control" type="date" formControlName="timeSystemNewDate">
                    </div>
                    <!-- Trigger scheduled tasks immediately -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.timeAutotriggerScheduledTasks' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="timeAutotriggerScheduledTasks">
                    </div>
                    <!-- Time flow factor -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.timeAccelerationFactor' | translate }}</label>
                      <input class="form-control" type="number" min="1" max="16" step="1"
                        formControlName="timeAccelerationFactor"
                        [ngClass]="{'is-invalid': timeAccelerationFactor.errors}">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- DETECTION -->
            <div class="accordion-item">
              <span class="accordion-header" id="detection-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#detection-panel-collapse" aria-expanded="true"
                  aria-controls="detection-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.detection' | translate }}
                </span>
              </span>
              <div id="detection-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="detection-panel">
                <div class="accordion-body">
                  <div class="grid-column-2" id="detection">
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.rulesets' | translate }} -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.rulesets' | translate }} </label>
                      <select class="form-select" formControlName="rulesets">
                        <option selected>OCD</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- DUMPS TAB -->
      <div class="tab-pane fade" id="dumps-panel-content" role="tabpanel" aria-labelledby="dumps-nav">
        <div class="accordion" id="dumpsAccordionPanels">
          <form [formGroup]="dumpsForm">
            <!-- ARTIFACTS-->
            <div class="accordion-item">
              <span class="accordion-header" id="analysis-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#analysis-panel-collapse" aria-expanded="true"
                  aria-controls="analysis-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.artifacts' | translate }}
                </span>
              </span>
              <div id="analysis-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="analysis-panel">
                <div class="accordion-body">
                  <div class="grid-column-2" id="analysis">
                    <!-- Extract file artifacts -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableFileDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableFileDumps">
                    </div>
                    <!-- Extract network dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableNetworkDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableNetworkDumps">
                    </div>
                    <!-- Extract registry dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableRegistryDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableRegistryDumps">
                    </div>
                    <!-- Extract cryptographic buffers -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableCryptoDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableCryptoDumps">
                    </div>
                    <!-- Take screenshot -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnablesScreenDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnablesScreenDumps">
                    </div>
                    <!-- Screenshots vertical resolution -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpScreenYResolution' | translate }}</label>
                      <input class="form-control" type="number" min="100" max="1200" step="100" class="form-control"
                        formControlName="dumpScreenYResolution"
                        [ngClass]="{'is-invalid': dumpScreenYResolution.errors}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Process dumps -->
            <div class="accordion-item">
              <span class="accordion-header" id="time-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#time-panel-collapse" aria-expanded="true" aria-controls="time-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.processDumps' | translate }}
                </span>
              </span>
              <div id="time-panel-collapse" class="accordion-collapse collapse show" aria-labelledby="time-panel">
                <div class="accordion-body">
                  <div class="grid-column-2" id="time">
                    <!-- Enable PE image dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableImageDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableImageDumps">
                    </div>
                    <!-- Enable memory dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableMemoryDumps' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpEnableMemoryDumps">
                    </div>
                    <!-- Enable memory dumps for url analysis -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpEnableMemoryDumpsUrlAnalysis' | translate }}</label>
                      <input class="form-check-input" type="checkbox"
                        formControlName="dumpEnableMemoryDumpsUrlAnalysis">
                    </div>
                    <!-- Scan memory dumps for embedded PE objects -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpMemoryScanForSubobjects' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="dumpMemoryScanForSubobjects">
                    </div>
                    <!-- IAT reconstruction for PE image dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpImageApisResolveIat' | translate }}</label>
                      <select class="form-select" aria-label="select-VM" formControlName="dumpImageApisResolveIat">
                        <option *ngFor="let recon of iatReconstruction" value={{recon.value}}>{{recon.label}}</option>
                      </select>
                    </div>
                    <!-- IAT reconstruction for memory dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpMemoryApisResolveIat' | translate }}</label>
                      <select class="form-select" aria-label="select-VM" formControlName="dumpMemoryApisResolveIat">
                        <option *ngFor="let recon of iatReconstruction" value={{recon.value}}>{{recon.label}}</option>
                      </select>
                    </div>
                    <!-- Maximum size of a single dump -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpMaximumSizeSingle' | translate }}</label>
                      <input class="form-control" type="number" min="10" max="1000" step="10"
                        ngClass="dumpMaximumSizeSingle." class="form-control" formControlName="dumpMaximumSizeSingle"
                        [ngClass]="{'is-invalid': dumpMaximumSizeSingle.errors}">
                    </div>
                    <!-- Maximum cumulated size of all dumps for a single process -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpMaximumSizePerProcess' | translate }}</label>
                      <input class="form-control" type="number" min="10" max="1000" step="20" class="form-control"
                        formControlName="dumpMaximumSizePerProcess"
                        [ngClass]="{'is-invalid': dumpMaximumSizePerProcess.errors}">
                    </div>
                    <!-- Maximum cumulated size of all dumps -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpMaximumSizeTotal' | translate }}</label>
                      <input class="form-control" type="number" min="10" max="1000" step="10" class="form-control"
                        formControlName="dumpMaximumSizeTotal" [ngClass]="{'is-invalid': dumpMaximumSizeTotal.errors}">
                    </div>
                    <!-- Discard dumps if less than this many % different bytes -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.dumpDiscardIfDeltaUnderPermillion' | translate }}</label>
                      <input class="form-control" type="number" min="1" max="33" class="form-control"
                        formControlName="dumpDiscardIfDeltaUnderPermillion"
                        [ngClass]="{'is-invalid': dumpDiscardIfDeltaUnderPermillion.errors}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- ADVANCED TAB-->
      <div class="tab-pane fade" id="advanced-panel-content" role="tabpanel" aria-labelledby="advanced-nav">
        <div class="accordion" id="advancedAccordionPanels">
          <form [formGroup]="advancedForm">

            <!-- Processes to analyze -->
            <div class="accordion-item">
              <span class="accordion-header" id="processes-to-analyze-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#processes-to-analyze-panel-collapse" aria-expanded="true"
                  aria-controls="processes-to-analyze-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.processesToAnalyze' | translate }}
                </span>
              </span>
              <div id="processes-to-analyze-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="processes-to-analyze-panel">
                <div class="accordion-body">
                  <div class="simple-row">
                    <!-- Process names -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.followProcess' | translate }}</label>
                      <input class="form-control" type="text" class="form-control" formControlName="followProcess">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Executable parameters -->
            <div class="accordion-item">
              <span class="accordion-header" id="executable-parameters-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#executable-parameters-panel-collapse" aria-expanded="true"
                  aria-controls="executable-parameters-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.executableParameters' | translate }}
                </span>
              </span>
              <div id="executable-parameters-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="executable-parameters-panel">
                <div class="accordion-body">
                  <div class="simple-row" id="time">
                    <!-- Custom parameters -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.extraArgs' | translate }}</label>
                      <input class="form-control" type="text" class="form-control" formControlName="extraArgs">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Custom command line -->
            <div class="accordion-item">
              <span class="accordion-header" id="custom-command-line-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#custom-command-line-panel-collapse" aria-expanded="true"
                  aria-controls="custom-command-line-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.customCommandLineLab' | translate }}
                </span>
              </span>
              <div id="custom-command-line-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="custom-command-line-panel">
                <div class="accordion-body">
                  <div class="simple-row">
                    <!-- Command line -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.customCommandLine' | translate }}</label>
                      <input class="form-control" type="text" class="form-control" formControlName="customCommandLine">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Hooks to enable -->
            <div class="accordion-item">
              <span class="accordion-header" id="hooks-to-enable-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#hooks-to-enable-panel-collapse" aria-expanded="true"
                  aria-controls="hooks-to-enable-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.hooksToEnable' | translate }}
                </span>
              </span>
              <div id="hooks-to-enable-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="hooks-to-enable-panel">
                <div class="accordion-body">
                  <div class="simple-row">
                    <!-- Hooks groups -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.hooks' | translate }}</label>
                      <ng-multiselect-dropdown style="width: 50%;" [settings]="dropdownSettingsHooks" [data]="hooks"
                        formControlName="hooks" name="vendorFilter">
                      </ng-multiselect-dropdown>
                    </div>
                    <!-- Disable all hooks -->
                    <div>
                      <label>Disable all hooks</label>
                      <input class="form-check-input" type="checkbox" formControlName="disableHooks">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Counter-measures -->
            <div class="accordion-item">
              <span class="accordion-header" id="counter-measures-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#counter-measures-panel-collapse" aria-expanded="true"
                  aria-controls="counter-measures-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.counterMeasures' | translate }}
                </span>
              </span>
              <div id="counter-measures-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="counter-measures-panel">
                <div class="accordion-body">
                  <div class="grid-column-2">
                    <!-- Use anti anti-VMs -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.antiAntiVm' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="antiAntiVm">
                    </div>
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.antiFingerprint ' | translate }} -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.antiFingerprint' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="antiFingerprint">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Time -->
            <div class="accordion-item">
              <span class="accordion-header" id="advanced-time-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#advanced-time-panel-collapse" aria-expanded="true"
                  aria-controls="advanced-time-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.time' | translate }}
                </span>
              </span>
              <div id="advanced-time-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="advanced-time-panel">
                <div class="accordion-body">
                  <div class="grid-column-2">
                    <!-- System time -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.timeSystemUsePeheaderTime' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="timeSystemUsePeheaderTime">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Monitoring -->
            <div class="accordion-item">
              <span class="accordion-header" id="monitoring-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#monitoring-panel-collapse" aria-expanded="true"
                  aria-controls="monitoring-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.monitoring' | translate }}
                </span>
              </span>
              <div id="monitoring-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="monitoring-panel">
                <div class="accordion-body">
                  <div class="grid-column-2">
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.env' | translate }} -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.env' | translate }}</label>
                      <select class="form-select" aria-label="select-VM" formControlName="env">
                        <option *ngFor="let env of specificLaunchEnv" value={{env.value}}>{{env.label}}</option>
                      </select>
                    </div>
                    <!-- {{'pages.updates.certTools.dynamicMFD.upload.monitorHeapExecute' | translate }} -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.monitorHeapExecute' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="monitorHeapExecute">
                    </div>
                    <!-- Monitor In Memory PE headers -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.monitorPeHeaders' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="monitorPeHeaders">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Debug -->
            <div class="accordion-item">
              <span class="accordion-header" id="debug-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#debug-panel-collapse" aria-expanded="true" aria-controls="debug-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.debug' | translate }}
                </span>
              </span>
              <div id="debug-panel-collapse" class="accordion-collapse collapse show" aria-labelledby="debug-panel">
                <div class="accordion-body">
                  <div class="grid-column-2">
                    <!-- Assume FAIL if windows error reporting dialog appears -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.errorBreakOnWerfault' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="errorBreakOnWerfault">
                    </div>
                    <!-- Assume FAIL if an uncatched exception is thrown by a monitored process -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.errorBreakOnUncatchedExceptions' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="errorBreakOnUncatchedExceptions">
                    </div>
                    <!-- Trace EVERY API call in log file -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.traceApiCalls' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="traceApiCalls">
                    </div>
                    <!-- Trace EVERY Windows COM call in log file -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.traceComCalls' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="traceComCalls">
                    </div>
                    <!-- Log verbosity -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.verbositylevel' | translate }}</label>
                      <input class="form-control" type="number" min="0" max="7" value="5" class="form-control"
                        formControlName="verbositylevel">
                    </div>
                    <!-- Exit as soon as a possible after a confidente guess -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.fastExitConfidence' | translate }}</label>
                      <input class="form-check-input" type="checkbox" formControlName="fastExitConfidence">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Taint Options -->
            <div class="accordion-item">
              <span class="accordion-header" id="taint-options-panel">
                <span class="accordion-span" type="button" data-bs-toggle="collapse"
                  data-bs-target="#taint-options-panel-collapse" aria-expanded="true"
                  aria-controls="taint-options-panel-collapse">
                  {{'pages.updates.certTools.dynamicMFD.upload.taintOptions' | translate }}
                </span>
              </span>
              <div id="taint-options-panel-collapse" class="accordion-collapse collapse show"
                aria-labelledby="taint-options-panel">
                <div class="accordion-body">
                  <div class="grid-column-2">
                    <!-- Minimum size of buffers to dump -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.taintAnalysisMinBufferSize' | translate }}</label>
                      <input class="form-control" type="number" min="0" max="4096" step="128" value="2048"
                        class="form-control" formControlName="taintAnalysisMinBufferSize">
                    </div>
                    <!-- Maximum size of the entire zlog file -->
                    <div>
                      <label>{{'pages.updates.certTools.dynamicMFD.upload.taintAnalysisMaxZlogSize' | translate }}</label>
                      <input class="form-control" type="number" min="0" max="40000" value="100" step="100"
                        class="form-control" formControlName="taintAnalysisMaxZlogSize">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- file zone -->
  <div id="fileZone" class="fileZone">
    <!-- SCAN FILE -->
    <app-drag-and-drop [validForm]="fileSettingsForm.valid" [(scanFiles)]="scanFiles" [(mfdQuota)]="mfdQuota" (submitForm)="onSubmit()"
      (clickFile)="openScanReport($event)" (refreshFile)="refreshFile($event, true)"></app-drag-and-drop>
  </div>
</div>
