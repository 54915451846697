import { Component, Input, OnDestroy } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';
import { XtendedFaqListComponent } from './faq/list/xtended-faq-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { XtendedFaqPreviewComponent } from './faq/preview/xtended-faq-preview.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-xtended-help',
  standalone: true,
  imports: [
    CommonModule,
    XtendedFaqListComponent,
    XtendedFaqPreviewComponent,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
  ],
  templateUrl: './xtended-help.component.html',
  styleUrls: ['./xtended-help.component.scss'],
})
export class XtendedHelpComponent implements OnDestroy {
  @Input() isHelpOpen: boolean = false;

  faqs: Array<{ question: string; answer: string }> = [
    {
      question: 'pages.cybersocxdr.faq.questions.reportContent.question',
      answer: 'pages.cybersocxdr.faq.questions.reportContent.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.howToGenerateReport.question',
      answer: 'pages.cybersocxdr.faq.questions.howToGenerateReport.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.mainSections.question',
      answer: 'pages.cybersocxdr.faq.questions.mainSections.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.homePage.question',
      answer: 'pages.cybersocxdr.faq.questions.homePage.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.incidentManagement.question',
      answer: 'pages.cybersocxdr.faq.questions.incidentManagement.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.fourAThreeW.question',
      answer: 'pages.cybersocxdr.faq.questions.fourAThreeW.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.slaMetrics.question',
      answer: 'pages.cybersocxdr.faq.questions.slaMetrics.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.dashboardCustomization.question',
      answer: 'pages.cybersocxdr.faq.questions.dashboardCustomization.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.contractSelection.question',
      answer: 'pages.cybersocxdr.faq.questions.contractSelection.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.feedback.question',
      answer: 'pages.cybersocxdr.faq.questions.feedback.answer',
    },
  ];

  filteredFaqs: Array<{ question: string; answer: string }> = this.faqs;
  searchTerm: string = '';
  langChangeSubscription: Subscription;

  selectedFaqIndex: number | null = null;

  constructor(public contextService: ContextService, private translate: TranslateService) {
    this.langChangeSubscription = translate.onLangChange.subscribe(() => {
      this.onSearch();
    });
  }

  viewFaq(index: number): void {
    this.selectedFaqIndex = index;
  }

  onBack(): void {
    this.selectedFaqIndex = null;
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  onSearch() {
    if (this.searchTerm) {
      this.filteredFaqs = this.faqs.filter(
        (faq) =>
          this.translate.instant(faq.question).toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          this.translate.instant(faq.answer).toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.filteredFaqs = this.faqs;
    }
  }
}
