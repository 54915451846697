<div class="tpvsfp-widget">

    <h3 *ngIf="loaded" class="title">
        {{'pages.dashboard.widgets.truePositivesVSFalsePositivesTitle' | translate}}
        <app-help message="{{ 'pages.dashboard.widgets.truePositivesVSFalsePositivesDesc' | translate }}"></app-help>
    </h3>

    <ngb-carousel #carousel
        [interval]="slideInterval"
        [pauseOnHover]="true"
        [animation]="false"
        [showNavigationIndicators]="false"
        *ngIf="!errorMessage && loaded; else showError;"
        (slide)="onChangeChart($event)">
        <ng-template ngbSlide *ngFor="let chart of chartList; index as i">
            <app-tpvsfp-chart *ngIf="selectedChart==='tpvsfp'"
                [widgetFormat]="true"
                [data]="chartsData.data"
                [monthIndex]="chartsData.monthIndex"
                [width]="chartsWidth"
                [height]="chartsHeight"
                [theme]="theme">
            </app-tpvsfp-chart>
            <app-threat-actor-chart *ngIf="selectedChart==='actor'"
                [widgetFormat]="true"
                [data]="chartsData.data"
                [monthIndex]="chartsData.monthIndex"
                [width]="chartsWidth"
                [height]="chartsHeight"
                [theme]="theme">
            </app-threat-actor-chart>
            <app-threat-killchain-chart *ngIf="selectedChart==='killchain'"
                [widgetFormat]="true"
                [smallScreenMode]="smallScreenMode"
                [data]="chartsData.data"
                [monthIndex]="chartsData.monthIndex"
                [width]="chartsWidth"
                [height]="chartsHeight"
                [theme]="theme">
            </app-threat-killchain-chart>
            <app-threat-action-chart *ngIf="selectedChart==='action'"
                [widgetFormat]="true"
                [smallScreenMode]="smallScreenMode"
                [data]="chartsData.data"
                [monthIndex]="chartsData.monthIndex"
                [width]="chartsWidth"
                [height]="chartsHeight"
                [theme]="theme">
            </app-threat-action-chart>
            <app-threat-assets-chart *ngIf="selectedChart==='assets'"
                [widgetFormat]="true"
                [smallScreenMode]="smallScreenMode"
                [data]="chartsData.data"
                [monthIndex]="chartsData.monthIndex"
                [width]="chartsWidth"
                [height]="chartsHeight"
                [theme]="theme">
            </app-threat-assets-chart>
        </ng-template>
    </ngb-carousel>

    <div *ngIf="loaded && !errorMessage">
        <div class="monthly-navigation my-3">
            <button
                class="btn" [disabled]="!canGetPreviousMonth() || selectedChart === 'tpvsfp'"
                (click)="changeMonth('back')">
                <fa-icon [icon]="['fas', 'angles-left']"></fa-icon>
            </button>
            
            <span class="mx-3 month-span">{{ selectedChart === 'tpvsfp' ? 'Current' : chartsData.currentMonth }}</span>
            <button
                class="btn" [disabled]="!canGetNextMonth() || selectedChart === 'tpvsfp'"
                (click)="changeMonth('fwd')">
                <fa-icon [icon]="['fas', 'angles-right']"></fa-icon>
            </button>
        </div>
    </div>

    <ng-template #showError>
        <div class="alert alert-danger alert-sm" role="alert">
            <fa-icon [icon]="['fas', 'info-circle']" class="info-icon mx-2" size="xl" placement="left" [ngbTooltip]="help"></fa-icon>
            <p [ngClass]="{'error-message': true, 'light-theme': theme === 'light'}">{{ errorMessage }}</p>
          </div>
    </ng-template>

    <div class="row justify-content-center p-5" *ngIf="!loaded">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
    </div>

</div>
