import { Component, OnInit } from '@angular/core';
import { constants } from 'src/app/shared/constants';
import { CookieInfo, Cookies } from 'src/app/shared/model/cookie';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  public noticeInfo: string;
  public cookieList: CookieInfo[] = [];

  constructor(
    public contextService: ContextService
   ) { }

  ngOnInit(): void {

    // get cookie generic values
    const subgroup = constants.cookies.subgroup;
    const thirdpart = 'Third Party';
    const lifeSpan = 'Session';

    // build cookies list
    this.cookieList.push(new CookieInfo(subgroup, Cookies.oauthNonce, thirdpart, lifeSpan));
    this.cookieList.push(new CookieInfo(subgroup, Cookies.oauthRedirectParms, thirdpart, lifeSpan));
    this.cookieList.push(new CookieInfo(subgroup, Cookies.oauthState, thirdpart, lifeSpan));
  }
}

