import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Asset, Case } from 'src/app/shared/model/itsm';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { LocalStorageItem, Pages } from 'src/app/shared/model/shared-items';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-assetview',
  templateUrl: './assetview.component.html',
  styleUrls: ['./assetview.component.scss']
})

export class AssetViewComponent extends FlaggedListComponent implements OnInit {

  // pagination
  perPage = constants.recordsPerPage.cases;
  currentPage: number;

  // date
  assetRef: string;
  asset: Asset;

  // case view
  page: string;
  cases: Case[];
  subsetCases: Case[];
  casesLoaded: boolean;
  entitlements = [];
  entitlementsLoaded: boolean;

  sort: string;
  sortBy: string;
  originalName: string;
  flagcount: number;

   //Modal
   closeResult: string;
   displayField = false;
   fieldValue = '';
   assetUpdated = false;

  constructor(
    private route: ActivatedRoute,
    public userprefService: UserPreferenceService,
    private modalService: NgbModal,
    private itsmService: ItsmService,
    public alertService: AlertService,
    private userActivity: UserActivityService,
    private translateService: TranslateService
  ) {
    super(userprefService, alertService);
    this.flagType = constants.local.flags.assetCases;
    this.viewType = constants.local.views.asset;
    super.checkPreferences();
  }

  ngOnInit(): void {
    this.casesLoaded = false;
    this.entitlementsLoaded = false;
    this.route.params.subscribe(params => this.assetRef = params.assetRef);
    this.page = Pages.cases;
    this.currentPage = 1;

    this.refreshSupportUserPrefs();

    this.cases = [];

    this.itsmService.getAssetsByAssetId(this.assetRef).subscribe(
      {
        next:(response) => {
          if(response.length === 1 ) {
            this.asset = response[0];

            if(this.asset.entitlements.length !== 0) {

              // get all services
              forkJoin(
                this.asset.entitlements.map(ent => this.itsmService.getServiceByServiceId(ent.id))
              ).subscribe(values => {
                values.forEach(res => {
                  this.entitlements = this.entitlements.concat(res);
                })
                this.entitlementsLoaded = true;
              })
            }


            this.loadCases().then( () => {
              const assetFlaggedCases = this.cases.filter(c => this.userprefService.userPreferences.flags[this.flagType].includes(c.id));
              this.flagcount = assetFlaggedCases.length;
            });

          } else {
            this.alertService.addError('Error: multiple asset found for this id');
          }
        },
        error:(err) => {
          console.log(err);
        }
      }
    )

    this.userActivity.logActivity(TYPES.assetManagement, PAGES.assetView, ACTIONS.visit);
  }

  editAsset(content, field, flagg){

    this.displayField = flagg;
    this.openModal(content, field);
  }
  public openModal(content, field) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if(result === 'save'){

       const assetObj = {
        sys_id : this.assetRef
       };
       assetObj[field] = this.fieldValue;
       this.itsmService.updateAsset(assetObj)
        .then(() => {
          localStorage.removeItem(LocalStorageItem.itsmAssets);
          window.location.reload();
          this.alertService.addSuccess(this.translateService.instant('pages.generic.changesSaved'));
        })
        .catch(exc => {
          console.log(exc);
        });

      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  loadCases(): Promise<void> {
    this.casesLoaded = false;
    return new Promise((resolve,reject) => {
      const searchCriteria = new SupportCaseSearchCriteria();

      searchCriteria.asset = this.asset.serialNumber + '||' + this.originalName;
      if(this.sort) {
        searchCriteria.sort = this.sort;
      }
      if(this.sortBy) {
        searchCriteria.sortBy = this.sortBy;
      }

      this.itsmService.caseSearch(searchCriteria)
        .then(res => {
          this.cases = res;
          // this.casesLoaded = true;
          this.subsetCases = this.cases.slice(0, this.perPage);
          resolve();
        })
        .catch(err => {
          reject();
          this.handlerError(err);
        })
        .finally(() => {
          this.casesLoaded = true;
        });
    });
  }

  /**
   * Filter flagged cases
   */
  loadFlagged(){
    this.cases = this.cases.filter(c => this.userprefService.userPreferences.flags[this.flagType].includes(c.id));
    this.page = Pages.flagged;
  }


  /**
   * Refresh the local flagRefs and viewType variable from user preferences
   */
  refreshSupportUserPrefs() {
    // load the flagged signals from user preferences
    const preferences = this.userprefService.userPreferences;
    if (preferences?.flags && !preferences.flags.assetCases) {
      this.userprefService.userPreferences.flags[this.flagType] = [];
    }
    if (preferences && !preferences.assetViewType) {
      this.userprefService.userPreferences.assetViewType = 'grid';
    }
  }

   /**
    * Toggle between grid/list view
    *
    * @param view
    */
  changeViewType(view: string){
    // set local variable to determine what view the user is using
    this.userprefService.userPreferences.assetViewType = view;

    // update the users flags appropriately
    this.userprefService.userPreferencesUpdateSingle(constants.local.views.asset, view)
      .catch(err => {
        this.handlerError(err);
      });
  }

  onPageChange($event){
    this.casesLoaded = false;
    this.subsetCases = this.cases.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
    this.casesLoaded = true;
  }

  sortResults(field: string){
    this.casesLoaded = false;
    this.currentPage = 1;
    if(this.sort === field && this.sortBy){
      if(this.sortBy === 'DESC') {
        this.sortBy = 'ASC';
      }else{
        this.sortBy = 'DESC';
      }
    }else{
      this.sort = field;
      this.sortBy = 'DESC';
    }

    this.loadCases();
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
  private handlerError(err){
    this.alertService.handlerError(err);
  }
}
