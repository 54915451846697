<div class="xtended-soc-main container p-0 mw-100 col-lg-11 bg-body-tertiary">
  <div class="container bg-body-tertiary p-3" style="max-width: 1800px">
    <app-card class="mw-100">
      @if (entity) {
      <div class="mb-4">
        <h3>{{ entity.name }}</h3>
      </div>

      <div class="mb-4">
        <h4 id="membersTableDescription">
          {{ 'pages.cybersocxdr.entities.members' | translate }}
        </h4>
        <div class="table-responsive">
          <table class="table" aria-describedby="membersTableDescription">
            <thead>
              <tr>
                <th>
                  {{
                    'pages.cybersocxdr.entities.fieldTitles.email' | translate
                  }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (member of entity.members; track member.id) {
              <tr>
                <td>{{ member.mail }}</td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    (click)="removeMember(member.id)"
                  >
                    {{ 'pages.cybersocxdr.entities.button.remove' | translate }}
                  </button>
                </td>
              </tr>
              } @if (entity.members.length === 0) {
              <tr>
                <td colspan="2" class="text-center">
                  {{ 'pages.cybersocxdr.entities.noMembers' | translate }}
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      <div class="mb-3">
        <h4>{{ 'pages.cybersocxdr.entities.addMember' | translate }}</h4>
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            [(ngModel)]="newMemberEmail"
            placeholder="{{
              'pages.cybersocxdr.entities.emailPlaceholder' | translate
            }}"
          />
          <button class="btn btn-primary" (click)="addMember()">
            {{ 'pages.cybersocxdr.entities.button.add' | translate }}
          </button>
        </div>
      </div>
      }
    </app-card>
  </div>
</div>
