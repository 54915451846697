<app-card
  cardTitle="pages.cybersocxdr.backlog.backlogSanityScore"
  actionTitle="pages.cybersocxdr.backlog.viewBacklog"
  actionRoute="/xtended-soc/dashboard"
>
  <div class="mb-4">
    <div
      class="progress h-100"
      role="progressbar"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div class="progress-bar py-1" [style]="getBarStyle()">
        {{ backlogStatus?.sanityScore ?? 0 }}%
      </div>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col-12 col-sm-5">
      <div
        class="text-muted mb-2"
        [title]="'pages.cybersocxdr.backlog.backlogTickets' | translate"
      >
        {{ 'pages.cybersocxdr.backlog.backlogTickets' | translate }}
      </div>
      <div class="fs-1 fw-bold">{{ backlogStatus?.total ?? 0 }}</div>
      <div class="mt-2">
        <app-route-withfacets
          actionRoute="/xtended-soc/incidents"
          [criteriaToApply]="criteriaToApplyOlderThanAMonth"
          [text]="
            (backlogStatus?.olderThanAMonth ?? 0) +
            ' ' +
            ('pages.cybersocxdr.backlog.olderThanAMonth' | translate)
          "
        >
        </app-route-withfacets>
      </div>
    </div>

    <div class="col-12 col-sm-5">
      <div
        class="text-muted mb-2"
        [title]="'pages.cybersocxdr.backlog.ongoingTickets' | translate"
      >
        {{ 'pages.cybersocxdr.backlog.ongoingTickets' | translate }}
      </div>
      <div class="fs-1 fw-bold">{{ backlogStatus?.onGoingTickets ?? 0 }}</div>
      <div class="mt-2">
        <app-route-withfacets
          actionRoute="/xtended-soc/incidents"
          [criteriaToApply]="criteriaToApplyOngoingTickets"
          [text]="'pages.generic.view' | translate"
        >
        </app-route-withfacets>
      </div>
    </div>
  </div>
</app-card>
