import { Injectable } from '@angular/core';
import { Asset } from '../model/itsm';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  appGroups: [];

  services: [] = [];
  assets: Asset[] = [];
  constructor() { }

  private caseTypes: any[];
  private casePriorities: any[];
  private caseStatus: any[];

  public getCaseValue(option) {
    switch (option) {
      case ('type'): return this.caseTypes;
      case ('priority'): return this.casePriorities;
      case ('status'): return this.caseStatus;
    }
  }

  public setCaseValue(option: string, value: any[]) {
    switch (option) {
      case ('type'): {
        this.caseTypes = value;
        break;
      }
      case ('priority'): {
        this.casePriorities = value;
        break;
      }
      case ('status'): {
        this.caseStatus = value;
        break;
      }
    }
  }
}
