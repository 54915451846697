@if((gpAlerts?.length ?? 0) > 0 || (alertService.alerts?.length ?? 0) > 0) {
<div
  [ngClass]="[
    contextService.isDashboard ? 'bg-black' : contextService.bgTheme,
    hasPadding ? 'notifications' : 'no-padding-notifications'
  ]"
>
  <!-- GP GLOBAL NOTIFICATION -->
  @for(gpAlert of gpAlerts; track gpAlert) {
  <div
    class="d-flex align-items-center"
    [class]="[
      'alert',
      'alert-' + gpAlert.category,
      'alert-dismissible',
      'fade',
      'show'
    ]"
    role="alert"
  >
    <span class="alert-icon"><span class="visually-hidden">Error</span></span>
    <div class="gp-notifications">
      <span>{{ gpAlert.title }}</span>
      <p [innerHtml]="gpAlert.message"></p>
    </div>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      (click)="closeGPAlert(gpAlert)"
    >
      <span class="visually-hidden">Close</span>
    </button>
  </div>
  }

  <!-- SESSION NOTIFICATION -->
  @for(sessionAlert of alertService.alerts; track sessionAlert) {
  <div
    [class]="[
      'alert',
      'alert-' + sessionAlert.type,
      'alert-dismissible',
      'fade',
      'show'
    ]"
    (click)="closeAlert(sessionAlert)"
    role="alert"
  >
    <span class="alert-icon"><span class="visually-hidden">Error</span></span>
    <p [innerHtml]="sessionAlert.message"></p>
    <button type="button" class="btn-close" data-bs-dismiss="alert">
      <span class="visually-hidden">Close</span>
    </button>
  </div>
  }
</div>
}
<!-- TOAST -->
<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <ng-container #toastContainer></ng-container>
</div>
