import { Component, OnInit} from '@angular/core';
import { ContextService } from '../../services/context-service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor(
    public contextService: ContextService
  ) { }

  public displayHeader: boolean;

  ngOnInit(): void {
    this.displayHeader = this.contextService.title && this.contextService.title !== 'dashboard';
  }

}
