import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { ReleaseNoteDTO } from '../model/cybersocxdr/portailReleaseNote';

@Injectable({
  providedIn: 'root',
})
export class PortailReleaseNoteService {
  private portailReleaseNoteEndpoint = `${environment.endpoints.cybersocxdr}admin/portail-release-notes`;

  constructor(private http: HttpClient) {}

  getReleaseNotes(): Observable<ReleaseNoteDTO[]> {
    return this.http.get<ReleaseNoteDTO[]>(`${this.portailReleaseNoteEndpoint}`);
  }
}
