import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: [
    '../abstract-question/abstract-question.component.scss',
    './list-order.component.scss'
  ]
})
export class ListOrderQuestionComponent extends AbstractQuestionComponent {

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.question.answers, event.previousIndex, event.currentIndex);

    // update form
    this.surveyForm.get(this.question.title).patchValue(this.question.answers);
  }
}
