import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { constants } from 'src/app/shared/constants';
import { CVE } from 'src/app/shared/model/cve';
import { VulnSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { VulnerabilitiesService } from 'src/app/shared/services/vulnerabilities.service';

@Component({
  selector: 'app-viewcpe',
  templateUrl: './viewcpe.component.html',
  styleUrls: ['./viewcpe.component.scss']
})
export class ViewCpeComponent extends FlaggedListComponent implements OnInit {

  cpeRef: string;
  searchCriteria: VulnSearchCriteria;
  searched: boolean;
  vulnsLoaded: boolean;
  vulns: CVE[];
  vulnsCount: number;


  // pagination
  perPage = constants.recordsPerPage.vulns;
  currentPage: number;

  constructor(
    private route: ActivatedRoute,
    private vulnService: VulnerabilitiesService,
    public userprefService: UserPreferenceService,
    public alertService: AlertService) {
      super(userprefService, alertService);
      this.flagType = constants.local.flags.cpe;
      this.viewType = constants.local.views.vuln;
      super.checkPreferences();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.cpeRef = params.cpeRef);
    this.currentPage = 1;

    this.refreshVulnUserPrefs();

    this.searchCriteria = new VulnSearchCriteria();
    this.searchCriteria.cpe = this.cpeRef;
    this.searchCriteria.returnStub = true;
    this.searchCriteria.limit = this.perPage;
    this.searchCriteria.skip = this.perPage * (this.currentPage-1);

    this.vulnSearch();
  }

   /**
    * Utility method to load count of results as well as results themselves
    * Used for pagination
    */
  private vulnSearch(): Promise<void> {
    this.vulnsLoaded = false;
    return new Promise((resolve,reject) => {
      this.searchCriteria.count = true;
      const tempLimit = this.searchCriteria.limit;
      delete this.searchCriteria.limit;

      this.vulnService.vulnSearch(this.searchCriteria)
        .then(res => {
          if(res && res[0]){
            this.vulnsCount = res[0].matching_vulns;
          }else{
            this.vulnsCount = 0;
          }

          this.searchCriteria.count = false;
          this.searchCriteria.limit = tempLimit;
          this.vulnService.vulnSearch(this.searchCriteria)
            .then(res => {
              this.vulns = res;
              this.vulnsLoaded = true;
              resolve();
            })
            .catch(err => {
              throw err;
            });
        })
        .catch(err => {
          reject();
          this.handlerError(err);
        });
    });
  }

  /**
   * Refresh the local flagRefs, savedSearches and viewType variable from user preferences
   */
  refreshVulnUserPrefs() {
    // load the flagged signals from user preferences
    const preference = this.userprefService.userPreferences;
    if (preference?.flag && !preference.flags.cpe) {
      this.flagRefs = [];
    }
    if (preference?.vulnViewType) {
      this.viewType = preference.vulnViewType;
    } else {
      this.viewType = 'list';
    }
  }

  /**
   * Pagination
   *
   * @param event
   */
  onPageChange(event): void {
    this.searchCriteria.skip = this.perPage * (this.currentPage-1);
    this.vulnSearch();
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
  private handlerError(err){
    this.alertService.handlerError(err);
  }
}
