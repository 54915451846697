<div class="row">
  <div class="col-xl-1 col-lg-1 hidden-md"></div>
  <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">

    <div class="row">
      <div class="col-12">
        <p>
        <p>{{ 'pages.policy.termsOfUse.introP1' | translate }}</p>
        <p [innerHTML]="'pages.policy.termsOfUse.introP2' | translate"></p>
        <p>{{ 'pages.policy.termsOfUse.introP3' | translate }}</p>
        <ul>
          <li>{{ 'pages.policy.privacy.itemFrance' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemNetherland' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemBelgium' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemUnitedKingdom' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemSweden' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemDenmark' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemNorway' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemGermany' | translate }}</li>
          <li>{{ 'pages.policy.privacy.itemSouthAfrica' | translate }}</li>
        </ul>
        <p>{{ 'pages.policy.termsOfUse.introP4' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.introP5' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S1' | translate }}</strong></p>
        <p>{{ 'pages.policy.termsOfUse.S1P1' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S1P2' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S2' | translate }}</strong></p>
        <p>{{ 'pages.policy.termsOfUse.S2P1' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S3' | translate }}</strong></p>
        <p>{{ 'pages.policy.termsOfUse.S3P1' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S4' | translate }}</strong></p>
        <p [innerHTML]="'pages.policy.termsOfUse.S4P1' | translate"></p>
        <p>{{ 'pages.policy.termsOfUse.S4P2' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S5' | translate }}</strong></p>
        <p>{{ 'pages.policy.termsOfUse.S5P1' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S5P2' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S5P3' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S5P4' | translate }}</p>

        <p><strong>{{ 'pages.policy.termsOfUse.S6' | translate }}</strong></p>
        <p>{{ 'pages.policy.termsOfUse.S6P1' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S6P2' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S6P3' | translate }}</p>
        <p>{{ 'pages.policy.termsOfUse.S6P4' | translate }}</p>
        <br /><br /><br /><br />
      </div>
    </div>
  </div>
</div>
