import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'timeZoneDatetimePipe',
    standalone: true
})
export class TimeZoneDateTimePipe implements PipeTransform {

    /**
     * format the CERT offsetdateTime to readable date format
     */
    transform(date: string): any {
        const ret = moment(date);
        return ret;
    }
}