export class Survey {
    public _id: { $oid: string };
    public title: string;
    public questions: Question[];
    public desc: string;
    public timestamp_created: string; 
}

export class Question {
    public _id: { $oid: string };
    public component: SurveyComponentEnum;
    public title: string;
    public params: string;
    public answers: string[];
}

export class SurveyResponse {
    public parentId: string;
    public answers: QuestionResponse[];

    constructor(parentId: string) {
        this.parentId = parentId;
        this.answers = []
    }
}

export class SurveyReport {
    details: {
        count: number;
    };
    answers: [{
        questionId: string;
        name: string;
        data: [];
        question: Question;
        param: {}
    }]
} 

export class QuestionResponse {
    public questionId;
    public name;
    public answer;

    constructor(questionId: string, name: string, answer: any) {
        this.questionId = questionId;
        this.name = name;
        this.answer = answer;
    }
}

export enum SurveyComponentEnum {
    closedChoice = 'closed-choice',
    listOrder = 'list-order',
    singleSelect = 'single-select',
    multiSelect = 'multi-select',
    freeText = 'free-text',
    rating = 'rating'
}
