import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-pie',
  templateUrl: '../basechart/basechart.component.html',
})
export class PieComponent extends BaseChartComponent<Array<Highcharts.SeriesOptionsType>> {
  @Input() suffix = '%';
  @Input() innerSize: string;
  @Input() enabled3D = false;
  @Input() drilldownSeries: Highcharts.SeriesOptionsType[] = undefined;
  options: Highcharts.Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      options3d: {
        enabled: this.enabled3D,
        alpha: 45,
        beta: 0,
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      useHTML: true,
      pointFormat: '<b><span style="color:{point.color}">{point.name}</span>:</b> {point.percentage:.0f}%',
    },
    legend: {
      align: 'center',
    },
    plotOptions: {
      pie: {
        innerSize: undefined,
        depth: 60,
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
      },
    },
    series: undefined,
  };

  draw(renderingid: string) {
    this.options.title.text = this.title;
    this.options.series = this.data;
    if (this.drilldownSeries) this.options.drilldown = { series: this.drilldownSeries };
    this.options.plotOptions.pie.innerSize = this.innerSize;
    this.options.chart.options3d.enabled = this.enabled3D;
    this.options.plotOptions.pie.dataLabels = {
      enabled: false,
      format: `<b>{point.y}</b> ${this.suffix}`,
    };
  }
}
