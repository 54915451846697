import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { CybersocxdrRoutingModule } from './cybersocxdr-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { XtendedSOCDashboardComponent } from './pages/dashboard/dashboard.component';
import { XtendedSOCSLAComponent } from './pages/sla/sla.component';
import { CybersocxdrIncidentBySeverityComponent } from './charts/incidentbyseverity/incidentbyseverity.component';
import { CybersocxdrIncidentBySeverityCategoryComponent } from './charts/incidentbyseveritycategory/incidentbyseveritycategory.component';
import { BarovertimeComponent } from './charts/barovertime/barovertime.component';
import { AreaovertimeComponent } from './charts/areaovertime/areaovertime.component';
import { PackedbubblehostComponent } from './charts/packedbubblehost/packedbubblehost.component';
import { SlaGaugeComponent } from './charts/slagauge/slagauge.component';
import { PieComponent } from './charts/pie/pie.component';
import { SecurityNavigatorComponent } from './charts/securitynavigator/securitynavigator.component';
import { SecuritynavigatormanagerComponent } from './components/securitynavigatormanager/securitynavigatormanager.component';
import { TranslateModule } from '@ngx-translate/core';
import { IncidentlistComponent } from './components/incidentlist/incidentlist.component';
import { ScatterpyramidComponent } from './charts/scatterpyramid/scatterpyramid.component';
import { XtendedSOCIncidentDetailsComponent } from './pages/incident-details/incident-details.component';
import { IncidentTimelineComponent } from './charts/incident-timeline/incident-timeline.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WorldmapComponent } from './charts/worldmap/worldmap.component';
import { SunburstComponent } from './charts/sunburst/sunburst.component';
import { PolarComponent } from './charts/polar/polar.component';
import { FiltermenuComponent } from './components/filtermenu/filtermenu.component';
import { TableComponent } from './charts/table/table.component';
import { SearchabledropdownComponent } from './components/searchabledropdown/searchabledropdown.component';
import { AnonymisePipe } from './pipes/anonymise.pipe';
import { MicrosocCaseListComponent } from './components/microsocCase-list/microsocCase-list.component';
import { XtendedSOC4A3WComponent } from './pages/4A3W/4A3W.component';
import { XtendedChartService } from '../shared/services/xtendedsoc/xtended-chart.service';
import { SankeyComponent } from './charts/sankey/sankey.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { XtendedLayoutComponent } from '../layout/xtended-layout/xtended-layout.component';
import { BacklogSanityScoreComponent } from './components/backlogSanityScore/backlog-sanity-score.component';
import { XtendedSOCHomeComponent } from './pages/home/home.component';
import { InsightComponent } from './components/insight/insight.component';
import { InsightCarrouselComponent } from './components/insight/insight-carrousel.component';
import { InsightchartComponent } from './charts/insightchart/insightchart.component';
import { DailyInfoComponent } from './components/dailyInfo/daily-info.component';
import { FourAThreeWPreviewComponent } from './components/fourAthreeWPreview/four-a-three-w-preview.component';
import { TreemapComponent } from './charts/treemap/treemap.component';
import { IncidentNeedingActionPreviewComponent } from './components/incidentNeedingActionPreview/incident-needing-action-preview.component';
import { ClientcontactstableComponent } from './components/clientcontactstable/clientcontactstable.component';
import { FilterFormComponent } from './components/filterForm/filter-form.component';
import { XtendedFiltersService } from './xtendedfilters.service';
import { TabBarComponent } from './components/tabbar/tabbar.component';
import { ScrollableIncidentListComponent } from './components/scrollableIncidentList/scrollableIncidentList.component';
import { XtendedHighchartService } from './xtended-highchart.service';
import { BarchartComponent } from './charts/barchart/barchart.component';
import { CustompourcentchartComponent } from './charts/custompourcentchart/custompourcentchart.component';
import { XtendedSOCContractComponent } from './pages/contract/contract.component';
import { XtendedSOCIncidentsComponent } from './pages/incidents/incidents.component';
import { CardComponent } from './components/card/card.component';
import { PaginationIncidentListComponent } from './components/paginationList/paginationIncidentList.component';
import { RouteWithfacetsComponent } from './components/route-withfacets/route-withfacets.component';
import { IconInfoComponent } from './components/icon-info/icon-info.component';
import { PortailReleaseNoteComponent } from './pages/portail-release-note/portail-release-note.component';

import * as Highcharts from 'highcharts';
import HC_treemap from 'highcharts/modules/treemap';
import HC_sankey from 'highcharts/modules/sankey';
import HC_solidGauge from 'highcharts/modules/solid-gauge.src';
import HC_map from 'highcharts/modules/map';
import { FeedbackReminderComponent } from './components/feedback-reminder/feedback-reminder.component';
import { XtendedHelpComponent } from './components/xtended-help/xtended-help.component';
import { XtendedNotificationService } from '../shared/services/xtendedsoc/xtended-notification.service';
import { XtendedNotificationCarouselComponent } from './components/xtended-notification-carousel/xtended-notification-carousel.component';
import { XtendedSOCEntitiesComponent } from './pages/entities/entities.component';
import { XtendedSOCEntityDetailComponent } from './pages/entities/detail/entity-detail.component';

HC_treemap(Highcharts);
HC_sankey(Highcharts);
HC_solidGauge(Highcharts);
HC_map(Highcharts);

@NgModule({
  declarations: [
    XtendedLayoutComponent,
    XtendedSOCDashboardComponent,
    XtendedSOCSLAComponent,
    XtendedSOCContractComponent,
    XtendedSOCEntitiesComponent,
    XtendedSOCEntityDetailComponent,
    CybersocxdrIncidentBySeverityComponent,
    CybersocxdrIncidentBySeverityCategoryComponent,
    BarovertimeComponent,
    AreaovertimeComponent,
    InsightchartComponent,
    SlaGaugeComponent,
    TreemapComponent,
    PieComponent,
    SankeyComponent,
    PackedbubblehostComponent,
    SecurityNavigatorComponent,
    SecuritynavigatormanagerComponent,
    IncidentlistComponent,
    ScatterpyramidComponent,
    XtendedSOCIncidentsComponent,
    XtendedSOCIncidentDetailsComponent,
    IncidentTimelineComponent,
    WorldmapComponent,
    SunburstComponent,
    PolarComponent,
    FiltermenuComponent,
    TableComponent,
    SearchabledropdownComponent,
    AnonymisePipe,
    MicrosocCaseListComponent,
    ScrollableIncidentListComponent,
    PaginationIncidentListComponent,
    XtendedSOC4A3WComponent,
    SidemenuComponent,
    TabBarComponent,
    BacklogSanityScoreComponent,
    DailyInfoComponent,
    FilterFormComponent,
    IncidentNeedingActionPreviewComponent,
    FourAThreeWPreviewComponent,
    InsightComponent,
    InsightCarrouselComponent,
    XtendedSOCHomeComponent,
    ClientcontactstableComponent,
    BarchartComponent,
    CustompourcentchartComponent,
    CardComponent,
    FeedbackReminderComponent,
    RouteWithfacetsComponent,
    IconInfoComponent,
    PortailReleaseNoteComponent,
  ],
  imports: [
    CommonModule,
    XtendedHelpComponent,
    XtendedNotificationCarouselComponent,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CybersocxdrRoutingModule,
    AppSharedModule,
    HighchartsChartModule,
    TranslateModule,
    InfiniteScrollModule,
  ],
  exports: [XtendedSOCDashboardComponent, XtendedSOCSLAComponent, XtendedSOCContractComponent],
  bootstrap: [XtendedSOCDashboardComponent, XtendedSOCSLAComponent, XtendedSOCContractComponent],
  providers: [XtendedFiltersService, XtendedHighchartService, XtendedChartService, XtendedNotificationService],
})
export class CybersocxdrModule {}
