import { Case } from 'src/app/shared/model/itsm';
import { SecurityCaseActivity } from './chartModels';
import { Utilities } from 'src/app/shared/utilities';

export type ChartsDataDTO = {
  data: Case[];
  caseMap: Map<string, SecurityCaseActivity>;
  monthIndex: number;
  currentMonth: string;
  currentMonthDataTP: Case[];
  currentMonthDataFP: Case[];
};

export const initChartsData = ( (): ChartsDataDTO => ({
    data: [],
    caseMap: new Map(),
    monthIndex: 0,
    currentMonth: '',
    currentMonthDataFP: [],
    currentMonthDataTP: []
  }));


export const securityIncidentCallback = ( (res: any, chartsData: ChartsDataDTO): ChartsDataDTO => {
  chartsData.data = res.filter(t => (t.type !== 'Change Request') && (t.type !== 'Service Request'));
  if (chartsData.data && chartsData.data.length > 0) {
    chartsData.caseMap = new Map();
    chartsData.data.forEach( (c: Case) => {
      const cd = new Date(c.createdDate);
      const actualMonth = cd.getMonth() + 1;
      const dateStr = cd.getFullYear() + '-' + actualMonth;

      if (!chartsData.caseMap.get(dateStr)) {
        chartsData.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
      }

      if (Utilities.checkTruePositive(c.caseNumber, c.status, c.closureCode)) {
        const caseM = chartsData.caseMap.get(dateStr);
        caseM.truePositives.push(c);
      } else if (Utilities.checkFalsePositive(c.status, c.closureCode)) {
        const caseM = chartsData.caseMap.get(dateStr);
        caseM.falsePositives.push(c);
      }
    });

    chartsData.caseMap = new Map([...chartsData.caseMap.entries()].reverse());
    chartsData.monthIndex = Array.from(chartsData.caseMap).length - 1;
    const cmdate = Array.from(chartsData.caseMap)[chartsData.monthIndex][0];
    chartsData.currentMonth = cmdate.slice(5) + '/' + cmdate.slice(0, 4);
    chartsData.currentMonthDataTP = Array.from(chartsData.caseMap)[chartsData.monthIndex][1].truePositives;
    chartsData.currentMonthDataFP = Array.from(chartsData.caseMap)[chartsData.monthIndex][1].falsePositives;
    return chartsData;
  } else {
    return null;
  }
});

export const changeChartsMonth = ( (direction: 'fwd' | 'back', chartsData: ChartsDataDTO): ChartsDataDTO => {
  const caseMapArray = Array.from(chartsData.caseMap);
  if (direction === 'fwd') {
    chartsData.monthIndex++;
  } else {
    chartsData.monthIndex--;
  }
  const cmdate = caseMapArray[chartsData.monthIndex][0];
  chartsData.currentMonth = cmdate.slice(5) + '/' + cmdate.slice(0, 4);
  chartsData.currentMonthDataTP = caseMapArray[chartsData.monthIndex][1].truePositives;
  chartsData.currentMonthDataFP = caseMapArray[chartsData.monthIndex][1].falsePositives;
  return chartsData;
});
