/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable max-len */
import { Component } from '@angular/core';


@Component({
  selector: 'app-fake-widget1',
  template : '<img src="assets/images/widgets/widget1.png" class="card-img-top" alt="Fake Widget 1" width="100%">',
})
export class FakeWidget1 { }

@Component({
  selector: 'app-fake-widget2',
  template : '<img src="assets/images/widgets/widget2.png" class="card-img-top" alt="Fake Widget 2" width="100%">',
})
export class FakeWidget2 { }

@Component({
  selector: 'app-fake-latest-activity',
  template : '<img src="assets/images/widgets/latestActivity.png" class="card-img-top" alt="Latest Activity" width="100%">',
})
export class FakeLatestActivity { }

@Component({
  selector: 'app-fake-recent-support-activity',
  template : '<img src="assets/images/widgets/recentSupportActivity.png" class="card-img-top" alt="Recent Support Activity" width="100%">',
})
export class FakeRecentSupportActivity { }

@Component({
  selector: 'app-fake-worldwatch-carousel',
  template : '<img src="assets/images/widgets/worldwatchCarousel.png" class="card-img-top" alt="Worldwatch" width="100%">',
})
export class FakeWorldwatchCarousel { }

@Component({
  selector: 'app-fake-since-login',
  template : '<img src="assets/images/widgets/sinceLogin.png" class="card-img-top" alt="Since Login" width="100%">',
})
export class FakeSinceLogin { }

@Component({
  selector: 'app-fake-since-login-half-width',
  template : '<img src="assets/images/widgets/sinceLogin-halfWidth.png" class="card-img-top" alt="Since Login" width="100%">',
})
export class FakeSinceLoginHalfWidth { }

@Component({
  selector: 'app-fake-pending-cases-ocd',
  template : '<img src="assets/images/widgets/pendingCasesOcd.png" class="card-img-top" alt="Pending Cases Ocd" width="100%">',
})
export class FakePendingCasesOcdComponent { }

@Component({
  selector: 'app-fake-open-cases',
  template : '<img src="assets/images/widgets/openCases.png" class="card-img-top" alt="Open Cases" width="100%">',
})
export class FakeOpenCasesComponent { }

@Component({
  selector: 'app-fake-true-positives-vs-false-positives',
  template : '<img src="assets/images/widgets/truePositivesVSFalsePositives.png" class="card-img-top" alt="True Positives VS False Positives" width="100%">',
})
export class FakeTruePositivesVSFalsePositivesComponent { }

@Component({
  selector: 'app-fake-mtta',
  template : '<img src="assets/images/widgets/mtta_widget.png" class="card-img-top" alt="Mean-time-to-Acknowledge" width="100%">',
})
export class FakeMTTAComponent { }

@Component({
  selector: 'app-fake-mtt-respond',
  template : '<img src="assets/images/widgets/mttRespond_widget.png" class="card-img-top" alt="Mean-time-to-Respond" width="100%">',
})
export class FakeMTTRespondComponent { }

@Component({
  selector: 'app-fake-mtt-resolve',
  template : '<img src="assets/images/widgets/mttResolve_widget.png" class="card-img-top" alt="Mean-time-to-Resolve" width="100%">',
})
export class FakeMTTResolveComponent { }
