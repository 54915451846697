import { Component } from '@angular/core';
import { InternalFileTransfertComponent } from "../../shared/components/internal-file-transfer/internal-file-transfer.component";
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FileAccessService } from 'src/app/shared/services/fileaccess.service';
import { SharedGCPFile } from 'src/app/shared/model/files';
import { IconsModule } from 'src/app/shared/modules/icons/icons.module';
import { CommonModule, DatePipe } from '@angular/common';
import FileSaver from 'file-saver';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';


@Component({
  selector: 'app-user-shared-files',
  standalone: true,
  imports: [
    InternalFileTransfertComponent,
    TranslateModule,
    FormsModule,
    IconsModule,
    DatePipe,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './user-shared-files.component.html',
  styleUrl: './user-shared-files.component.scss'
})
export class UserSharedFilesComponent {

  public error: string;
  public loading = false;
  public loadingPage = true;
  public loadingSharedFiles: boolean;
  public loadingUserSharedFiles: boolean;
  public recipient: string;
  public recipientForm: FormGroup;
  public selectedTab = "sharedFiles";
  public sharedFiles: SharedGCPFile[] = [];
  public uploadedFile: File;
  public mySharedFiles: SharedGCPFile[] = [];
  public validFile = false;

  public get recipients() { return this.recipientForm.get("recipients") as FormArray }

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private fileAccessService: FileAccessService,
    private fb: FormBuilder,
    private userActivity: UserActivityService
  ) {
    this.loadSharedFiles();
    this.initForm()
    this.logActivity(ACTIONS.visit);
    this.loadingPage = false;
  }

  private logActivity(action: string) {
    this.userActivity.logActivity(TYPES.reports, PAGES.sharedFiles, action);
  }

  private initForm() {
    this.recipientForm = this.fb.group({
      recipients: this.fb.array([
        this.fb.control('', [Validators.required, Validators.email])
      ])
    })
  }

  public loadSharedFiles() {
    this.selectedTab = 'sharedFiles'
    this.loadingSharedFiles = true;
    this.fileAccessService.getSharedFiles().subscribe({
      next: (reponse) => {
        this.sharedFiles = reponse;
        this.loadingSharedFiles = false;
      },
      error: () => {
        this.loadingSharedFiles = false;
        this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.sharedFileLoadingInError'));
      }
    })
  }

  public loadMySharedFiles() {
    this.selectedTab = 'mySharedFiles'
    this.loadingUserSharedFiles = true;
    this.fileAccessService.getUserSharedFiles().subscribe({
      next: (reponse) => {
        this.mySharedFiles = reponse;
        this.loadingUserSharedFiles = false;
      },
      error: () => {
        this.loadingUserSharedFiles = false;
        this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.mySharedFileLoadingInError'));
      }
    })
  }

  private resetUpload() {
    this.uploadedFile = undefined;
    this.initForm();
  }

  public addRecipient() {
    this.recipients.push(this.fb.control('', [Validators.email]));
  }

  public removeRecipient(index: number) {
    this.recipients.removeAt(index);
    this.recipients.updateValueAndValidity();
  }

  public refresh() {
    if (this.selectedTab === "sharedFiles") {
      this.loadSharedFiles();
    } else if (this.selectedTab === "mySharedFiles") {
      this.loadMySharedFiles();
    }
  }

  /**
 * send file to backen to proceed import
 */
  public submitFile() {
    this.loading = true;
    if (this.recipientForm.valid && this.uploadedFile) {

      const recipients: string[] = this.recipients.value.filter(r => r !== '');

      this.fileAccessService.uploadFile(this.uploadedFile, recipients).subscribe({
        next: () => {
          this.loading = false;
          this.alertService.addSuccess(this.translateService.instant('pages.reports.internalFileTransfer.uploadInSuccess', { filename: this.uploadedFile.name }));
          this.logActivity(ACTIONS.sharedFileUpload)
          this.resetUpload()
          this.loadMySharedFiles();
        },
        error: () => {
          this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.uploadInError', { filename: this.uploadedFile.name }));
          this.loading = false;
        }
      });
    }
  }

  public addFile(file) {
    this.uploadedFile = file;
  }

  public downloadFile(file: SharedGCPFile) {
    this.fileAccessService.downloadSharedFile(file.title).subscribe({
      next: (response) => {
        if(response.size === 0) {
          this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.downloadingInError', { filename: file.title }));
        } else {
          this.logActivity(ACTIONS.sharedFileDownload);
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(response);
          } else {
            FileSaver.saveAs(response, file.title.substring(file.title.lastIndexOf('/') + 1));
          }
        }
      },
      error: () => {
        this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.downloadingInError', { filename: file.title }));
      }
    })
  }

  public deleteFile(file: SharedGCPFile) {
    this.fileAccessService.deleteSharedFile(file.title).subscribe({
      next: () => {
        this.alertService.addSuccess(this.translateService.instant('pages.reports.internalFileTransfer.deletionInSuccess', { filename: file.title }));
        this.refresh();
      },
      error: () => {
        this.alertService.addError(this.translateService.instant('pages.reports.internalFileTransfer.deletionInError', { filename: file.title }));
      }
    })
  }


}
