import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MfdDynamicAnalysisReportParameters } from 'src/app/shared/modals/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-dynamic-mfd-analysis-report',
  templateUrl: './dynamic-mfd-analysis-report.component.html',
  styleUrls: ['./dynamic-mfd-analysis-report.component.scss'],
})
export class DynamicMfdAnalysisReportModalComponent implements OnInit {
  @Input() params: MfdDynamicAnalysisReportParameters;

  public report: Blob;
  public filename;
  public fileURL;
  public myfile: File;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.filename = this.params?.filename;
    if (this.params) {
      const url = URL.createObjectURL(this.params.reportAnalysis);
    this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    } else {
      this.alertService.addError('Error while generating report');
    }
  }

  public close() {
    this.activeModal.close();
  }

  public downloadPdf() {
    window.open(this.fileURL);
  }
}
