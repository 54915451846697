import { Component, OnInit, Input } from '@angular/core';
import { Activity } from 'src/app/shared/model/activity';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';

@Component({
  selector: 'app-login-section',
  templateUrl: './login-section.component.html',
  styleUrls: ['./login-section.component.scss']
})
export class LoginSectionComponent implements OnInit {

  @Input() name: string;
  @Input() selectedTenant: string;

  public sinceLastLogin: Activity[];
  public loaded = false;

  public colorTab: string[] = [
    '#4bb4e6',
    '#ffd200',
    '#50be87',
    '#a885d8',
    '#ffb4e6'
  ];

  constructor(
    private activitySearch: ActivityService,
    private alertService: AlertService,
    public userPreferenceService: UserPreferenceService) {
  }

  async ngOnInit() {

    try {
      // load sigs and vulns since user last logged in
      const res = await Promise.allSettled([
        this.activitySearch.loadTranslations(),
        this.activitySearch.loadSinceLastVisit()
      ])
  
      if(res[1].status === 'fulfilled') {
        this.sinceLastLogin = res[1].value;
      }

      this.loaded = true;
    } catch(error) {
      this.alertService.handlerError(error);
    }
  }

}
