import { NgClass, NgStyle } from '@angular/common';
import { OnInit, AfterViewInit, Component, Input } from '@angular/core';
import { CssColor } from 'src/app/shared/model/shared-items';
import { Utilities } from 'src/app/shared/utilities';
declare const boosted: any;

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() message: string;
  @Input() level: 'info' | 'success' | 'warning' | 'danger';

  public toastId = "toast_" + Utilities.getRandomNumber();
  public borderColor: string;

  ngOnInit() {
    this.borderColor = this.getBorderColor();
  }

  ngAfterViewInit(): void {
    if (document.getElementById(this.toastId)) {
      boosted.Toast.getOrCreateInstance(document.getElementById(this.toastId)).show();
    }
  }

  private getBorderColor(): string {
    switch (this.level) {
      case 'info': return CssColor.info;
      case 'success': return CssColor.success;
      case 'warning': return CssColor.warning;
      case 'danger': return CssColor.danger;
    }
  }

}
