<div class="row">
    <div class="col-12 text-center">
        <h2>{{ 'pages.tenants.select' | translate }}</h2>
        <p>{{ 'pages.tenants.update' | translate }}</p>

        <h4>{{ 'pages.tenants.current' | translate }} : {{selectedTenant}}</h4>
    </div>
</div>
<form>
    <div class="row justify-content-center text-center">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3">
            <select class="form-control mb-3" name="tenant" aria-describedby="selectTenant" [(ngModel)]="tenantToSelect">
                <!-- <option hidden>Change Tenant</option> -->
                <option value="{{ten}}" *ngFor="let ten of availableTenants">{{ten}}</option>
            </select>

            <button class="btn btn-primary" (click)="selectTenant();">{{ 'pages.dashboard.changeTenant' | translate }}</button>
        </div>
    </div>
</form>

