<div class="row">
  <div class="col-xl-1 col-lg-1 hidden-md"></div>
  <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">

    <div class="row">
        <div class="col-12">
            <p>{{ 'pages.policy.privacy.introS1' | translate }}</p>
            <p>{{ 'pages.policy.privacy.introS2' | translate }}</p>
            <p [innerHTML]="'pages.policy.privacy.introS3' | translate"></p>
            <p>{{ 'pages.policy.privacy.introS4' | translate }}</p>
            <ul>
                <li>{{ 'pages.policy.privacy.itemFrance' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemNetherland' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemBelgium' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemUnitedKingdom' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemSweden' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemDenmark' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemNorway' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemGermany' | translate }}</li>
                <li>{{ 'pages.policy.privacy.itemSouthAfrica' | translate }}</li>
            </ul>
            <p [innerHTML]="'pages.policy.privacy.introS5' | translate"></p>
            <ol>
                <li>{{ 'pages.policy.privacy.sectionDefinition' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionScope' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionWho' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionWhat' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionHow' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionTo' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionInternalTransfers' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionDataSecurity' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionDataRetention' | translate }}</li>
                <li>{{ 'pages.policy.privacy.sectionSubjectRights' | translate }}</li>
            </ol>

            <p></p>

            <p class="OCDHeading"><strong>1. {{ 'pages.policy.privacy.sectionDefinition' | translate }}</strong></p>
            <table class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="data definitions" aria-hidden="true">
                <tbody>
                    <tr>
                        <td style="width: 33%">
                            <p><strong>{{ 'pages.policy.privacy.controller' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.controllerDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.customer' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.customerDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.customerPersonalData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p [innerHTML]="'pages.policy.privacy.customerPersonalDataDesc' | translate"></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.customerRepresentative' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.customerRepresentativeDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.endUser' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.endUserDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.personalData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.personalDataDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.processor' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.processorDesc' | translate }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p></p>

            <p>&nbsp;</p>
            <p class="OCDHeading"><strong>2. {{ 'pages.policy.privacy.sectionScope' | translate }}</strong></p>
            <p><strong>{{ 'pages.policy.privacy.S2P1' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S2P1Info' | translate }}</p>

            <p></p>

            <p>&nbsp;</p>
            <p class="OCDHeading"><strong>3. {{ 'pages.policy.privacy.sectionWho' | translate }}</strong></p>
            <p><strong>{{ 'pages.policy.privacy.controller' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S3P1SUB1Info' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S3P1SUB2Info' | translate }}<a href="https://portal.orangecyberdefense.com/">https://portal.orangecyberdefense.com</a>.</p>
            <p>{{ 'pages.policy.privacy.S3P1SUB3Info' | translate }}</p>

            <p><strong>{{ 'pages.policy.privacy.processor' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S3P2SUB1Info' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S3P2SUB2Info' | translate }}</p>
            <p>{{ 'pages.policy.privacy.P3S3SUB1Info' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S3P3SUB1' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S3P3SUB1Info1' | translate }}</p>

            <p>&nbsp;</p>
            <p class="OCDHeading"><strong>4. {{ 'pages.policy.privacy.sectionWhat' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.sectionWhat' | translate }}</p>
            <table class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="What Customer Personal Data may we collect">
                <tbody>
                    <tr class="rowfiller">
                        <th style="width: 33%">
                            <p><strong>{{ 'pages.policy.privacy.category' | translate }}</strong></p>
                        </th>
                        <th>
                            <p><strong>{{ 'pages.policy.privacy.categoryDesc' | translate }}</strong></p>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.contactData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.contactDataDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.supportData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.supportDataDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.identityData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.identityDataDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.locationData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.locationDataDesc' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.technicalData' | translate }}</strong></p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.technicalDataDesc' | translate }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>&nbsp;</p>
            <p class="OCDHeading"><strong>5. {{ 'pages.policy.privacy.sectionHow' | translate }}</strong></p>
            <table class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="Customer Personal Data for which we are Controller">
                <tbody>
                    <tr class="rowfiller">
                        <th colspan="3">
                            <p><strong>{{ 'pages.policy.privacy.tableTitleP5S1' | translate }}</strong></p>
                        </th>
                    </tr>
                    <tr>
                        <td style="width: 33%">
                            <p><strong>{{ 'pages.policy.privacy.activity' | translate }}</strong></p>
                        </td>
                        <td style="width: 33%">
                            <p><strong>{{ 'pages.policy.privacy.personalDataTypes' | translate }}</strong></p>
                        </td>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.lawfulBasis' | translate }}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.activityDesc1' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.contactData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.technicalData' | translate }}</p>
                        </td>
                        <td>
                            <p [innerHTML]="'pages.policy.privacy.lawfulBasisDesc1' | translate"></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.activityDesc2' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.identityData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.contactData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.technicalData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.locationData' | translate }}</p>
                            <p></p>
                        </td>
                        <td>
                            <p [innerHTML]="'pages.policy.privacy.lawfulBasisDesc2' | translate"></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p></p>
            <p>{{ 'pages.policy.privacy.S5P1Info' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S5P2Info' | translate }}</p>
            <table class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="Customer Personal Data for which we are Processor" aria-hidden="true">
                <tbody>
                    <tr class="rowfiller">
                        <td colspan="2">
                            <p><strong>{{ 'pages.policy.privacy.S5P2tableTitle' | translate }}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 66%">
                            <p><strong>{{ 'pages.policy.privacy.activity' | translate }}</strong></p>
                        </td>
                        <td>
                            <p><strong>{{ 'pages.policy.privacy.personalDataTypes' | translate }}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.S5P2ActivityDesc1' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.identityData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.contactData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.supportData' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.S5P2ActivityDesc2' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.identityData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.contactData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.supportData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.technicalData' | translate }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.S5P2ActivityDesc3' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.identityData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.contactData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.supportData' | translate }}</p>
                            <p>{{ 'pages.policy.privacy.technicalData' | translate }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>&nbsp;</p>

            <p class="OCDHeading"><strong>6. {{ 'pages.policy.privacy.sectionTo' | translate }}</strong></p>

            <p><strong>{{ 'pages.policy.privacy.S6P1' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S6P1Info' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S6P2' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S6P2Info' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S6P3' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S6P3Info' | translate }}</p>
            <table class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="External parties">
                <tbody>
                    <tr class="rowfiller">
                        <th style="width: 25%">
                            <p><strong>{{ 'pages.policy.privacy.colParty' | translate }}</strong></p>
                        </th>
                        <th>
                            <p><strong>{{ 'pages.policy.privacy.colDescription' | translate }}</strong></p>
                        </th>
                        <th style="width: 25%">
                            <p><strong>{{ 'pages.policy.privacy.colLocation' | translate }}</strong></p>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <p>Google, Inc</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.S6GoogleDesc1' | translate }}</p>
                            <p>&middot; {{ 'pages.policy.privacy.S6GoogleDesc2' | translate }}</p>
                            <p>&middot; {{ 'pages.policy.privacy.S6GoogleDesc3' | translate }}</p>
                            <p>&middot; {{ 'pages.policy.privacy.S6GoogleDesc4' | translate }}</p>
                            <p>&middot; {{ 'pages.policy.privacy.S6GoogleDesc5' | translate }}</p>
                        </td>
                        <td width="141">
                            <p>Belgium</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Okta, Inc</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.S6OktaDesc1' | translate }}</p>
                        </td>
                        <td width="141">
                            <p>Ireland</p>
                            <p>Germany</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ 'pages.policy.privacy.colSalesforce' | translate }}</p>
                        </td>
                        <td>
                            <p>{{ 'pages.policy.privacy.S6SalesforceDesc1' | translate }}</p>
                        </td>
                        <td width="141">
                            <p>Netherlands</p>
                            <p>France</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>ServiceNow</p>
                        </td>
                        <td>
                            <p> {{ 'pages.policy.privacy.S6SalesforceDesc1' | translate }}</p>
                        </td>
                        <td width="141">
                            <p>Ireland</p>
                            <p>Netherlands</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p><strong>{{ 'pages.policy.privacy.S6P4' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S6P4Info1' | translate }}</p>
            <p [innerHTML]="'pages.policy.privacy.S6P4Info2' | translate"></p>

            <p>&nbsp;</p>

            <p class="OCDHeading"><strong>7. {{ 'pages.policy.privacy.sectionInternalTransfers' | translate }}</strong></p>

            <p>{{ 'pages.policy.privacy.S7Intro' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S7P1' | translate }}</strong></p>
            <p [innerHTML]="'pages.policy.privacy.S7P1Info1' | translate"></p>
            <p>{{ 'pages.policy.privacy.S7P1Info2' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S7P2' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S7P2Info' | translate }}</p>

            <p>&nbsp;</p>

            <p class="OCDHeading"><strong>8. {{ 'pages.policy.privacy.sectionDataSecurity' | translate }}</strong></p>

            <p>{{ 'pages.policy.privacy.S8P1' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S8P1Info1' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S8P1Info2' | translate }}</p>

            <p>&nbsp;</p>

            <p class="OCDHeading"><strong>9. {{ 'pages.policy.privacy.sectionDataRetention' | translate }}</strong></p>

            <p>{{ 'pages.policy.privacy.S9P1' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S9P1Info1' | translate }}</p>
            <p>{{ 'pages.policy.privacy.S9P1Info2' | translate }}</p>

            <p>&nbsp;</p>

            <p class="OCDHeading"><strong>10. {{ 'pages.policy.privacy.sectionSubjectRights' | translate }}</strong></p>

            <p><strong>{{ 'pages.policy.privacy.S10P1' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S10P1Info' | translate }}</p>
            <ul>
                <li>{{ 'pages.policy.privacy.S10P1InfoItem1' | translate }}</li>
                <li>{{ 'pages.policy.privacy.S10P1InfoItem2' | translate }}</li>
                <li>{{ 'pages.policy.privacy.S10P1InfoItem3' | translate }}</li>
                <li>{{ 'pages.policy.privacy.S10P1InfoItem4' | translate }}</li>
            </ul>
            <p><strong>{{ 'pages.policy.privacy.S10P2' | translate }}</strong></p>
            <ol>
                <li>{{ 'pages.policy.privacy.S10P2Item1' | translate }}<a href="mailto:{{mailToInfoSec}}">{{mailToInfoSec}}</a></li>
                <li>{{ 'pages.policy.privacy.S10P2Item2' | translate }}</li>
            </ol>
            <p>{{ 'pages.policy.privacy.S10P2Info' | translate }}</p>
            <p><strong>{{ 'pages.policy.privacy.S10P3' | translate }}</strong></p>
            <p>{{ 'pages.policy.privacy.S10P3Info1' | translate }}<a href="mailto:{{mailToInfoSec}}">{{mailToInfoSec}}</a> {{ 'pages.policy.privacy.S10P3Info2' | translate }}</p>
            <ol start="3">
                <li>{{ 'pages.policy.privacy.S10P3Info3' | translate }}<a href="mailto:{{mailToInfoSec}}">{{mailToInfoSec}}</a></li>
            </ol>
            <p></p>
            <p><strong>{{ 'pages.policy.privacy.S10P4' | translate }}</strong></p>
            <p><em>{{ 'pages.policy.privacy.S10P4SUB1' | translate }}</em></p>
            <p>{{ 'pages.policy.privacy.S10P4SUB1Info' | translate }}</p>
            <p><em>{{ 'pages.policy.privacy.S10P4SUB2' | translate }}</em></p>
            <p>{{ 'pages.policy.privacy.S10P4SUB2Info' | translate }}</p>
            <p><em>{{ 'pages.policy.privacy.S10P4SUB3' | translate }}</em></p>
            <p>{{ 'pages.policy.privacy.S10P4SUB3Info' | translate }}</p>
            <p></p>
            <p>Dated: 4<sup>th</sup> April 2022</p>
            <p></p>
            <br /><br /><br /><br /><br />
        </div>
    </div>

  </div>
</div>
