import { Injectable } from '@angular/core';
import { SessionStorageItem } from '../model/shared-items';

@Injectable()
export class RetentionService {


  setAssetSort(sortField: string, sortOrder: number) {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention){
      const assetObj = JSON.parse(assetRetention);
      assetObj.sort={};
      assetObj.sort.field = sortField;
      assetObj.sort.order = sortOrder;
      sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
    }
    else{
      const assetObj = {} as AssetObj;
      assetObj.sort.field = sortField;
      assetObj.sort.order = sortOrder;
      sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
    }
  }

  getAssetSort(): any {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention &&
    JSON.parse(assetRetention).sort){
      const assetObj = JSON.parse(assetRetention);
      assetObj.sort.order = Number(assetObj.sort.order) * (-1);
      return assetObj.sort;
    }
    return null;
  }

  setAssetSearch(assetSearchObj: {}) {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention){
      const assetObj = JSON.parse(assetRetention);
      assetObj.search = assetSearchObj;
      sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
    }
    else{
      const assetObj = {} as AssetObj;
      assetObj.search = assetSearchObj;
      sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
    }
  }

  getAssetSearch(): any {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention && JSON.parse(assetRetention).search){
      const assetObj = JSON.parse(assetRetention);
      return assetObj.search;
    }
    return {};
  }

  getAssetPagination(): number {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention && JSON.parse(assetRetention).page){
      const assetObj = JSON.parse(assetRetention);
      return assetObj.page;
    }
    return 1;
  }

  setAssetPagination(currentPage: number) {
    const assetRetention = sessionStorage.getItem(SessionStorageItem.assetRetention);
    if(assetRetention){
     const assetObj = JSON.parse(assetRetention);
     assetObj.page = currentPage;
     sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
   }
    else{
      const assetObj = {} as AssetObj ;
      assetObj.page = currentPage;
      sessionStorage.setItem(SessionStorageItem.assetRetention, JSON.stringify(assetObj));
    }
  }
}

interface AssetObj {
  search;
  sort: {
    field: string;
    order: number;
  };
  page: number;
}

