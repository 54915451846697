import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: [
    '../abstract-question/abstract-question.component.scss',
    './multi-select.component.scss'
  ]
})

export class MultiSelectComponent extends AbstractQuestionComponent {

}
