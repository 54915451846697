<section>
  <div class="d-flex faketableheaderrow">
    <div class="col-1 faketableheader click d-none d-md-block" [class.active]="sortBy?.value === 'tdc_id'"
      (click)="sortAdvisories('tdc_id');">
      {{ 'pages.updates.signal.fieldTitles.id' | translate }}
      @if(sortBy?.value === 'tdc_id') {
      <ng-container *ngTemplateOutlet="sort"></ng-container>
      }
    </div>
    <div class="col-lg-1 col-md-2 col-sm-3 faketableheader click" [class.active]="sortBy?.value === 'severity'"
      (click)="sortAdvisories( 'severity');">
      {{ 'pages.updates.signal.fieldTitles.severity' | translate }}
      @if(sortBy?.value === 'severity') {
      <ng-container *ngTemplateOutlet="sort"></ng-container>
      }
    </div>
    <div class="col-lg-5 col-md-4 col-sm-8 faketableheader click" [class.active]="sortBy?.value === 'title'"
      (click)="sortAdvisories( 'title');">
      {{ 'pages.updates.signal.fieldTitles.title' | translate }}
      @if(sortBy?.value === 'title') {
      <ng-container *ngTemplateOutlet="sort"></ng-container>
      }
    </div>
    <div class="col-2 faketableheader click d-none d-md-block" [class.active]="sortBy?.value === 'timestamp_created'"
      (click)="sortAdvisories( 'timestamp_created');">
      {{ 'pages.updates.signal.fieldTitles.release' | translate }}
      @if(sortBy?.value === 'timestamp_created') {
      <ng-container *ngTemplateOutlet="sort"></ng-container>
      }
    </div>
    <div class="col-2 faketableheader click d-none d-md-block" [class.active]="sortBy?.value === 'timestamp_updated'"
      (click)="sortAdvisories( 'timestamp_updated');">
      {{ 'pages.updates.signal.fieldTitles.updated' | translate }}
      @if(sortBy?.value === 'timestamp_updated') {
      <ng-container *ngTemplateOutlet="sort"></ng-container>
      }
    </div>
    <div class="col-1 d-none d-sm-block faketableheader">{{ 'pages.generic.flag' | translate }}</div>
  </div>
  
  <div>
    @if(!loading && signals?.length === 0) {
    <div class="row p-3 justify-content-center redborder-sides">
      <div class="col-md-12 table-border justify-content-center">
        {{ 'pages.support.dashboard.noResults' | translate }}
      </div>
    </div>
    } @else {
    <div class="d-flex faketablerow signal-row" *ngFor="let sig of signals"
      [routerLink]="isWorldWatch_User ? ['/updates/worldwatch/viewSignal/' + sig.id] : null"
      routerLinkActive="activeLink">
      <div class="col-1 d-none d-md-block faketablecell">{{sig.id}}</div>
      <div class="col-lg-1 col-md-2 col-sm-3 d-flex justify-content-center align-items-center faketablecell"
        placement="top">
        <div class="sticker" [ngClass]="sig.severityStyle">
          <p class="mb-0">
            <span class="d-block">{{sig.severity}}</span>
          </p>
        </div>
      </div>
      <div class="col-lg-5 col-md-4 col-sm-8 faketablecell signal-title">{{sig.title}}</div>
      <div class="col-2 d-none d-md-block faketablecell">{{sig.timestampCreated | offsetDatetimePipe}}</div>
      <div class="col-2 d-none d-md-block faketablecell">{{sig.timestampUpdated | offsetDatetimePipe}}</div>
      <div class="col-1 d-none d-sm-block faketablecell">
        @if(flaggingRef && flaggingRef == sig.id) {
        <div class="spinner-grow spinner-grow-sm text-primary">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
        } @else if (flagRefs && (flagRefs.includes(sig.id + '') || flagRefs.includes(sig.tdcId + ''))) {
        <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagSignal($event, sig.id)"></fa-icon>
        } @else {
        <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagSignal($event, sig.id)"></fa-icon>
        }
      </div>
    </div>
    @if(loading) {
    <div class="row justify-content-center p-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
    }
    }
  </div>
</section>

<ng-template #sort>
  @if(sortOrder.value === 'asc') {
  <fa-icon [icon]="['fas', 'arrow-up-wide-short']"></fa-icon>
  } @else {
  <fa-icon [icon]="['fas', 'arrow-down-wide-short']"></fa-icon>
  }
</ng-template>