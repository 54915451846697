import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { OktaUserService } from 'src/app/shared/services/oktauser.service';
import GPRoles from 'src/config/global-portal-roles.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-roles',
  templateUrl: './new-roles.component.html',
  styleUrls: ['./new-roles.component.scss']
})
export class NewRolesComponent implements OnInit {

  @Input() selectedTenantApps;

  private activatedUserApps: any[] = [];
  public userId: string;
  public userEmail: string;
  public selectedProfile;

  public availableUserRoles: {
    id: string;
    created: string;
    lastUpdated: string;
    APPROLE_appId: string;
    name: string;
    description: string;
    userCount: number;
    appName: string;
    selected: boolean;
  }[];
  public loading;
  public gpRoles: any;
  public roleLabels = [];
  public customProfile = {
    label: 'Custom profile',
    roles: ''
  };
  public selectedUserRoleIds = [];
  public selectableRoles;

  constructor(
    private oktaUserService: OktaUserService,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService
  ) { }


  async ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: {id: string; email: string}) => {
      this.userId = params.id;
      this.userEmail = params.email;
    });

    try {

      // get all global portal roles
      this.gpRoles = GPRoles;
      this.loading = true;

      const apps = await this.oktaUserService.listTenantApps();
      this.selectedTenantApps = apps;

      // get all user roles
      const userIncGroups = await this.oktaUserService.getUserIncGroups(this.userId);

      userIncGroups.groups.forEach(grp => {


        if(grp.profile.name.startsWith('APPUSERS')){
          const appId = grp.profile.name.split('_')[2];
          this.selectedTenantApps.forEach(ta => {
            if(ta.id === appId && this.activatedUserApps.indexOf(ta) === -1){
              this.activatedUserApps.push(ta);
            }
          });
        }
        if(grp.profile.name.startsWith('APPROLE')){
          const appId = grp.profile.name.split('_')[1];
          this.selectedTenantApps.forEach(ta => {
            if(ta.id === appId && this.activatedUserApps.indexOf(ta) === -1){
              this.activatedUserApps.push(ta);
            }
          });
          this.selectedUserRoleIds.push(grp.id);
        }
      });

      this.activatedUserApps.forEach(ua => {
        if (!this.selectedUserRoleIds.includes(ua.APPUSERS_groupId)) {
          this.selectedUserRoleIds.push(ua.APPUSERS_groupId);
        }
      });

      // get all user applications roles
      await this.getAllAppsRoles();
    }
    catch (err) {
      this.alertService.handlerError(err);
    }
    finally {
      this.loading = false;
    }

  }

  /**
   * get the user groups id
   */
  async getAllAppsRoles() {

    for (const app of this.activatedUserApps) {
      const appRoles = await this.oktaUserService.getAppRoles(app.id);
      this.availableUserRoles = [];
      appRoles.forEach(r => {
        r.appName = app.name;
        r.selected = false;
      });

      this.availableUserRoles = this.availableUserRoles.concat(appRoles);

      this.roleLabels = [];
      this.availableUserRoles.forEach(role => {

        // init roleLabels for profile view table
          this.roleLabels.push({
            label: role.name.replaceAll('_', ' '),
            name: role.name
          });
      });
    }
  }

  /**
   * select a pre defined profile
   * @param roles
   */
  public selectProfile() {
    // reset existing userIds
    const selectedProfileRoles = this.selectedProfile.roles;
    this.availableUserRoles.forEach(role => {

      const mustHaveRole = selectedProfileRoles.includes(role.name);
      const roleIsSelected = this.selectedUserRoleIds.includes(role.id);

      // check if roles must be add
      if (mustHaveRole && !roleIsSelected) {
        this.selectedUserRoleIds.push(role.id);
      }
      // check if roles must not be removed
      else if (!mustHaveRole && roleIsSelected) {
        const index = this.selectedUserRoleIds.indexOf(role.id, 0);
        if (index > -1) {
          this.selectedUserRoleIds.splice(index, 1);
        }
      }
    });
  }

  /**
   * Add the role group id to the new users group ids
   * @param event - click event
   * @param roleId - role id
   */
  selectRole(event, roleId: string) {
    if (this.selectedUserRoleIds.includes(roleId)) {
      const ind = this.selectedUserRoleIds.indexOf(roleId);
      this.selectedUserRoleIds.splice(ind, 1);

      // if we modify existing profile, change the profile to custom
      if (this.selectedProfile !== this.customProfile) {
        this.selectedProfile = this.customProfile;
      }

    } else {
      if (!this.selectedUserRoleIds.includes(roleId)) {
        this.selectedUserRoleIds.push(roleId);
      }
    }

  }

  /**
   * role is selectable
   */
  roleIsSelectable(role: string) {
    const cats = this.getCategories();
    let selectableRolesArr = [];
    cats.forEach(cat => {
      selectableRolesArr = selectableRolesArr.concat(this.getRolesForCat(cat));
    });
    return selectableRolesArr.includes(role);
  }

  /**
   * returns an array of role for a given category
   */
  getRolesForCat(cat: string): string[] {
    return Object.keys(this.gpRoles.selectableRoles[cat]);
  }

  /**
   * role has help
   */
  roleHasHelp(cat: string, role: string): boolean {
    return this.gpRoles.selectableRoles?.[cat]?.[role]?.help;
  }

  /**
   * get user categories
   */
  getCategories(): string[] {
    return Object.keys(this.gpRoles.selectableRoles);
  }

  categoryHasRoles(cat: string) {
    return Object.keys(this.gpRoles.selectableRoles[cat]).length > 0;
  }

  /**
   * category include role
   */
  categoryIncludesRole(cat: string, role: string): boolean {
    const roles = Object.keys(this.gpRoles.selectableRoles[cat]);
    return roles.includes(role);
  }

  /**
   * update the new rules
   */
  updateRules() {
    this.activatedUserApps.forEach(ua => {
      if (!this.selectedUserRoleIds.includes(ua.APPUSERS_groupId)) {
        this.selectedUserRoleIds.push(ua.APPUSERS_groupId);
      }
    });
    this.loading = true;
    this.oktaUserService.updateUsersGroups(this.userId, this.selectedUserRoleIds)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('pages.viewTenant.updateRoles', { email: this.userEmail }));
        this.back();
      })
      .catch(err => {
        this.alertService.handlerError(err);
      })
      .finally(() => {
        this.loading = false;
      });

  }

  /**
   * return to the user list
   */
  back() {
    this.router.navigateByUrl('/user/management');
  }

}
