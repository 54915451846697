import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { ACTIONS, PAGES, TYPES, constants } from '../shared/constants';
import { Widget } from '../shared/model/widget';
import { UserActivityService } from '../shared/services/user-activity.service';
import { UserPreferenceService } from '../shared/services/userpreferences.service';
import { CssTheme } from '../shared/model/shared-items';
import { ContextService } from '../shared/services/context-service';
import { AlertService } from '../shared/services/alert.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  loaded = false;
  userObject: any;
  selectedWidgets: Widget[] = null;
  widgetLines: Widget[][] = null;
  availableWidgets: Widget[] = null;
  libraryWidgets: Widget[] = null;
  selectedTheme;

  public widgetSettings = {
    worldwatch : new WidgetSettings(),
    recentSupportActivity : new WidgetSettings(),
    latestActivity : new WidgetSettings(),
    pendingCasesOcd : new WidgetSettings(),
    openCases: new WidgetSettings(),
    truePositivesVSFalsePositives: new WidgetSettings(),
    mtta: new WidgetSettings(),
    mttRespond: new WidgetSettings(),
    mttResolve: new WidgetSettings()
  };

  constructor(
    public contextService: ContextService,
    private userActivity: UserActivityService,
    private userPref: UserPreferenceService,
    public userAuthService: UserAuthService,
    private alertService: AlertService) {
  }

  async ngOnInit() {
    try {
      const [registeredWidgets] = await Promise.allSettled([
        this.userPref.getRegisteredWidgets(),
        this.userPref.loadProfilePicture()
      ]);

      if(registeredWidgets.status === 'fulfilled') {
        const widgets = registeredWidgets.value;
        this.libraryWidgets = widgets;
        const widgetListName = this.libraryWidgets.map(widget => widget.name);
        const cacheWidgets = JSON.parse(sessionStorage.getItem(constants.local.selectedWidgets));
        if(cacheWidgets) {
          this.manageWidgets(cacheWidgets, widgetListName);
        } else {
          //load the users selected widgets
          this.userPref.selectedWidgets
            .subscribe(widgets => {
              this.manageWidgets(widgets, widgetListName);
            });
        }
      }

    } catch (error) {
      this.alertService.handlerError(error);
    }

    // load the widgets default library
    this.userPref.getRegisteredWidgets()
      .then(libraryWidgets => {
        this.libraryWidgets = libraryWidgets;
        const widgetListName = this.libraryWidgets.map(widget => widget.name);
        const cacheWidgets = JSON.parse(sessionStorage.getItem(constants.local.selectedWidgets));
        if(cacheWidgets) {
          this.manageWidgets(cacheWidgets, widgetListName);
        } else {
          //load the users selected widgets
          this.userPref.selectedWidgets
            .subscribe(widgets => {
              this.manageWidgets(widgets, widgetListName);
            });
        }
      });

    this.userActivity.logActivity(TYPES.dashboard, PAGES.dashboard, ACTIONS.visit);
  }

  private manageWidgets(widgets, widgetListname: string[]) {
    this.selectedWidgets = widgets.filter(w => widgetListname.includes(w.name));

    // get user theme
    this.selectedWidgets.forEach(widget => {
      if(this.widgetSettings['' + widget.name]) {
        this.widgetSettings['' + widget.name].display = true;
        this.widgetSettings['' + widget.name].theme = this.contextService.getWidgetTheme(widget.rank);
      }
    });
    this.loaded = true;
  }

}


export class WidgetSettings {
  display: boolean;
  theme: CssTheme;

  constructor() {
    this.display = false;
    this.theme = CssTheme.black;
  }
}
