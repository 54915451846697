import { Injectable } from '@angular/core';
import { Account, Asset, Case, Contact, Entitlement, Product } from '../model/itsm';
import { Utilities } from '../utilities';

@Injectable()
export class ItsmGenerator {
    industies = ['Industry A', 'Industry B', 'Industry C', 'Industry D', 'Industry E', 'Industry F', 'Industry G'];
    assetStatus = ['Decommissioned','Active','On Standby','Passive'];
    state = ['Open','Closed','Awaiting Customer'];
    contactName = ['Martin Birkby', 'Michel Bechard', 'Peter Inchley'];
    dates = ['01/01/2020', '05/05/2020', '06/09/2021', '09/09/2020', '12/08/2021'];
    lorem = ['Lorem','ipsum','dolor','sit','amet,','consectetur','adipiscing','elit.','Nullam','posuere','nisl','nisi,','at','facilisis','libero','porta','quis.','Pellentesque','habitant','morbi','tristique','senectus','et','netus','et','malesuada','fames','ac','turpis','egestas.','Vivamus','interdum','lorem','ac','elit','ultricies','accumsan.','Lorem','ipsum','dolor','sit','amet,','consectetur','adipiscing','elit.','Donec','quis','dolor','id','orci','tincidunt','pulvinar','id','quis','urna.','Etiam','eget','diam','a','nunc','hendrerit','mattis','at','quis','neque.','Aliquam','lacinia','enim','et','enim','pulvinar','tincidunt.','Pellentesque','faucibus','ultricies','libero','et','feugiat.','Donec','sed','sem','sem.','Ut','metus','augue,','dapibus','at','nunc','quis,','faucibus','gravida','justo.','Phasellus','accumsan','ligula','in','quam','dignissim,','ultrices','laoreet','elit','molestie.','Fusce','ut','diam','tincidunt,','sollicitudin','nibh','non,','viverra','tellus.'];
    types = ['Type 1', 'Type 2', 'Type 3', 'Type 4'];
    priorities = ['Sev 0', 'Sev 1', 'Sev 3', 'Sev 4'];
    categories = ['Category1','Category2','Category3','Category4','Category5'];

    getCase(): Case{
        const caseObj = new Case();

        caseObj.id = String(Utilities.getRandomNumber(1, 100000));
        caseObj.accountId = String(Utilities.getRandomNumber(1, 100000));
        caseObj.category = this.categories[Utilities.getRandomNumber(0, this.categories.length)];

        const randomstart2 = Utilities.getRandomNumber(0, this.lorem.length-20);
        const selection2 = this.lorem.slice(randomstart2, randomstart2+5);
        caseObj.subject = selection2.join(' ');


        const acc = new Account();
        acc.id = caseObj.accountId;
        acc.name = this.randomString(10);
        acc.industry = this.industies[Utilities.getRandomNumber(0, this.industies.length)];
        acc.address = this.randomString(30);
        caseObj.account = acc;

        caseObj.assetId = String(Utilities.getRandomNumber(1,100000));
        const ass = this.getAsset();
        ass.id = caseObj.assetId;
        caseObj.asset = ass;

        caseObj.contactId = String(Utilities.getRandomNumber(1,6));

        const con = new Contact();
        con.id = caseObj.contactId;
        // con.account = caseObj.account;
        // con.accountId = caseObj.accountId;
        con.fullName = this.contactName[Utilities.getRandomNumber(0, this.contactName.length)];
        con.email = con.fullName.replace(' ', '').toLowerCase() + '@orangecyberdefense.com';
        con.phone = '01622600000';
        caseObj.contact = con;

        const randomstart = Utilities.getRandomNumber(0, this.lorem.length-20);
        const selection = this.lorem.slice(randomstart, randomstart+20);
        caseObj.description = selection.join(' ');

        caseObj.type = this.types[Utilities.getRandomNumber(0, this.types.length)];
        caseObj.priority = this.priorities[Utilities.getRandomNumber(0, this.priorities.length)];
        caseObj.lastModifiedDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        caseObj.createdDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        caseObj.status = this.state[Utilities.getRandomNumber(0, this.state.length)];

        return caseObj;
    }

    getCases(count: number): Case[]{
        let i = 0;
        const ret = [];
        while(i < count){
            ret.push(this.getCase());
            i++;
        }
        return ret;
    }

    getAsset(): Asset {
        const ass = new Asset();
        ass.id = String(Utilities.getRandomNumber(1, 100000));
        const randomstart3 = Utilities.getRandomNumber(0, this.lorem.length - 20);
        const selection3 = this.lorem.slice(randomstart3, randomstart3+1);

        ass.name = selection3.join(' ');
        ass.status = this.assetStatus[Utilities.getRandomNumber(0, this.assetStatus.length)];
        ass.installDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        ass.purchaseDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        ass.product2Id = '123456';

        const randomstart5 = Utilities.getRandomNumber(0, this.lorem.length - 20);
        const selection5 = this.lorem.slice(randomstart5, randomstart5+4);

        const prod = new Product();
        prod.description = selection5.join(' ');
        prod.id = String(Utilities.getRandomNumber(1, 100000));
        prod.name = selection3.join(' ');
        ass.product = prod;

        const ent = new Entitlement();
        ent.id = String(Utilities.getRandomNumber(1, 100000));
        ent.accountId = String(Utilities.getRandomNumber(1, 100000));
        ent.assetId = ass.id;
        ent.name = this.randomString(12);
        ent.startDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        ent.endDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];
        ent.lastModifiedDate = this.dates[Utilities.getRandomNumber(0, this.dates.length)];

        ass.entitlements = [ent];
        ent.name = this.randomString(12);
        ass.entitlements.push(ent);

        ass.cases = this.getCases(3);
        return ass;
    }

    getAssets(count: number): Asset[]{
        let i = 0;
        const ret = [];
        while(i < count){
            ret.push(this.getAsset());
            i++;
        }
        return ret;
    }

    getContact(): Contact{
        const con = new Contact();
        con.id = String(Utilities.getRandomNumber(1, 100000));
        con.accountId = String(Utilities.getRandomNumber(1, 100000));
        con.fullName = this.contactName[Utilities.getRandomNumber(0, this.contactName.length)];
        con.email = con.fullName.replace(' ', '').toLowerCase() + '@orangecyberdefense.com';
        con.phone = '01622600000';
        return con;
    }

    getContacts(count: number): Contact[]{
        let i = 0;
        const ret = [];
        while(i < count){
            ret.push(this.getContact());
            i++;
        }
        return ret;
    }

    randomString(length) {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ';
        let result = '';
        for (let i = length; i > 0; --i) {result += chars[Utilities.getRandomNumber(0, chars.length)];}
        return result;
    }
}
