import { Pipe, PipeTransform } from '@angular/core';
import { Feedback } from 'src/app/dashboard/feedbacks/add-feedback/add-feedback.component';
import { FeedbackFilter } from 'src/app/dashboard/feedbacks/feedback-summary/feedback-summary.component';

@Pipe({
    name: 'feedbackPipe',
    standalone: true,
    pure: false
})
export class FeedbackPipes implements PipeTransform {
    transform(items: Feedback[], filter: FeedbackFilter): any {
        if (!items) {
            return items;
        } else {
            // filter items array, items which match and return true will be
            // kept, false will be filtered out
            return items.filter(item =>
                (item.title.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1 ||
                 item.login.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1)
                && filter.pages.includes(item.page)
                && filter.categories.includes(item.category)
            );
        }
    }
}
