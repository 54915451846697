import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-closed-choice',
  templateUrl: './closed-choice.component.html',
  styleUrls: [
    '../abstract-question/abstract-question.component.scss',
    './closed-choice.component.scss'
  ]
})
export class ClosedQuestionComponent extends AbstractQuestionComponent {

  public closedQuestionForm: FormGroup;

  /**
   * update the value of the FormControl
   */
  updateValue(value: boolean) {
    this.surveyForm.get(this.question.title).setValue(value);

    // Reset radio button state
    const inputElements = document.getElementsByName(this.question.title) as NodeListOf<HTMLInputElement>;
    inputElements.forEach(input => {
      input.checked = false;
    });
  }

}
