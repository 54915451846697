<div class="error-page">
  <section class="section flex items-center error-section">
    <div class="container">
      <div class="row">
        <div class="desk:col-5 hidden desk:block">
        </div>
        <div class="desk:has-offset-1"></div>
        <div class="desk:col-6 desk:align-start col-12 align-center">
          <h2 class="title-h2 mb-40">{{ 'error.401.label' | translate }}</h2>
          <h2 class="title-h2 mb-40">{{ 'pages.dashboard.notAssigned.contactSupport' | translate }}
          </h2>
          <button class="btn btn-primary me-4" (click)="refreshSession();">{{ 'pages.generic.refreshSession' | translate }}</button>
          <button class="btn btn-primary" (click)="logout();">{{ 'pages.generic.logout' | translate }}</button>
        </div>
      </div>
    </div>
  </section>
</div>
