import { Component, Input } from '@angular/core';
import { Last24HData } from 'src/app/shared/model/cybersocxdr/last24HData';
import { DateTime } from 'luxon';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { Severity } from 'src/app/shared/model/cybersocxdr/facetsValues';

@Component({
  selector: 'app-daily-info',
  templateUrl: './daily-info.component.html',
})
export class DailyInfoComponent {
  @Input()
  last24HData: Last24HData;

  criteriaToApplyCriticalIncidents: Partial<CybersocXDRDashboardSearchCriteria> = {
    createdFrom: DateTime.now().minus({ hours: 24 }).toFormat('yyyy-MM-dd'),
    severitys: [Severity.CRITICAL],
  };

  criteriaToApplyNewIncidents: Partial<CybersocXDRDashboardSearchCriteria> = {
    createdFrom: DateTime.now().minus({ hours: 24 }).toFormat('yyyy-MM-dd'),
  };
}
