<div class="card main highlight" (click)="open()">
  @if(item) {
    <div class="header">
      <img class="card-img-top" src="{{ item.imagePath }}" alt="{{item.title}} illustration picture">
    </div>
    <div class="card-body">
      <div class="mb-2">
        <span class="title" [ngClass]="context.isDarkTheme() ? 'lighter' : 'darker'" data-toggle="tooltip" data-placement="top">
          {{ item.title}}
        </span>
        <h5 *ngIf="item.pubDate" class="date">{{ item.pubDate | date:'dd/MM/yyyy HH:mm' }}</h5>
      </div>
      <p class="card-text" [innerHTML]="item.shortDescription"></p>
    </div>
  }
</div>
