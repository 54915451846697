import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../constants';
import { Activity } from '../model/activity';
import { CVE } from '../model/cve';
import { Case } from '../model/itsm';
import { SignalSearchCriteria, SupportCaseSearchCriteria, VulnSearchCriteria } from '../model/searchCriteria';
import { Signal } from '../model/signal';
import { Utilities } from '../utilities';
import { ItsmService } from './itsm.service';
import { UserPreferenceService } from './userpreferences.service';
import { VulnerabilitiesService } from './vulnerabilities.service';
import { WorldWatchService } from './worldwatch.service';
import { SessionStorageItem } from '../model/shared-items';

@Injectable()
export class ActivityService {
    datepipe: DatePipe = new DatePipe('en-GB');
    signalsWWKey = 'menu.mainDashboard.signalsWW';
    vulnerabilitiesNVDDatabase= '';
    vulnerabilitiesNVDDatabaseKey = 'menu.mainDashboard.vulnerabilitiesNVDDatabase';
    casesUpdated= '';
    casesUpdatedKey = 'menu.mainDashboard.casesUpdated';;
    newCasesUpdatedKey = 'menu.mainDashboard.newCasesUpdated';

    constructor(
        private translate: TranslateService,
        private vulnService: VulnerabilitiesService,
        private worldwatchService: WorldWatchService,
        private userprefService: UserPreferenceService,
        private itsmService: ItsmService) {

         }

    loadTranslations(){
        return new Promise((resolve, reject) => {
            this.translate.get(['menu.mainDashboard.signalsWW','menu.mainDashboard.vulnerabilitiesNVDDatabase',
            'menu.mainDashboard.casesUpdated','menu.mainDashboard.newCasesUpdated',]).subscribe((res: string) => {
                this.vulnerabilitiesNVDDatabase = res['menu.mainDashboard.vulnerabilitiesNVDDatabase'];
                this.casesUpdated = res['menu.mainDashboard.casesUpdated'];
                resolve(1);
            });
        });

    }


    /**
     * Load signals from a number of days
     * If storeSignals is true then save the latest signals for dashboard use
     */
    loadActivityDays(days: number, storeSignals: boolean): Promise<Activity[]> {
        const from = Utilities.getTodayMinusDays(days);
        const to = Utilities.getToday();
        return this.loadActivity(from, to, storeSignals, null);
    }

    /**
     * Load signals between two dates
     * TODO implement type filter
     */
    loadActivity(from: string, to: string, storeSignals: boolean, type: string): Promise<Activity[]> {
        return new Promise((resolve,reject) => {
            let acts = [];

            if(type){
                if(type==='Cases'){

                    this.searchUpdatedCases(from, to)
                        .then(res => {
                            const ucs = res;
                            ucs.forEach(cc => {
                                const ucsAct = new Activity();
                                ucsAct.type = constants.activity.types.support.updated.type;
                                ucsAct.description = cc.type + ' ' + this.casesUpdated + ': ' + cc.caseNumber + ' - ' + cc.subject;
                                ucsAct.date = cc.lastModifiedDate;
                                ucsAct.ref = cc.id;
                                ucsAct.priority = cc.priority;
                                ucsAct.url = constants.activity.types.support.updated.viewUrl + cc.id;
                                acts.push(ucsAct);
                            });
                        })
                        .then(() => {
                            // sort array by date
                            acts = acts.sort(( a, b ) => new Date(b.date).getTime() - new Date(a.date).getTime());
                        })
                        .then(() => {
                            resolve(acts);
                        })
                        .catch(err => {
                            reject(err);
                        });

                }else if(type==='World Watch'){

                    this.seachSignals(from, to)
                        .then(res => {
                            const sigs = res;
                            if(storeSignals){
                                sessionStorage.setItem(SessionStorageItem.latestSignals, JSON.stringify(sigs));
                            }
                            sigs.forEach(s => {
                                const actSig = new Activity();
                                actSig.type = constants.activity.types.signal.type;
                                actSig.description = s.summary;
                                actSig.date = s.publishDate;
                                actSig.ref = s.jiraRef;
                                actSig.iconStyle = s.signalUrgency + s.mainCategory;
                                actSig.iconAlt = '<div>Category: ' + s.mainCategory + '</div><div>Urgency:  ' + s.signalUrgency + '</div>';
                                actSig.url = constants.activity.types.signal.viewUrl + s.jiraRef;
                                acts.push(actSig);
                            });
                        })
                        .then(() => {
                            // sort array by date
                            acts = acts.sort(( a, b ) => new Date(b.date).getTime() - new Date(a.date).getTime());
                        })
                        .then(() => {
                            resolve(acts);
                        })
                        .catch(err => {
                            reject(err);
                        });

                }else if(type==='Vulnerabilities'){

                    this.seachVulnsCount(from, to)
                        .then(res => {
                            const actVuln = new Activity();
                            actVuln.type = constants.activity.types.vuln.type;
                            actVuln.description = ' new ' + this.vulnerabilitiesNVDDatabase+ ' released between ' + from + ' and ' + to;
                            actVuln.url = constants.activity.types.vuln.lastDays;
                            actVuln.date = Utilities.getToday();
                            actVuln.count = res;
                            acts.push(actVuln);
                        })
                        .then(() => {
                            // sort array by date
                            acts = acts.sort(( a, b ) => new Date(b.date).getTime() - new Date(a.date).getTime());
                        })
                        .then(() => {
                            resolve(acts);
                        })
                        .catch(err => {
                            reject(err);
                        });

                }
            }else{
                // monthly data
                Promise.allSettled([
                    this.seachSignals(from, to),
                    this.seachVulnsCount(from, to),
                    //this.searchCreatedCases(from, to),
                    this.searchUpdatedCases(from, to)
                ])
                .then(ress => {

                    if(ress[0].status === 'fulfilled'){
                        const sigs = ress[0].value;
                        if(storeSignals){
                            sessionStorage.setItem(SessionStorageItem.latestSignals, JSON.stringify(sigs));
                        }
                        sigs.forEach(s => {
                            const actSig = new Activity();
                            actSig.type = constants.activity.types.signal.type;
                            actSig.description = s.summary;
                            actSig.date = s.publishDate;
                            actSig.ref = s.jiraRef;
                            actSig.iconStyle = s.signalUrgency + s.mainCategory;
                            actSig.iconAlt = '<div>Category: ' + s.mainCategory + '</div><div>Urgency:  ' + s.signalUrgency + '</div>';
                            actSig.url = constants.activity.types.signal.viewUrl + s.jiraRef;
                            acts.push(actSig);
                        });
                    }

                    if(ress[1].status === 'fulfilled'){
                        const actVuln = new Activity();
                        actVuln.type = constants.activity.types.vuln.type;
                        actVuln.description = ' new ' + constants.activity.types.vuln.title + ' released between ' + from + ' and ' + to;
                        actVuln.url = constants.activity.types.vuln.lastDays;
                        actVuln.date = Utilities.getToday();
                        actVuln.count = ress[1].value;
                        acts.push(actVuln);
                    }

                    if(ress[2].status === 'fulfilled'){
                        const ucs = ress[2].value;
                        ucs.forEach(cc => {
                            const ucsAct = new Activity();
                            ucsAct.type = constants.activity.types.support.updated.type;
                            ucsAct.description = cc.type + ' ' + ' updated ' + ': ' + cc.caseNumber + ' - ' + cc.subject;
                            ucsAct.date = cc.lastModifiedDate;
                            ucsAct.ref = cc.id;
                            ucsAct.priority = cc.priority;
                            ucsAct.url = constants.activity.types.support.updated.viewUrl + cc.id;
                            ucsAct.updatedBy = cc.updatedBy;
                            acts.push(ucsAct);
                        });
                    }
                })
                .then(() => {
                    // sort array by date
                    acts = acts.sort(( a, b ) => new Date(b.date).getTime() - new Date(a.date).getTime());
                })
                .then(() => {
                    resolve(acts);
                })
                .catch(err => {
                    reject(err);
                });
            }
        });
    }

    /**
     * Load signals created since last visit
     */
    loadSinceLastVisit(): Promise<Activity[]> {
        return new Promise((resolve,reject) => {
            const acts = [];
            // since last login
            this.userprefService.getUserLastLoginDate()
                .then(res => {
                    // monthly data
                    Promise.allSettled([
                        this.worldwatchService.signalCountSinceLastLogin(res),
                        this.vulnService.vulnsCountSinceLastLogin(res),
                        this.itsmService.cacheCasesCountUpdatedSinceLastLogin(res),
                        this.itsmService.cacheCasesCountCreatedSinceLastLogin(res)
                    ])
                    .then(ress => {

                        if(ress[0].status === 'fulfilled'){
                            const actSig = new Activity();
                            actSig.count = ress[0].value;
                            actSig.type = constants.activity.types.signal.type;
                            actSig.description = this.signalsWWKey;
                            actSig.url = constants.activity.types.signal.lastLoginUrl;
                            acts.push(actSig);
                        }

                        if(ress[1].status === 'fulfilled'){
                            const actVuln = new Activity();
                            actVuln.count = ress[1].value;
                            actVuln.type = constants.activity.types.vuln.type;
                            actVuln.description = this.vulnerabilitiesNVDDatabaseKey;
                            actVuln.url = constants.activity.types.vuln.lastLoginUrl;
                            acts.push(actVuln);
                        }

                        if(ress[2].status === 'fulfilled'){
                            const updatedCase = new Activity();
                            updatedCase.count = ress[2].value;
                            updatedCase.type = constants.activity.types.support.updated.type;
                            updatedCase.description = this.casesUpdatedKey;
                            updatedCase.url = constants.activity.types.support.updated.lastLoginUrl;
                            acts.push(updatedCase);
                        }

                        if(ress[3].status === 'fulfilled'){
                            const createdCase = new Activity();
                            createdCase.count = ress[3].value;
                            createdCase.type = constants.activity.types.support.created.type;
                            createdCase.description = this.newCasesUpdatedKey;
                            createdCase.url = constants.activity.types.support.created.lastLoginUrl;
                            acts.push(createdCase);
                        }

                    })
                    .then(() => {
                        resolve(acts);
                    })
                    .catch(err => {
                        reject(err);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    /**
     * Search vulnerabilities and return objects
     *
     * @param dateFrom
     * @param dateTo
     * @param limit
     * @param skip
     */
    private seachVulns(dateFrom: string, dateTo: string): Promise<CVE[]> {
        return new Promise((resolve,reject) => {
            const tempSearchCriteria = new VulnSearchCriteria();
            tempSearchCriteria.modDateFrom = dateFrom;
            tempSearchCriteria.modDateTo = dateTo;
            this.vulnService.vulnSearch(tempSearchCriteria)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    /**
     * Seach vulnerabilities and return a count
     *
     * @param dateFrom
     * @param dateTo
     * @param count - boolean, to count or return object
     */
    private seachVulnsCount(dateFrom: string, dateTo: string): Promise<number> {
        return new Promise((resolve,reject) => {
            const tempSearchCriteria = new VulnSearchCriteria();
            tempSearchCriteria.modDateFrom = dateFrom;
            tempSearchCriteria.modDateTo = dateTo;
            tempSearchCriteria.count = true;
            this.vulnService.vulnSearch(tempSearchCriteria)
                .then(res => {
                    if(res && res.length == 0){
                        resolve(0);
                    }else{
                        resolve(res[0].matching_vulns);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    /**
     * Seach signals and return objects
     *
     * @param dateFrom
     * @param dateTo
     * @param limit
     * @param skip
     */
    private seachSignals(dateFrom: string, dateTo: string): Promise<Signal[]> {
        return new Promise((resolve,reject) => {
            const tempSearchCriteria = new SignalSearchCriteria();
          //  tempSearchCriteria.publishDateFrom = dateFrom;
          //  tempSearchCriteria.publishDateTo = dateTo;
          //  tempSearchCriteria.returnStub = true;
            tempSearchCriteria.sortOrder = -1;
            tempSearchCriteria.limit = 5;
            this.worldwatchService.signalSearch(tempSearchCriteria)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                   reject(err);
                });
        });
    }

    /**
     * Get cases raised in this date range
     *
     * @param dateFrom
     * @param dateTo
     */
    private searchCreatedCases(dateFrom: string, dateTo: string): Promise<Case[]> {
        const criteria = new SupportCaseSearchCriteria();
        criteria.createdFrom = dateFrom;
        criteria.createdTo = dateTo;
        criteria.returnStub = true;
        return this.itsmService.caseSearch(criteria);
    }

    /**
     * Get cases updated in this date range
     *
     * @param dateFrom
     * @param dateTo
     */
    private searchUpdatedCases(dateFrom: string, dateTo: string): Promise<Case[]> {
        const criteria = new SupportCaseSearchCriteria();
        criteria.updatedFrom = dateFrom;
        criteria.updatedTo = dateTo;
        criteria.returnStub = true;
        criteria.byPassSF = true;
        criteria.forceTMLimit = true;
        return this.itsmService.caseSearch(criteria);
    }

    /**
     * Seach signals and return count
     *
     * @param dateFrom
     * @param dateTo
     */
    private seachSignalsCount(dateFrom: string, dateTo: string): Promise<number> {
        return new Promise((resolve,reject) => {
            const tempSearchCriteria = new SignalSearchCriteria();
            tempSearchCriteria.updateDateFrom = dateFrom;
            tempSearchCriteria.updateDateTo = dateTo;
            tempSearchCriteria.count = true;
            this.worldwatchService.signalSearch(tempSearchCriteria)
                .then(res => {
                    if(res && res.length == 0){
                        resolve(0);
                    }else{
                        resolve(res[0].matching_signals);
                    }
                })
                .catch(err => {
                   reject(err);
                });
        });
    }

}
