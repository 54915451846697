import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-rss-feed-view',
  templateUrl: './rss-feed-view.component.html',
  styleUrls: ['./rss-feed-view.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class RssFeedViewComponent {

  @Input() public selectedRssFeed: any;
  @Input() public type: any;

  constructor(
    public activeModal: NgbActiveModal,
    public contextService: ContextService
  ) { 
  }

  close() {
    this.activeModal.close();
  }

}
