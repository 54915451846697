import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { XtendedNotificationDTO } from 'src/app/shared/model/cybersocxdr/xtended-notification';

@Component({
  selector: 'app-xtended-notification-carousel',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './xtended-notification-carousel.component.html',
  styleUrls: ['./xtended-notification-carousel.component.scss'],
})
export class XtendedNotificationCarouselComponent {
  private _notifications: XtendedNotificationDTO[] = [];
  notificationsToDisplay: XtendedNotificationDTO[] = [];

  @Input()
  set notifications(value: XtendedNotificationDTO[]) {
    this._notifications = value;
    const displayedNotifications = JSON.parse(localStorage.getItem('displayedNotifications') || '[]');

    this.notificationsToDisplay = this._notifications.filter(
      (notification) => !displayedNotifications.includes(notification.id)
    );
  }

  constructor(public translateService: TranslateService) {}

  closeNotifications() {
    const displayedNotifications = JSON.parse(localStorage.getItem('displayedNotifications') || '[]');
    displayedNotifications.push(...this.notificationsToDisplay.map((notification) => notification.id));
    localStorage.setItem('displayedNotifications', JSON.stringify(displayedNotifications));
    this.notificationsToDisplay = [];
  }

  hasImages(): boolean {
    return this.notificationsToDisplay.some((notification) => notification.image);
  }

  hasLink(): boolean {
    return this.notificationsToDisplay.some((notification) => notification.link);
  }
}
