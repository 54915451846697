import { Component, Input } from '@angular/core';
import { CaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';

@Component({
  selector: 'app-incident-needing-action-preview',
  templateUrl: './incident-needing-action-preview.component.html',
})
export class IncidentNeedingActionPreviewComponent {
  @Input()
  incidents: CaseXDR[] = [];

  severityBg: string[] = ['#FFD200', '#FFD200', '#FF7900', '#FF0000', '#c00000'];
  severityColor: string[] = ['black', 'black', 'black', 'white', 'white'];

  getSeveritySpanClass(severity: string) {
    if (parseInt(severity) >= 0 && parseInt(severity) <= 4)
      return `py-1 px-2 text-${this.severityColor[parseInt(severity)]} rounded-pill`;
    return `py-1 px-2 rounded-pill`;
  }

  getSeveritySpanStyle(severity: string) {
    if (parseInt(severity) >= 0 && parseInt(severity) <= 4)
      return `background-color: ${this.severityBg[parseInt(severity)]}`;
    return '';
  }
}
