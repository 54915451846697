import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpUsersPreferencesService } from 'src/app/shared/httpServices/http-users-preferences.service';
import { Survey, SurveyReport } from 'src/app/shared/model/feedback';
import { OffsetDatetimePipe } from 'src/app/shared/pipes/offsetdatetime.pipe';
import { TimeZoneDateTimePipe } from 'src/app/shared/pipes/timezone.pipe';
import { MultiSelectAnswerComponent } from './answer-results/multi-select-answer/multi-select-answer.component';
import { ClosedChoiceAnswerComponent } from './answer-results/closed-choice-answer/closed-choice-answer.component';
import { ListOrderAnswerComponent } from './answer-results/list-order-answer/list-order-answer.component';
import { SingleSelectAnswerComponent } from './answer-results/single-select-answer/single-select-answer.component';
import { RatingAnswerComponent } from './answer-results/rating-answer/rating-answer.component';

@Component({
  selector: 'app-survey-summary',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule, OffsetDatetimePipe, 
    TimeZoneDateTimePipe, MultiSelectAnswerComponent, ClosedChoiceAnswerComponent, 
    ListOrderAnswerComponent, SingleSelectAnswerComponent, RatingAnswerComponent],
  templateUrl: './survey-summary.component.html',
  styleUrl: './survey-summary.component.scss'
})
export class SurveySummaryComponent {

  public loaded = false;
  public surveys: Survey[] = [];
  public selectedSurvey: Survey;
  public surveyResult: SurveyReport;

  constructor(
    private httpUsersPreferencesService: HttpUsersPreferencesService
  ) {
    this.loadSurveyList();
  }



  /**
   * load all existing surveys
   */
  private loadSurveyList() {
    this.httpUsersPreferencesService.getAllSurveys('open').subscribe({
      next:(surveys) => {
        this.surveys = surveys;
        if(this.surveys.length > 0) {
          this.selectedSurvey = this.surveys[0];
          this.updateSelectedSurvey();
        } else {
          this.loaded = true;
        }
      }
    })
  }

  /**
   * load answers for the selected survey
   */
  public updateSelectedSurvey() {
    this.httpUsersPreferencesService.getSurveyResults(this.selectedSurvey._id.$oid).subscribe({
      next: (results) => {
        this.surveyResult = results;
        if(this.surveyResult.answers.length > 0) {
          this.surveyResult.answers.forEach(answer => {
            // get the type of question
            const question = this.selectedSurvey.questions.find(q => q._id.$oid === answer.questionId);
            answer.question = question;    
              this.loaded = true;
           })
        }
      }
    })
  }

}
