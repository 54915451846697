import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GCPFile, SharedGCPFile } from '../model/files';
import { Observable } from 'rxjs';
import { FileImportReport } from '../model/import-file.model';

@Injectable()
export class FileAccessService {
    private fileAccessURL = environment.endpoints.fileAccess;

    // Define a mapping between file extensions and MIME types
    public mimeTypeMap: { [key: string]: string } = {
        csv: 'text/csv',
        txt: 'text/plain',
        pdf: 'application/pdf',
    };

    constructor(private http: HttpClient) {
    }

    getFilesList(): Promise<GCPFile[]> {
        return this.http
            .get(this.fileAccessURL + 'view')
            .toPromise()
            .then(response => response as GCPFile[])
            .catch(this.handleError);
    }

    getFilesByFolder(directory: string): Promise<GCPFile[]> {
        return this.http
            .get(this.fileAccessURL + 'view/' + directory)
            .toPromise()
            .then(response => response as GCPFile[])
            .catch(this.handleError);
    }

    public getSharedFiles(): Observable<SharedGCPFile[]> {
        return this.http.get<SharedGCPFile[]>(this.fileAccessURL + "sharedFiles");
    }

    public getUserSharedFiles(): Observable<SharedGCPFile[]> {
        return this.http.get<SharedGCPFile[]>(this.fileAccessURL + "mySharedFiles");
    }

    public downloadSharedFile(filename: string): Observable<any> {
        // send delete request with blobId in body parameter
        return this.http.post(this.fileAccessURL + "downloadSharedFile", {filename: filename}, {responseType: 'blob'});
    }

    public deleteSharedFile(filename: string): Observable<any> {
        // send delete request with blobId in body parameter
        return this.http.delete(this.fileAccessURL + "sharedFiles/" + filename);
    }

    public uploadFile(file: File, recipients: string[]): Observable<FileImportReport> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('recipients', recipients.toString())
    
        return this.http.post<FileImportReport>(this.fileAccessURL + 'sharedFiles', formData, {
            responseType: 'json',
        });
      }

    downloadFile(fileName: string): any {
        return this.http.post(this.fileAccessURL + 'download', JSON.stringify({ fileName }), {responseType: 'blob'});
    }

    downloadFileByDirectory(fileName: string, directory: string): any {
        return this.http.post(this.fileAccessURL + 'folder/download', JSON.stringify({ fileName, directory }), {responseType: 'blob'});
    }

    // Get the MIME type based on the file extension
    getMimeType(fileExtension: string): string | undefined {
        return this.mimeTypeMap[fileExtension.toLowerCase()];
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
