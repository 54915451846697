import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notification, NotificationAudience, NotificationCategory } from '../model/notification';
import { HttpClient } from '@angular/common/http';
import { constants } from '../constants';
import { shareReplay } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifCreateURL = environment.endpoints.userpreferences + 'notifications';
  private userPrefGetNotificationsURL = environment.endpoints.userpreferences + 'getNotifications';
  private userPrefGetNotificationsCountURL = environment.endpoints.userpreferences + 'getNotificationsCount';
  private activityCategories: NotificationCategory[] = [
    NotificationCategory.reportDownload,
    NotificationCategory.fileScan,
    NotificationCategory.generateReport
  ];

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  isActivity(notification: Notification): boolean {
    return this.activityCategories.includes(notification.notification.category);
  }

  async getNotifications(offset: number, limit: number): Promise<Notification[]> {
    let query = `${this.userPrefGetNotificationsURL}?offset=${offset}&limit=${limit}`;
    return this.http
      .get(query)
      .pipe(shareReplay())
      .toPromise()
      .then(res => res as Notification[])
      .catch(this.handleError);
  }

  async getNotificationsCount(): Promise<number> {
    let query = this.userPrefGetNotificationsCountURL;
    return this.http
      .get(query)
      .pipe(shareReplay())
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  sendNotification(
    notifCategory: NotificationCategory,
    keyItem: {title: string; content: string},
    notifAudience = NotificationAudience.admins
  ) {

    let user: string;
    if (JSON.parse(sessionStorage.getItem(constants.local.userPreferences))) {
      user = JSON.parse(sessionStorage.getItem(constants.local.userPreferences))._id;
    }

    const notification = {
      publishedTime: Date.now(),
      notification: {
          title: keyItem.title,
          description: keyItem.content,
          popup: false,
          category: notifCategory,
          audience: notifAudience,
          author: user,
          toBeTranslated: true
      }
    };
    this.saveNotification(notification as Notification);
  }

  private async saveNotification(notification: Notification): Promise<Notification> {
    return this.http
      .post(this.notifCreateURL, JSON.stringify(notification))
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  public translateNotification(notification: Notification): Notification {
    if (!notification.notification.toBeTranslated) {
        return notification;
    }
    const notifBaseTitle = this.translateService.instant(
      `pages.dashboard.notificationsContent.titles.${NotificationCategory[notification.notification.category]}`
    );

    const notifBaseContent = this.translateService.instant(
        `pages.dashboard.notificationsContent.contents.${NotificationCategory[notification.notification.category]}`
    );
    const unknown = this.translateService.instant('pages.generic.unknownUser');

    notification.notification.title = `${notifBaseTitle}: ${notification.notification.title}`;
    notification.notification.description =
      `${notification.notification.description} ${notifBaseContent} ${notification.notification.author || unknown}`;
    return notification;
  }

  /**
   * Generic error handler for this service
   *
   * @param error
   */
   private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
