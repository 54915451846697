<h3>
  <ng-template #backTo> {{ 'pages.updates.certTools.backToCertTools' | translate }} </ng-template>
  <a class="btn btn-icon" [ngbTooltip]="backTo" href="/updates/services/certtools"><fa-icon [icon]="['fas', 'chevron-left']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon></a>
  {{ 'pages.updates.certTools.mfdHistory.title' | translate }}
</h3>

<div class="row faketableheaderrow">
  <div class="col-5 faketableheader" >{{ 'pages.generic.name' | translate }}
  </div>
  <div class="col-3 d-none d-md-block faketableheader">{{ 'pages.generic.date' | translate }}
  </div>
  <div class="col-4 faketableheader">{{ 'pages.generic.user' | translate }}
  </div>
</div>

<div *ngFor="let scan of history" class="row faketablerow">
  <div class="col-5 faketablecell">
    {{ scan.filename}}
  </div>
  <div class="col-3 faketablecell">
    {{ scan.time * 1000 | date : 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="col-4 faketablecell">
    {{ scan.email }}
  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="history === undefined">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div *ngIf="history?.length === 0" class="alert alert-warning mt-4 mb-5" role="alert">
  <span class="alert-icon"><span class="visually-hidden">Info</span></span>
  <p>{{ 'pages.updates.certTools.mfdHistory.noFileScanned' | translate }}</p>
</div>

