import { Component, OnInit } from '@angular/core';
import { constants } from 'src/app/shared/constants';
import { Case } from 'src/app/shared/model/itsm';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-open-cases',
  templateUrl: './open-cases.component.html',
  styleUrls: ['./open-cases.component.scss']
})
export class OpenCasesComponent extends WidgetBaseComponent implements OnInit {

   // data
   cases: Case[];
   casesCount: number;
   searchCriteria: SupportCaseSearchCriteria;
   casesLoaded= false;

   constructor(private alertService: AlertService, private itsmService: ItsmService) {
    super();
   }

   ngOnInit(): void {
       this.caseSearchOpen();
   }

   /**
    * Triggers case search for cases that are pending Ocd
    */
   private caseSearchOpen(): Promise<any> {
     return new Promise((resolve,reject) => {
       this.searchCriteria = new SupportCaseSearchCriteria();
       this.searchCriteria.status = constants.itsm.globalStates.open;

       this.searchCriteria.SNCount = true;
       this.searchCriteria.limit = undefined;
       this.searchCriteria.byPassSF = true;
       this.searchCriteria.forceTMLimit = true;

       // fetch the count of matching records (happens before the actual results are fetched)
       this.itsmService.caseSearch(this.searchCriteria)
         .then(res => {
           if(res){
             this.casesCount = res;
           }else{
             this.casesCount = 0;
           }

           // fetch the actual records
           this.searchCriteria.SNCount = false;
           this.searchCriteria.limit = 12;
           this.searchCriteria.byPassSF = true;
           this.searchCriteria.forceTMLimit = true;

           this.itsmService.caseSearch(this.searchCriteria)
             .then(res2 => {
               this.cases = res2;
               this.casesLoaded = true;
               resolve({abandoned: false});
             })
             .catch(err => {
               throw err;
             });
         })
         .catch(err => {
           reject();
           this.handlerError(err);
         });
     });
   }

  /**
   * Error handling
   *
   * @param err
   */
  private handlerError(err){
    this.alertService.handlerError(err);
  }
}
