import { Component, Input, OnInit } from '@angular/core';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { Utilities } from 'src/app/shared/utilities';
import { NgbSingleSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
import { TranslateService } from '@ngx-translate/core';
import {
  ChartsDataDTO,
  changeChartsMonth,
  initChartsData,
  securityIncidentCallback
} from 'src/app/reports/reports-live/report-live-securitycase/chartsdata-utils';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-true-positives-vs-false-positives',
  templateUrl: './true-positives-vs-false-positives.component.html',
  styleUrls: ['./true-positives-vs-false-positives.component.scss']
})
export class TruePositivesVSFalsePositivesComponent extends WidgetBaseComponent implements OnInit {

  @Input() smallScreenMode: boolean;

  private chartList = ['tpvsfp', 'actor', 'killchain', 'action', 'assets'];
  public chartIndex = 0;
  public chartsData: ChartsDataDTO;

  // template presets
  public slideInterval = 5000;
  public selectedChart = 'tpvsfp';
  public chartsWidth = '80%';
  public chartsHeight = '25rem';

  public loaded: boolean;
  public errorMessage: string;

  constructor(
    private itsmService: ItsmService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.loaded = false;
    this.chartsData = initChartsData();
    this.itsmService.securityIncidentReport(Utilities.get6MonthsAgo())
    .then( res => {
      this.chartsData = securityIncidentCallback(res, this.chartsData)
    })
    .catch( err => this.errorMessage = `Error fetching data: ${err}`)
    .finally( () => {
      if (!this.chartsData || this.chartsData.data.length === 0) {
        this.errorMessage = this.translateService.instant('pages.securitycase.noDataAvailable');
      }
      this.loaded = true;
    });
  }

  onChangeChart(event: NgbSingleSlideEvent) {
    if (event.direction === 'start') {
      this.chartIndex = (this.chartIndex + 1) % (this.chartList.length);
    } else if (event.direction === 'end') {
      this.chartIndex = this.chartIndex !== 0 ?
        (this.chartIndex - 1) % (this.chartList.length) :
        this.chartList.length-1;
    }
    this.selectedChart = this.chartList[this.chartIndex];
  }

  canGetNextMonth() {
    return Array.from(this.chartsData.caseMap).length > this.chartsData.monthIndex + 1;
  }

  canGetPreviousMonth() {
    return this.chartsData.monthIndex > 0;
  }

  changeMonth(direction: 'fwd' | 'back') {
    this.chartsData = changeChartsMonth(direction, this.chartsData);
  }

}
