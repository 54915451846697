import { Injectable } from '@angular/core';
import Highcharts, { PointOptionsObject } from 'highcharts';
import { COLOR_CRITICAL, COLOR_HIGH, COLOR_LOW, COLOR_MEDIUM } from '../shared/model/cybersocxdr/charts/colors';
import {
  HighChartsHistogramSerie,
  HighChartsMapSerie,
  PackedBubblePoint,
  PackedBubbleSerie,
  PieDrillDownSerie,
  RepartitionChartItem,
} from '../shared/model/cybersocxdr/charts/common';
import { XtendedFacet, XtendedGroupbyFacet } from '../shared/model/cybersocxdr/facets';
import { Fields4A3W } from '../shared/model/cybersocxdr/fields4A3W';
import { getDefaultHighchartOptions } from './utils';

@Injectable({
  providedIn: 'root',
})
export class XtendedHighchartService {
  private firstLetterToUpperCase(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDataName(name: string, groupBy: XtendedGroupbyFacet | Fields4A3W): string {
    if (groupBy === XtendedFacet.OWNER) {
      return this.firstLetterToUpperCase(name) || 'Unassigned';
    } else {
      return this.firstLetterToUpperCase(name);
    }
  }

  formatDataColor(name: string, groupBy: XtendedGroupbyFacet | Fields4A3W): string | undefined {
    switch (groupBy) {
      case XtendedFacet.OWNER:
        return name ? undefined : '#085EBD';
      case XtendedFacet.SEVERITY:
        return this.severityColor(name);
      default:
        return undefined;
    }
  }

  //for trimap
  drillPointMapper(items: RepartitionChartItem[], groupBy: XtendedGroupbyFacet | Fields4A3W): PointOptionsObject[] {
    return items
      .map((item, i) => [
        {
          name: this.formatDataName(item.name, groupBy),
          id: item.name,
          y: item.volume,
          value: item.volume,
          color:
            this.formatDataColor(item.name, groupBy) ??
            getDefaultHighchartOptions().colors[i % getDefaultHighchartOptions().colors.length],
        },
        ...(item.detail?.map((subItem) => {
          return {
            parent: item.name,
            name: this.formatDataName(subItem.name, groupBy),
            id: `${item.name}-${subItem.name}`,
            y: subItem.volume,
            value: subItem.volume,
          };
        }) ?? []),
      ])
      .flat();
  }

  barOvertimeChartSerie(
    data: HighChartsHistogramSerie[],
    groupBy: XtendedGroupbyFacet
  ): Highcharts.SeriesColumnOptions[] {
    return data.map((item) => {
      return {
        type: 'column',
        name: item.name,
        y: item.data.reduce((sum, point) => sum + point[1], 0),
        data: item.data.map((point) => {
          return { x: point[0], y: point[1], facets: [groupBy], facetValues: [item.name] };
        }),
      };
    });
  }
  worldMapChartSerie2(data: HighChartsMapSerie[], groupBy: XtendedGroupbyFacet): Highcharts.SeriesMapOptions[] {
    return data.map((item) => {
      return {
        type: 'map',
        name: item.name,
        iso3: item.iso3,
        volume: item.volume,
        data: [
          {
            code: item.iso3,
            value: item.volume,
            facets: [groupBy],
            facetValues: [item.name],
          },
        ],
      };
    });
  }
  pieChartSerie(data: RepartitionChartItem[], groupBy: XtendedGroupbyFacet): Highcharts.SeriesPieOptions {
    return {
      name: groupBy,
      type: 'pie',
      data: data.map((item, i) => {
        return {
          name: this.formatDataName(item.name, groupBy),
          facets: [groupBy],
          facetValues: [item.name],
          y: item.volume,
          drilldown: item?.detail ? item.name : null,
          color:
            this.formatDataColor(item.name, groupBy) ??
            getDefaultHighchartOptions().colors[i % getDefaultHighchartOptions().colors.length],
        };
      }),
    };
  }

  packedBubbleChartSerie(
    data: PackedBubbleSerie,
    groupBy: XtendedGroupbyFacet[]
  ): Highcharts.SeriesPackedbubbleOptions {
    return {
      name: this.formatDataName(data.name, groupBy?.[0]),
      type: 'packedbubble',
      data: this.packedBubbleChartSerieData(data, groupBy),
    };
  }

  packedBubbleChartSerieData(data: PackedBubbleSerie, groupBy: XtendedGroupbyFacet[]): PackedBubblePoint[] {
    return data.data.map((item) => {
      const basePoint: PackedBubblePoint = {
        facets: groupBy,
        facetValues: [data.name, item.name],
        name: item.name,
        id: item.name,
        value: item.value,
        color: this.formatDataColor(item.name, groupBy?.[0]),
      };
      if (item.data) {
        basePoint.children = this.packedBubbleChartSerieData(item, groupBy);
      }

      return basePoint;
    });
  }

  pieChartDrilldownSerie(data: RepartitionChartItem[]): PieDrillDownSerie[] {
    return data
      .filter((d) => d.detail)
      .map((d) => {
        return { name: d.name, id: d.name, data: d.detail.map((x) => [x.name, x.volume]) };
      });
  }

  severityColor(severity: string): string | undefined {
    switch (severity) {
      case 'critical':
        return COLOR_CRITICAL;
      case 'high':
        return COLOR_HIGH;
      case 'medium':
        return COLOR_MEDIUM;
      case 'low':
        return COLOR_LOW;
      default:
        return undefined;
    }
  }

  severityFontWhite(severity: string): boolean {
    switch (severity) {
      case 'critical':
      case 'high':
        return true;
      default:
        return false;
    }
  }
}
