import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { Activity } from 'src/app/shared/model/activity';
import { ActivitySearchCriteria, DateRange } from 'src/app/shared/model/searchCriteria';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { Utilities } from 'src/app/shared/utilities';

@Component({
  selector: 'app-activitydashboard',
  templateUrl: './activitydashboard.component.html',
  styleUrls: ['./activitydashboard.component.scss']
})
export class ActivityDashboardComponent implements OnInit {
  advSearch: boolean;
  perPage = constants.recordsPerPage.activitydashboard;
  currentPage: number;

  activities: Activity[];
  subsetActivities: Activity[];
  loadedActivities: boolean;

  // search fields
  searchCriteria: ActivitySearchCriteria;
  type: string;
  dateFrom: string;
  dateTo: string;
  dateRanges: DateRange[];
  validDateRanges: boolean;

  constructor(
    private activitySearch: ActivityService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userActivity: UserActivityService
  ) { }

  ngOnInit() {
    //initilization
    this.searchCriteria = new ActivitySearchCriteria();
    this.advSearch = false;
    this.currentPage = 1;
    this.loadedActivities = false;

    this.dateFrom = Utilities.getTodayMinusDays(7);
    this.dateTo = Utilities.getToday();
    this.dateRanges = [DateRange.date];
    this.validDateRanges = true;

    // load activity
    this.activitySearch.loadActivityDays(7, false)
      .then(res => {
        this.activities = res;
        this.subsetActivities = this.activities.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
        this.loadedActivities = true;
      })
      .catch(err => {
        this.alertService.handlerError(err);
      });

      this.userActivity.logActivity(TYPES.activity, PAGES.activityDashboard, ACTIONS.visit);
  }

  pageActivity($event){
    this.loadedActivities = false;
    this.subsetActivities = this.activities.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
    this.loadedActivities = true;
  }

  validateDateRanges() {
    if (this.dateFrom && this.dateTo) {
      this.searchCriteria.dateFrom = this.dateFrom;
      this.searchCriteria.dateTo = this.dateTo;
      this.validDateRanges = this.searchCriteria.checkDateRanges(this.dateRanges);
    } else {
      this.validDateRanges = true;
    }
  }

  search() {
    if(this.validDateRanges) {
      this.currentPage = 1;
      this.loadedActivities = false;
      // load activity
      this.activitySearch.loadActivity(this.dateFrom, this.dateTo, false, this.type)
      .then(res => {
        this.activities = res;
        this.subsetActivities = this.activities.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
        this.loadedActivities = true;
      })
      .catch(err => {
        this.alertService.handlerError(err);
      });
    } else {
      this.subsetActivities = [];
      this.loadedActivities = true;
      this.alertService.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantReturnResults')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  reset() {
    delete this.type;
    delete this.dateFrom;
    delete this.dateTo;
    this.ngOnInit();
  }

}
