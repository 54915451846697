import { Utilities } from "../utilities";

export class FilterParameters {
  createdFrom: string;
  createdTo: string;
  selectedRadioRange: string;
  selectedRadioDate: string;

  constructor(
    createdFrom: string = Utilities.get1MonthAgo(),
    createdTo: string = Utilities.getToday(),
    selectedRadioRange: string = 'all',
    selectedRadioDate: string = 'lastMonth'
  ) {
    this.createdFrom = createdFrom;
    this.createdTo = createdTo;
    this.selectedRadioRange = selectedRadioRange;
    this.selectedRadioDate = selectedRadioDate;  
  }
}
