import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Signal } from '../../model/signal';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-signal-list',
  templateUrl: './signal-list.component.html',
  styleUrls: ['./signal-list.component.scss']
})
export class SignalListComponent implements OnInit {

  @Input()
  signals: Signal[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Input()
  form: FormGroup;

  @Input()
  canSort: boolean;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  get sortBy() {
    if(this.form) {
      return this.form.get('sortBy');
    }
  }

  get sortOrder() {
    if(this.form) {
      return this.form.get('sortOrder');
    }
  }

  @Input()
  loading: boolean;

  isWorldWatch_User = false;
  worldWatch_Group = environment.world_watch_group;
  userObject: any;

  constructor(private userAuthService: UserAuthService) { }

  ngOnInit() {
    if(this.userAuthService.userDetails?.groups.indexOf(this.worldWatch_Group) > -1){
      this.isWorldWatch_User = true;
    }else{
      // if the user doesn't have worldwatch role
      this.isWorldWatch_User = false;
    }
  }

  flagSignal(event, id: number): void {
    event.stopPropagation();
    this.flagEmitter.emit(id.toString());
  }

  sortAdvisories(field: string): void {
    if(this.canSort) {
      if(field === this.sortBy.value) {
        const newOrder = this.sortOrder.value === 'asc' ? 'desc' : 'asc';
        this.sortEmitter.emit({sortOrder: newOrder});
      } else {
        this.sortEmitter.emit({sortBy: field, sortOrder: 'desc'});
      }    
    }

  }

}
