import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { environment } from 'src/environments/environment';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';
import { WorldWatchUtilService } from '../worldwatch-util-service';
import { saveAs } from 'file-saver';
import { TimeStamp } from 'src/app/shared/model/worldwatch';

@Component({
  selector: 'app-viewsignal',
  templateUrl: './viewsignal.component.html',
  styleUrls: ['./viewsignal.component.scss']
})
export class ViewSignalComponent implements OnInit {

  private advisoryId: string;
  public advisory: Advisory;
  public allTags: Tag[];
  public createBlock: ContentBlock;
  public haveTicket = false;
  public isDarkTheme: boolean;
  public isWorldWatchUser: boolean;
  public loadingSignal: boolean;
  public order = 'DESC';
  public selectedTab = 'analysis';
  public severityStyle: string;
  public tags: string[];
  public updateBlocks: ContentBlock[] = [];
  public internal = SourceType.INTERNAL;
  public external = SourceType.EXTERNAL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private worldwatchService: WorldWatchService,
    private alertService: AlertService,
    private userAuthService: UserAuthService,
    private itsmService: ItsmService,
    private userActivity: UserActivityService,
    private contextService: ContextService,
    private worldWatchUtilService: WorldWatchUtilService
  ) { }

  ngOnInit(): void {
    this.isDarkTheme = this.contextService.isDarkTheme();
    this.route.params.subscribe(params => {
      this.advisoryId = params.signalRef;
      this.loadAdvisory();
    });
  }

  /**
   * load advisory
   */
  private loadAdvisory() {
    this.loadingSignal = true;

    if (this.userAuthService.userDetails?.groups.indexOf(environment.world_watch_group) > -1) {

      this.isWorldWatchUser = true;
      this.worldwatchService.getAdvisoryById(this.advisoryId).subscribe({
        next: response => {
          this.advisory = response.advisory;
          let sortedTag: SortedTags = response.sortedTags
          this.allTags = sortedTag.tags.concat(sortedTag.sectors, sortedTag.countries, sortedTag.regions);

          this.manageResponse();
          this.fetchTickets();
          this.loadingSignal = false;
        },
        error: error => {
          this.loadingSignal = false;
          this.alertService.handlerError(error);
        }
      })

    } else {
      // if the user doesn't have worldwatch role
      this.loadingSignal = false;
      this.isWorldWatchUser = false;
    }

    this.userActivity.logActivity(TYPES.resources, PAGES.viewSignal, ACTIONS.visit);
  }

  /**
   * manage advisory response
   */
  private manageResponse() {
    this.severityStyle = this.worldWatchUtilService.getSeverityStyle(this.advisory.severity);
    this.advisory.contentBlocks.forEach(block => {
      if (block.index === 0) {
        this.createBlock = block;
      } else {
        this.updateBlocks.push(block)
      }
    });
  }

  /**
  * fetch tickets
  */
  private fetchTickets() {
    const searchCritera = new SupportCaseSearchCriteria();
    searchCritera.worldWatchSignal = this.advisory.tdcId;
    this.itsmService.caseSearch(searchCritera)
    .then(res => {
      if (res.length > 0) {
        this.haveTicket = true;
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  public getExternalLink(block: ContentBlock) {
    return block.sources.filter(source => source.type === SourceType.EXTERNAL);
  }

  public getInternalLink(block: ContentBlock) {
    return block.sources.filter(source => source.type === SourceType.INTERNAL);
  }

  /**
   * download advisory to PDF
   */
  public downloadPDF(): void {
    const prefix = "advisory_";
    const extension = ".pdf";
    this.worldwatchService.getPDFAdvisory(this.advisoryId)
      .subscribe({
        next: (response) => {
          const name = prefix + this.advisoryId + "_" + Date.now() + extension;
          saveAs(new Blob([response]), name);
        },
        error: (error) => {
          this.alertService.handlerError(error)
        }
      })
  }

  /**
   * open ticket detail
   */
  public viewTickets() {
    this.router.navigate(['/support/dashboard/worldwatch'], { queryParams: { signalRef: this.advisory.tdcId } })
  }

  /**
   * route to worldwatch dashboard with the selected tab
   */
  public searchAdvisoriesByTag(selectedTag: string) {
    let type = constants.worldwatch.defaultTagType;
    let tag = selectedTag;
    if(tag.startsWith("victim:country")) {
      type = 'country';
    } else if (tag.startsWith("victim:continent")) {
      type = 'region';
    } else if (tag.startsWith("victim:sector")) {
      type = 'sector';
    }
    this.router.navigate(['/updates/worldwatch/dashboard'], { queryParams: { tag: selectedTag, type: type } })
  }

  /**
   * switch activated tab
   */
  public changeTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  /** 
   * revert blocks
   */
  public revertBlocks() {
    this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    this.updateBlocks.reverse();
  }

  /**
   * scroll to the anchor ID
   */
  public scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * switch active tab to appendice block 
   */
  public viewAppendice(elementId: number) {
    this.selectedTab = 'appendices';
    setTimeout(() => {
      this.scrollTo('appendice_' + elementId);
    })
  }

}

export class AdvisoryResponse {
  advisory: Advisory;
  sortedTags: SortedTags;
}

export class Advisory {
  id: string;
    tdcId: string;
    title: string;
    severity: string;
    tags: string[];
    threatCategory: string;
    contentBlocks: ContentBlock[];
    timestampCreated: TimeStamp;
    timestampUpdated: TimeStamp;
    license_agreement: string;
}

export class SortedTags {
  tags:Tag[];
  sectors:Tag[];
  countries:Tag[];
  regions:Tag[];
}

export class Tag {
  label: string;
  key: string;
}

export class ContentBlock {
  id: number;
  advisory: number;
  index: number;
  title: string;
  tags: string[];
  severity: number;
  analyst: number;
  lastModifiedBy: string;
  threatCategory: string;
  executiveSummary: string;
  whatYouWillHear: string;
  whatItMeans: string;
  whatYouShouldDo: string;
  whatWeAreDoing: string;
  sources: SourceSchema[];
  detectionRules: DetectionRuleSchema[];
  datalakeUrl: DatalakeUrlSchema;
  other: string;
  timestampCreated: TimeStamp;
  timestampUpdated: TimeStamp;
}

export class SourceSchema {
  id: number;
  title: string;
  url: string;
  description: string;
  type: SourceType;
}
export class DetectionRuleSchema {
  id: number;
  title: string;
  description: string;
  content: string
}

export class DatalakeUrlSchema {
  id: number;
  title: string;
  description: string;
  url: string
}

export enum SourceType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL"
}
