import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ClientEntityDto, XtendedClientService } from 'src/app/shared/services/xtendedsoc/xtended-client.service';

@Component({
  selector: 'app-xtended-entity-detail',
  templateUrl: './entity-detail.component.html',
})
export class XtendedSOCEntityDetailComponent implements OnInit {
  public entity: ClientEntityDto | null = null;
  public newMemberEmail = '';
  public entityId: number | null = null;

  constructor(
    private xtendedClientService: XtendedClientService,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['entityId']) {
        this.entityId = Number(params['entityId']);
        this.loadEntity();
      }
    });
  }

  private async loadEntity(): Promise<void> {
    if (!this.entityId) return;

    try {
      this.entity = await this.xtendedClientService.getEntityById(this.entityId);
    } catch (error) {
      this.alertService.addError(error?.error?.message || 'Failed to load entity');
      this.entity = null;
    }
  }

  async addMember(): Promise<void> {
    if (!this.entityId || !this.newMemberEmail) return;

    try {
      await this.xtendedClientService.addUserToEntity(this.entityId, this.newMemberEmail);
    } catch (error) {
      this.alertService.addError(error?.error?.message || 'Failed to add member to entity');
    }
    await this.loadEntity();
    this.newMemberEmail = '';
  }

  async removeMember(userInEntityId: number): Promise<void> {
    if (!this.entityId || !userInEntityId) return;

    try {
      await this.xtendedClientService.removeUserFromEntity(userInEntityId);
      await this.loadEntity();
    } catch (error) {
      this.alertService.addError(error?.error?.message || 'Failed to remove member from entity');
    }
  }
}
