@if(loading && !(assets?.length > 0)) {
<div class="row justify-content-center p-5">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
} @else {
  @if (assets) {
    @if (assets.length === 0) {
    <div class="row p-3 justify-content-center redborder-sides">
      <div class="col-md-12 table-border justify-content-center">
        {{ 'pages.assets.dashboard.noResults' | translate }}
      </div>
    </div>
    } @else {
    <div class="row">
      
      @for (ass of assets; track ass.id) {
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pb-2">
        {{ass.status}}
        <div class="card highlight h-100 boxshadow" [routerLink]="['/asset/viewAsset/' + ass.id]" routerLinkActive="activeLink">

          <div class="card-header" [ngClass]="ass.active ? 'active' : 'inactive'">
            {{ ass.model ? ass.model +  ' | ' + ass.name : ass.name}}
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-4">{{ 'pages.generic.active' | translate }}</div>
              <div class="col-8">
                <fa-icon [icon]="['fas', 'check']" class="p-1" *ngIf="ass.active==='true'"></fa-icon>
                <fa-icon [icon]="['fas', 'window-close']" class="p-1" *ngIf="ass.active!=='true'"></fa-icon>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-4">{{ 'pages.assets.dashboard.fieldTitles.serialNumber' | translate }}</div>
              <div class="col-8">{{ass.serialNumber}}</div>
            </div>
            <div class="row">
              <div class="col-4">{{ 'pages.assets.dashboard.fieldTitles.vendor' | translate }}</div>
              <div class="col-8">{{ass.assetVendor ? ass.assetVendor : '--'}}</div>
            </div>
            <div class="row">
              <div class="col-4">{{ 'pages.assets.dashboard.fieldTitles.osVersion' | translate }}</div>
              <div class="col-8">{{ass.osVersion ? ass.osVersion : '--'}}</div>
            </div>
            <div class="row">
              <div class="col-4">{{ 'pages.assets.dashboard.fieldTitles.friendlyName' | translate }}</div>
              <div class="col-8">{{ass.friendlyName ? ass.friendlyName : '--'}}</div>
            </div>
            <div class="row">
              <div class="col-4">{{ 'pages.assets.dashboard.fieldTitles.hostName' | translate }}</div>
              <div class="col-8">{{ass.hostName ? ass.hostName : '--'}}</div>
            </div>
            <div class="row">
              <div class="col-4"> {{ 'pages.assets.dashboard.fieldTitles.entitlements' | translate }}</div>
              <div class="col-8">{{ass?.entitlements?.length > 0 ? ass?.entitlements?.[0].id : '--'}}</div>
            </div>
          </div>
    
          <div class="card-footer">
            <div style="float:left; width:100%"></div>
            <span class="justify-content-end" style="display:flex;">
              <div class="spinner-grow spinner-grow-sm text-primary" style="margin-left: -100px" *ngIf="flaggingRef && flaggingRef === ass.id">
                <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
              </div>
              @if ((!flagRefs.includes(ass.id)) && (!flaggingRef || (flaggingRef && flaggingRef !== ass.id))) {
              <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagAsset($event, ass.id)"></fa-icon>
              }
              @if ((flagRefs.includes(ass.id)) && (!flaggingRef || (flaggingRef && flaggingRef !== ass.id))) {
              <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagAsset($event, ass.id)"></fa-icon>
              }
            </span>
          </div>
        </div>
    
      </div>
      }
    </div>
    }
  }
}
