import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

export type QueryOptions = {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  withCredentials?: boolean;
};

export abstract class XtendedBaseService {
  constructor(protected http: HttpClient) {}

  /** Wrapper for the promise and error handler */
  genericRequest<T>(method: string, url: string, options?: QueryOptions): Promise<T> {
    return firstValueFrom(this.http.request<T>(method, url, options))
      .then((response) => response)
      .catch(this.handleError);
  }

  genericGet<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('get', url, options);
  }

  genericPost<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('post', url, options);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
