import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppDirectivesModule } from '../../directives/app-directives.module';
import { ContextService } from '../../services/context-service';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { IconsModule } from '../../modules/icons/icons.module';

@Component({
  selector: 'app-infinite-select',
  standalone: true,
  imports: [
    InfiniteScrollModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    AppDirectivesModule,
    IconsModule
  ],
  providers: [
    ContextService,
  ],
  templateUrl: './infinite-select.component.html',
  styleUrl: './infinite-select.component.scss'
})
export class InfiniteSelectComponent implements OnInit, OnChanges {

  @Input() tmpOptions: any[] = [];
  @Input() selectedOptionInput: any = null;
  @Input() maxOptionsCount: number = 0;
  @Input() labelKey: string = 'label';
  @Input() isDarkTheme: boolean = false;
  @Input() clearSelection: boolean = false;

  // emit the current number of items on scroll
  @Output() scrollEvent: EventEmitter<number> = new EventEmitter();
  // emit the selected item index
  @Output() selectEvent: EventEmitter<Object> = new EventEmitter();
  // emit the search term
  @Output() searchEvent: EventEmitter<Object> = new EventEmitter();

  public userInput: string;
  public userInputUpdate = new Subject<string>();
  public selectedOptionIndex: number = -1;
  public selectedOption = null;
  public optionsCanvas: boolean = false;
  public optionsLoading: boolean = true;
  public options: any[];

  private stopInputUpdate$: Subject<void> = new Subject();

  
  @HostListener('keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      // prevent native scroll since it's specifically handled below
      event.preventDefault();
    }
    switch(event.key) {
      case 'Escape': this.closeOptionsPanel(); // allow to close panel using escape key
        break;
      case 'Enter': this.chooseSelectedOption(); // go to selected option
        break;
      case 'ArrowUp': this.keyScroll(event.key); // scroll up
        break;
      case 'ArrowDown': this.keyScroll(event.key); // scroll down
        break;
    }
  }

  constructor() {
    this.subscribeUserInput();
  }
  
  ngOnInit(): void {
    this.optionsLoading = true;
  }

  ngOnDestroy() {
    this.stopInputUpdate$.next();
    this.stopInputUpdate$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tmpOptions?.currentValue !== undefined) {
      this.optionsLoading = true;
      this.options = this.options ?? [];
      this.tmpOptions = changes.tmpOptions.currentValue;
      this.options = this.options.concat(this.tmpOptions);
      this.optionsLoading = false;
    }
    if (changes.selectedOptionInput?.currentValue !== undefined) {
      this.selectOption(this.selectedOptionInput);
    }
    if (changes.clearSelection && !changes.clearSelection.firstChange) {
      this.emptySearch();
    }
  }

  subscribeUserInput() {
    // debounce search
    this.userInputUpdate.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.stopInputUpdate$))
      .subscribe(value => {
        this.triggerSearch(value);
      });
  }

  triggerSearch(value: string) {
    this.optionsLoading = true;
    this.options = [];
    this.selectedOption = null;
    this.selectEvent.emit(this.selectedOption);
    this.searchEvent.emit(value);
  }

  public emptySearch() {
    this.userInput = '';
    this.stopInputUpdate$.next();
    this.stopInputUpdate$.complete();
    this.subscribeUserInput();
    this.triggerSearch('');
  }

  public onScroll() {
    if (this.options.length < this.maxOptionsCount) {
      this.scrollEvent.emit(this.options?.length ?? 0);
      this.optionsLoading = true;
    }
  }

  private keyScroll(key: 'ArrowDown' | 'ArrowUp') {
    let selectedOptionElement: HTMLElement;
    let indexToFocus: number;
    const nbDisplayedOptions: number = 11;

    // Set focus on list for key navigation
    const activeElement = document.activeElement;
    if (activeElement.id !== 'optionsList') {
      document.getElementById('optionsList').focus();
    }

    // go through the results list using arrow up and arrow down
    if (key === 'ArrowUp') {
      this.selectedOptionIndex = this.selectedOptionIndex > 0 ? this.selectedOptionIndex - 1 : this.options.length-1;
    } else {
      this.selectedOptionIndex = this.selectedOptionIndex < this.options.length-1 ? this.selectedOptionIndex + 1 : 0;
    }
    
    // focus the scroll to see selected element on top until scroll reaches the last nbDisplayedCases results. Then focus stays still.
    indexToFocus = this.selectedOptionIndex < this.options.length - nbDisplayedOptions
      ? this.selectedOptionIndex
      : (this.options.length-1) - nbDisplayedOptions;
    selectedOptionElement = document.getElementById('optionIndex_' + indexToFocus) ?? document.getElementById('optionIndex_' + 0);
    selectedOptionElement.scrollIntoView();
  }

  private chooseSelectedOption() {
    this.selectOption(this.options[this.selectedOptionIndex] ?? null)
  }

  public selectOption(option: any) {
    this.selectedOption = option;
    if (this.selectedOption) {
      this.userInput = this.selectedOption?.displayValue;
    }
    this.selectEvent.emit(option);
    this.closeOptionsPanel();
  }

  public closeOptionsPanel() {
    this.optionsCanvas = false;
  }

}
