<div
  class="container p-4 rounded-3 shadow-sm text-dark"
  style="max-width: 1800px"
>
  <div class="row">
    <div class="col-12">
      @for (releaseNote of releaseNotes; track releaseNote.id; let i = $index) {
      <div
        class="accordion mb-4"
        [id]="'releaseNotesAccordion' + releaseNote.id"
      >
        <div class="accordion-item mb-3 rounded-3 overflow-hidden">
          <h1 class="accordion-header border-0" [id]="'heading' + i">
            <button
              class="accordion-button fw-bold text-primary text-dark"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i"
              [attr.aria-expanded]="showPatchNotes[releaseNote.id]"
              [attr.aria-controls]="'collapse' + i"
              (click)="togglePatchNotes(releaseNote.id)"
            >
              <span class="me-2 p-3">
                {{ releaseNote.version }} - {{ releaseNote.title }}
              </span>
            </button>
          </h1>
          <div
            [id]="'collapse' + i"
            class="accordion-collapse collapse px-4"
            [class.show]="showPatchNotes[releaseNote.id]"
            [attr.aria-labelledby]="'heading' + i"
          >
            <div class="accordion-body text-dark">
              <p class="lead mb-4">{{ releaseNote.description }}</p>
              <p class="text-dark mb-4">
                <i class="fas fa-calendar-alt me-2"></i
                >{{
                  'pages.cybersocxdr.releaseNotes.publicationDate' | translate
                }}
                :
                {{ releaseNote.releaseDate | date : 'dd/MM/yyyy' }}
              </p>

              @for (type of getTypesOfPatchNotes(releaseNote); track type.id) {
              <div class="mb-4">
                <h4 class="mb-3">
                  <span
                    class="badge rounded-3"
                    [ngClass]="{
                      'bg-danger': type.title === 'BUG_FIX',
                      'bg-info': type.title === 'FEATURE',
                      'text-white': type.title === 'FEATURE',
                    }"
                  >
                    {{
                      'pages.cybersocxdr.releaseNotes.type.' + type.title
                        | translate
                    }}
                  </span>
                </h4>
                <ul class="list-unstyled mx-2">
                  @for (patchNote of getPatchNotesByType(releaseNote, type.id);
                  track patchNote.id) {
                  <li class="mb-2 p-2">
                    <h6 class="mb-1">{{ patchNote.title }}</h6>
                    <p class="mb-0 text-muted">{{ patchNote.description }}</p>
                  </li>
                  }
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</div>
