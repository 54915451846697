import { Component, Input } from '@angular/core';
import { TrainingItem } from '../trainingdashboard/trainingdashboard.component';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-training-item',
  templateUrl: './training-item.component.html',
  styleUrls: ['./training-item.component.scss']
})
export class TrainingItemComponent {

  @Input() item: TrainingItem;

  constructor(private router: Router, public context: ContextService) {

  }

  open() {
    this.router.navigate([`/updates/training/${this.item.id}`]);
  }

}
