<div class="d-flex faketableheaderrow w-100">
  <div class="col-2 faketableheader click" (click)="sortAssets($event, 'name');">{{
    'pages.assets.dashboard.fieldTitles.name' | translate }}<fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-2 d-none d-lg-block faketableheader click" (click)="sortAssets($event, 'serialNumber');">
    {{ 'pages.assets.dashboard.fieldTitles.serialNumber' | translate }}<fa-icon [icon]="['fas', 'sort']"
      class="p-1"></fa-icon></div>
  <div class="col-2 d-none d-lg-block faketableheader">{{'pages.assets.dashboard.fieldTitles.friendlyName' |
    translate}}</div>
  <div class="col-1 d-none d-lg-block faketableheader">{{'pages.assets.dashboard.fieldTitles.hostName' |
    translate}}</div>
  <div class="col-1 d-none d-md-block faketableheader click"
    (click)="sortAssets($event, 'vendor');">{{ 'pages.assets.dashboard.fieldTitles.vendor' | translate }}<fa-icon
      [icon]="['fas', 'sort']" class="p-1"></fa-icon></div>
  <div class="col-1 d-none d-md-block faketableheader">{{'pages.assets.dashboard.fieldTitles.osVersion' |
    translate }}</div>
  <div class="col-1 d-none d-md-block faketableheader" *ngIf="statusExists">
    {{'pages.assets.dashboard.fieldTitles.status' | translate }}</div>
  <div class="col-1 d-none d-md-block faketableheader">{{ 'pages.generic.active' | translate }}</div>
  <div class="col-1 d-none d-lg-block faketableheader">{{'pages.assets.dashboard.fieldTitles.entitlement' |
    translate }}</div>
  <div class="col-1 d-none d-lg-block faketableheader">{{ 'pages.generic.flag' | translate }}</div>
</div>

  @for(ass of assets; track ass.id) {
    <div class="d-flex faketablerow" [routerLink]="['/asset/viewAsset/' + ass.id]"
      routerLinkActive="activeLink">
      <div class="col-2 faketablecell">{{ ass.model ? ass.model +  ' | ' + ass.name: ass.name}}</div>
      <div class="col-2 d-none d-lg-block faketablecell">{{ass.serialNumber}}</div>
      <div class="col-2 d-none d-lg-block faketablecell">{{ass.friendlyName ? ass.friendlyName : '--'}}</div>
      <div class="col-1 d-none d-lg-block faketablecell">{{ass.hostName ? ass.hostName : '--'}}</div>
      <div class="col-1 d-none d-md-block faketablecell">{{ass.assetVendor ? ass.assetVendor : '--'}}
      </div>
      <div class="col-1 d-none d-md-block faketablecell">{{ass.osVersion ? ass.osVersion : '--'}}
      </div>
      <div class="col-1 d-none d-md-block faketablecell" *ngIf="statusExists"></div>
      <div class="col-1 d-none d-md-block faketablecell">
        <fa-icon [icon]="['fas', 'check']" class="p-1" *ngIf="ass.active==='true'"></fa-icon>
        <fa-icon [icon]="['fas', 'window-close']" class="p-1" *ngIf="ass.active!=='true'"></fa-icon>
      </div>
      <div class="col-1 d-none d-md-block faketablecell">
        <fa-icon [icon]="['fas', 'check']" class="p-1" *ngIf="ass.entitlements.length > 0"></fa-icon>
        <fa-icon [icon]="['fas', 'window-close']" class="p-1" *ngIf="ass.entitlements.length === 0"></fa-icon>
      </div>
      <div class="col-1 d-none d-lg-block faketablecell">
        <div class="spinner-grow spinner-grow-sm text-primary" *ngIf="flaggingRef && flaggingRef === ass.id">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
        <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagAsset($event, ass.id)"
          *ngIf="(!flagRefs.includes(ass.id)) && (!flaggingRef || (flaggingRef && flaggingRef !== ass.id))"></fa-icon>
        <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagAsset($event, ass.id)"
          *ngIf="(flagRefs.includes(ass.id)) && (!flaggingRef || (flaggingRef && flaggingRef !== ass.id))"></fa-icon>
      </div>
    </div>
  }

  @if(loading) {
    <div class="d-flex faketablerow justify-content-center">
      <div class="row justify-content-center p-5" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
      </div>
    </div>
  }

  @if(!loading && assets && assets.length === 0) {
    <div class="row p-3 justify-content-center redborder-sides" *ngIf="assets && assets.length === 0">
      <div class="col-md-12 table-border justify-content-center">
        {{ 'pages.assets.dashboard.noResults' | translate }}
      </div>
    </div>
  }
