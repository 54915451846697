import { Component } from '@angular/core';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { ContextService } from 'src/app/shared/services/context-service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [
    './layout.component.scss',
    './../default-layout/default-layout.component.scss'
  ]
})
export class LayoutComponent extends DefaultLayoutComponent {


  changeTenants = false;

  //Modal
  closeResult: string;

  //Label
  public logoutLabel: string;

  constructor(
    public contextService: ContextService,
    public userPreferences: UserPreferenceService
  ) {
    super();
  }

  public onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
