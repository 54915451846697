
<div class="modal-header">
  <h5 class="modal-title" style="color: #ff7900" id="exampleModalLabel">{{params?.title}}</h5>
</div>
<div class="modal-body">
  <div class="form-group col-12 pt-2">
    <p>
      {{params?.bodyMessage}}
    </p>
    <a *ngIf="params?.link" href="{{params.link}}" rel="noopener" target="_blank">{{params.link}}</a>
  </div>

</div>
<div class="modal-footer">
  <p class="text-start font-weight-light">{{params?.datetime}}</p>
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ 'pages.generic.close' | translate }}
  </button>
</div>
