<div class="section" *ngIf="loadedCaseStats && loadedOpenCases">
  <div class="activities">
    <h3 class="title">{{ 'pages.dashboard.supportActivity' | translate }}
      <app-help message="{{ 'pages.dashboard.recentActivityDesc' | translate }}"></app-help>
    </h3>

    <!-- open cases -->
    <div routerLink="/support/dashboard/opencases">
      <fa-icon [icon]="['fas', 'arrow-right']" size="lg"></fa-icon>
      <span [ngClass]="{'count': true, 'mx-2': true, 'light-theme': theme === 'light'}">{{openCases}}</span>
      <span>{{ 'pages.dashboard.openCases' | translate }}</span>
    </div>

    <!-- pending cases -->
    <div routerLink="/support/dashboard/customerpending">
      <fa-icon [icon]="['fas', 'arrow-right']" size="lg"></fa-icon>
      <span [ngClass]="{'count': true, 'mx-2': true, 'light-theme': theme === 'light'}">{{customerPending}}</span>
      <span>{{ 'pages.dashboard.customerPending' | translate }}</span>
    </div>

  </div>
  <div class="chart">
    <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions"
      style="max-width: 45rem; height: 100%; display: block;"></highcharts-chart>
  </div>

</div>

<div class="row justify-content-center p-5" *ngIf="!loadedCaseStats || !loadedOpenCases">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
