import { Component, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from 'src/app/shared/utilities';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { FileExtension, Pages } from 'src/app/shared/model/shared-items';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { ReportPdfAccessLevel } from 'src/app/shared/model/files';
import { FalsePositiveCategory, ShowDataParameters } from './report-live-charts/base-chart/report-base-chart.component';
import { ChartsDataDTO, changeChartsMonth, initChartsData, securityIncidentCallback } from './chartsdata-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-live-securitycase',
  templateUrl: './report-live-securitycase.component.html',
  styleUrls: ['./report-live-securitycase.component.scss']
})
export class ReportLiveSecurityCaseComponent implements OnInit {

  public chartsData: ChartsDataDTO;
  public page: string;
  public subpage: string;
  public loaded: boolean;
  public errorMessage: string;
  public falsePositiveCategories: string[] = Object.keys(FalsePositiveCategory);
  public chartPositions: string[] = ['rankOneLeft', 'rankOneRight', 'rankTwoLeft', 'rankOneRight', 'rankThreeLeft'];

  //Modal
  public pdfName = '';
  public reportOption = FileExtension.csv;
  public pdfAccessLevel = '0';
  public generateReportLoading = false;
  public closeResult: string;

  private searchCriteria: SupportCaseSearchCriteria;
  private sortedBy: string;
  private sortedOrder: number;

  constructor(
    private itsmService: ItsmService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private userActivity: UserActivityService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loaded = false;
    this.chartsData = initChartsData();
    this.itsmService.securityIncidentReport(Utilities.get6MonthsAgo())
      .then(res => {
        this.chartsData = securityIncidentCallback(res, this.chartsData);
        this.page = Pages.tp;
        this.subpage = Pages.analytics;
      })
      .catch( err => this.alertService.handlerError(err) )
      .finally( () => {
        this.loaded = true;
      if (!this.chartsData || this.chartsData.data.length === 0) {
        this.errorMessage = this.translateService.instant('pages.securitycase.noDataAvailable');
      }
        this.loaded = true;
      });

      this.userActivity.logActivity(TYPES.reports, PAGES.securityIncidentsReport, ACTIONS.visit);
  }

  prepareCurrentMonthData(series, seriesName, posi) {
    const dynamicData = [];
    this.chartsData.data.forEach(c => {
      if (posi && !Utilities.checkTruePositive(c.caseNumber, c.status, c.closureCode)) {
        return;
      }
      if (!posi && !Utilities.checkFalsePositive(c.status, c.closureCode)) {
        return;
      }
      const cd = new Date(c.createdDate);
      const actualMonth = cd.getMonth() + 1;
      const dateStr = cd.getFullYear() + '-' + actualMonth;
      const cmdate = Array.from(this.chartsData.caseMap)[this.chartsData.monthIndex][0];

      if (cmdate === dateStr && c[series]) {
        const seriesList = c[series].split(',');

        if (seriesList.length > 1) {
          seriesList.forEach(element => {
            if (element.toLowerCase() === seriesName.toLowerCase()) {
              dynamicData.push(c);
            }
          });
        }
        else {
          if (c[series] === seriesName) {
            dynamicData.push(c);
          }
        }
      }
    });
    if (posi) {
      this.chartsData.currentMonthDataTP = dynamicData;
    }
    else {
      this.chartsData.currentMonthDataFP = dynamicData;
    }
    this.subpage = 'data';
  }

  canGetNextMonth() {
    return Array.from(this.chartsData.caseMap).length > this.chartsData.monthIndex + 1;
  }

  canGetPreviousMonth() {
    return this.chartsData.monthIndex > 0;
  }

  changeMonth(direction: 'fwd' | 'back') {
    this.chartsData = changeChartsMonth(direction, this.chartsData);
  }

  dataTabClicked() {
    this.chartsData.currentMonthDataFP = Array.from(this.chartsData.caseMap)[this.chartsData.monthIndex][1].falsePositives;
    this.chartsData.currentMonthDataTP = Array.from(this.chartsData.caseMap)[this.chartsData.monthIndex][1].truePositives;
  }

  sortAssets(field: string) {
    if (this.sortedBy === field) {
      if (this.sortedOrder === -1) {
        this.sortedOrder = 1;
        this.chartsData.currentMonthDataFP.sort( (a, b) => {
          if (a[field] < b[field]) { return 1; }
          if (a[field] > b[field]) { return -1; }
          return 0;
        });
        this.chartsData.currentMonthDataTP.sort( (a, b) => {
          if (a[field] < b[field]) { return 1; }
          if (a[field] > b[field]) { return -1; }
          return 0;
        });
      } else {
        this.sortedOrder = -1;
        this.chartsData.currentMonthDataFP.sort( (a, b) => {
          if (a[field] < b[field]) { return -1; }
          if (a[field] > b[field]) { return 1; }
          return 0;
        });
        this.chartsData.currentMonthDataTP.sort( (a, b) => {
          if (a[field] < b[field]) { return -1; }
          if (a[field] > b[field]) { return 1; }
          return 0;
        });
      }
    } else {
      this.sortedBy = field;
      this.sortedOrder = 1;
      this.chartsData.currentMonthDataFP.sort( (a, b) => {
        if (a[field] < b[field]) { return -1; }
        if (a[field] > b[field]) { return 1; }
        return 0;
      });
      this.chartsData.currentMonthDataTP.sort( (a, b) => {
        if (a[field] < b[field]) { return -1; }
        if (a[field] > b[field]) { return 1; }
        return 0;
      });
    }
  }

  showData(params: ShowDataParameters) {
    this.prepareCurrentMonthData(params.series, params.seriesName, params.posi);
  }

  exportReport(content) {
    this.openModal(content);
  }

  openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (result === 'save') {
        this.generateCsvReport();
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  generateCsvReport() {
    this.generateReportLoading = true;
    this.searchCriteria = new SupportCaseSearchCriteria();

    this.searchCriteria.count = false;
    this.searchCriteria.limit = undefined;
    this.searchCriteria.securityAnaltyics = true;
    this.searchCriteria.export = true;
    this.searchCriteria.pdfName = this.pdfName + ' - ' + constants.accessLevels[this.pdfAccessLevel]
      + '.' + (this.reportOption === FileExtension.csv ? FileExtension.csv : FileExtension.pdf);
    this.searchCriteria.pdfAccessLevel = this.pdfAccessLevel;
    this.searchCriteria.extended = true;
    this.searchCriteria.status = ['Closed'];
    this.searchCriteria.escalation = 'To Customer,from Customer';

    this.itsmService.caseSearch(this.searchCriteria)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('pages.generic.reportGenerated'));
        this.generateReportLoading = false;
        this.userActivity.logActivity(TYPES.reports, PAGES.securityIncidentsReport, ACTIONS.generateReport);
        const fullName =
          `${Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.searchCriteria.pdfAccessLevel])}/${this.searchCriteria.pdfName}`;
        this.notificationService.sendNotification(
          NotificationCategory.generateReport,
          { title: fullName, content: fullName },
          NotificationAudience.admins
        );
      })
      .catch(err => {
        this.alertService.handlerError(err);
        this.generateReportLoading = false;
      });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
