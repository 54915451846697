<main class="container-fluid m-0 mw-100">
  <div id="xtended-soc" class="row d-flex flex-colum flex-lg-row">
    <!-- SIDE NAVBAR -->
    <div
      class="xtended-soc-sidebar flex-shrink-0 d-flex justify-content-between flex-column p-0 px-0 border-light border-top border-end border-1 col-lg-1"
    >
      <app-sidemenu [menuLinks]="menuLinks"></app-sidemenu>

      <div class="mb-lg-2 d-lg-block d-none">
        <a
          (click)="handleAnonymousToggleChange()"
          class="btn d-inline-flex justify-content-between border-0 collapsed w-100 px-3 py-2"
        >
          <div
            class="d-inline-flex flex-column align-items-center justify-content-center m-auto gap-1"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>
                {{ 'pages.cybersocxdr.layout.anonymous' | translate }}
              </title>
              <g
                id="icons/functional_UI/E-M/ic_Hide"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M19.420992,8.41363199 L18.384912,9.44971199 C19.514376,10.205256 20.477712,11.106624 21.109992,11.993184 C20.395224,12.991584 19.252392,14.01108 17.920008,14.820744 C17.4544482,15.1039078 16.9716156,15.3576442 16.474344,15.580464 C17.6462259,14.10605 18.0244547,12.152359 17.487504,10.34712 L16.266024,11.5686 C16.279704,11.708552 16.286656,11.850328 16.28688,11.993928 C16.2869182,13.1396749 15.8317889,14.238506 15.0216235,15.0486715 C14.211458,15.8588369 13.1126269,16.3139662 11.96688,16.313928 C11.823344,16.313928 11.681568,16.306976 11.541552,16.293072 L9.98824799,17.8464 C10.5492239,17.9413621 11.1171006,17.9897033 11.686056,17.990928 C11.782464,17.990928 11.879016,17.994024 11.976,17.994024 C12.072984,17.994024 12.169536,17.990928 12.265944,17.990928 C16.76796,17.990904 21.32424,14.817744 22.8,11.994 C22.160616,10.770576 20.951736,9.48175199 19.420992,8.41363199 Z M2.89000796,11.994816 C3.60479997,10.99656 4.74767997,9.97679999 6.07999198,9.16723199 C6.51315481,8.90388646 6.96125403,8.66593341 7.42202398,8.45457599 C6.23823668,9.9766528 5.89316533,11.989527 6.50239198,13.818984 L7.69331998,12.628056 C7.49335273,11.2749563 7.9456546,9.90705817 8.91283239,8.93988039 C9.88001017,7.9727026 11.2479083,7.52040073 12.601008,7.72036798 L14.154672,6.16670398 C13.5473423,6.05550109 12.9312963,5.99874775 12.313872,5.99711997 C12.217464,5.99711997 12.120888,5.99399997 12.023928,5.99399997 C11.926968,5.99399997 11.830392,5.99711997 11.733984,5.99711997 C7.23203998,5.99711997 2.67578396,9.17023199 1.19999995,11.994 C1.85315996,13.243752 3.10060796,14.56176 4.67836797,15.643032 L5.71612797,14.605248 C4.54319997,13.836336 3.54098396,12.907728 2.89000796,11.994816 Z M19.757232,3.26399996 L12.106992,10.91424 L9.69403199,13.3272 L3.19799996,19.823256 L4.47074397,21.096 L11.088288,14.478456 L14.45136,11.115384 L21.03,4.53674397 L19.757232,3.26399996 Z"
                  [attr.fill]="anonymousEnabled ? '#FF7900' : '#000000'"
                ></path>
              </g>
            </svg>
            <div class="sidebar-label">
              {{ 'pages.cybersocxdr.layout.anonymous' | translate }}
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- MAIN -->
    <div
      class="xtended-soc-main container p-0 mw-100 col-lg-11 bg-body-tertiary"
    >
      <!-- Notifications Carousel -->
      <app-xtended-notification-carousel
        [notifications]="notifications"
      ></app-xtended-notification-carousel>

      <!-- pages may not be available in the sideline, therefore there is no page info -->
      @if (getCurrentPage()) {
      <div
        [class]="
          (isDarkThemeEnabled ? 'bg-dark ' : 'bg-white ') +
          'row d-flex flex-wrap gap-2 justify-content-between m-0 p-2 border-light border-top border-bottom border-1'
        "
      >
        <div class="w-auto d-flex flex-column gap-1">
          @if(getCurrentPage()?.name){
          <h3 class="m-0 w-auto d-flex align-items-center">
            {{ getCurrentPage()?.name | translate }}
          </h3>
          }

          <div *ngIf="getCurrentPage()?.saveSearchDisplay" class="dropdown">
            <a
              class="bg-transparent dropdown-toggle btn p-0 border-0"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ filterService.currentPreset }}
            </a>
            <ul class="dropdown-menu">
              <li *ngFor="let preset of filterService.filtersPresets">
                <a
                  class="dropdown-item"
                  (click)="filterService.applyPreset(preset)"
                  >{{ preset.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="w-auto d-flex align-items-center gap-2">
          @if(filterService.clientInfo?.contracts?.length > 1){
          <div>
            <select
              class="form-select rounded"
              (change)="onContractChange($event.target.value)"
              [disabled]="!getCurrentPage()?.sourceSelectable"
            >
              <option
                *ngFor="let contract of filterService.clientInfo?.contracts"
                [value]="contract.id"
                [selected]="contract.id === selectedContractId"
              >
                {{ contract.service }}
              </option>
            </select>
          </div>
          }
          <button
            *ngIf="getCurrentPage()?.filterDisplay"
            class="btn btn-secondary rounded"
            (click)="generateReport()"
            [disabled]="isLoadingGeneration"
          >
            <span
              class="spinner-border spinner-border-sm me-2"
              *ngIf="isLoadingGeneration"
              aria-hidden="true"
            ></span>
            Generate Report
          </button>
          <button
            *ngIf="getCurrentPage()?.filterDisplay"
            class="btn btn-primary rounded"
            id="filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#xtendendsoc-filter"
            aria-expanded="false"
            aria-controls="xtendendsoc-filter"
          >
            Show filters
          </button>
        </div>
      </div>

      <!-- SEARCH -->
      <div
        *ngIf="getCurrentPage()?.filterDisplay"
        id="xtendendsoc-filter"
        class="collapse"
      >
        <div
          id="xtendendsoc-search"
          class="pt-0 pb-3 px-3 bg-body-secondary border-bottom border-light border-1"
        >
          <app-filter-form (searchEmitter)="search($event)"></app-filter-form>
        </div>
      </div>
      } @if (getCurrentPage()?.haveTabs) {
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      }@else {
      <div class="container bg-body-tertiary p-3" style="max-width: 1800px">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
      }
    </div>
  </div>

  <div class="position-fixed bottom-0 end-0 mb-4 me-4" style="z-index: 1000">
    <button
      class="btn btn-primary rounded-circle"
      style="width: 60px; height: 60px"
      (click)="toggleHelp()"
      aria-label="Open help"
    >
      <fa-icon
        [icon]="['fas', isHelpOpen ? 'chevron-down' : 'question']"
        size="lg"
      ></fa-icon>
    </button>

    <app-xtended-help [isHelpOpen]="isHelpOpen"></app-xtended-help>
  </div>
</main>
