<div class="row pt-3" *ngIf="showCases.length > 0">
  <div class="col-1"></div>
  <div class="col-10">

      <div class="row">
          <div class="col-12">

              <div class="row faketableheaderrow">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 faketableheader" >
                      {{'pages.securitycase.caseNumber' | translate}} 
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block faketableheader" >
                      {{'pages.securitycase.status' | translate}} 
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8 faketableheader" >
                      {{'pages.securitycase.subject' | translate}} 
                  </div>
                 
              </div>

              <div class="row faketablerow" *ngFor="let case of showCases" [routerLink]="['/support/view/' + case.id]" routerLinkActive="activeLink">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 faketablecell">
                      {{case.caseNumber}}
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block faketablecell">
                      {{case.status}}
                  </div> 
                  <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8 faketablecell">
                      {{case.subject}}
                  </div>
               
              </div>

          </div>
      </div>
  
  </div>
</div>
