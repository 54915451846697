import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cveify'})
export class CvePipe implements PipeTransform {
  transform(link: string): string {
    return this.cveify(link);
  }

  private cveify(plainText): string{
    let replacedText;
    let replacePattern1;

    replacePattern1 = /(CVE-\d*-\d*)/gim;
    if(plainText){
      replacedText = plainText.replace(replacePattern1, '<a href="/updates/vulnerabilities/viewVuln/$1" routerLinkActive="activeLink" target="_ref">$1</a>');
    }

    return replacedText;
   }
}
