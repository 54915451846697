import { Component } from '@angular/core';
import { ChartView, ReportBaseChartComponent } from '../base-chart/report-base-chart.component';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-threat-action-chart',
  templateUrl: '../base-chart/report-base-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class ThreatActionChartComponent extends ReportBaseChartComponent {

  constructor(
    public widgetService: WidgetService
  ) {
    super(widgetService);
    this.chartName = 'threatAction';
    this.attributeName = 'action';
    this.isTruePositive = true;
    this.selectedView = ChartView.ring;
    this.multipleViews = true;
    this.chartsDesc = [
      {type: ChartView.ring, attrName: 'action', series: 'action'},
      {type: ChartView.assetBar, attrName: 'action', series: 'action'}
    ];
  }

}
