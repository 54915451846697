import { Component } from '@angular/core';
import { SecurityCaseActivity } from '../../chartModels';
import { Utilities } from 'src/app/shared/utilities';

import { ChartView, ReportBaseChartComponent } from '../base-chart/report-base-chart.component';
import { Case } from 'src/app/shared/model/itsm';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-threat-killchain-chart',
  templateUrl: '../base-chart/report-base-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class ThreatKillchainChartComponent extends ReportBaseChartComponent {

  private initialCategoryList = [
    'reconnaissance',
    'policy_breach',
    'initial_compromise',
    'persistence',
    'command_and_control',
    'privilege_escalation',
    'lateral_movement',
    'manipulation_and_evasion',
    'data_exfiltration',
    'n_a'
  ];

  constructor(
    public widgetService: WidgetService
  ) {
    super(widgetService);
    this.chartName = 'threatKillchain';
    this.attributeName = 'killChain';
    this.isTruePositive = true;
    this.selectedView = ChartView.bar;
    this.multipleViews = true;
    this.chartsDesc = [
      {type: ChartView.ring, attrName: 'killChain', series: 'killchain'},
      {type: ChartView.assetBar, attrName: 'killChain', series: 'killchain', initialCategoryList: this.initialCategoryList}
    ];
    this.parseCaseContentOverload = this.parseCaseContent;
  }

  public parseCaseContent = (caseContent: Case) => {
    const cd = new Date(caseContent.createdDate);
    const actualMonth = cd.getMonth() + 1;
    const dateStr = cd.getFullYear() + '-' + actualMonth;

    if (!this.caseMap.get(dateStr)) {
      this.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
    }

    if (Utilities.checkTruePositive(caseContent.caseNumber, caseContent.status, caseContent.closureCode)) {
      const caseM = this.caseMap.get(dateStr);
      caseM.truePositives.push(caseContent);
      if (caseContent.killchain) {
        const killchainM = this.caseMap.get(dateStr);
        let killchains = [];
        if (caseContent.killchain.includes(';')) {
          killchains = caseContent.killchain.split(';');
        } else {
          killchains.push(caseContent.killchain);
        }
        killchains.forEach(kc => {
          if (killchainM.killChain.get(kc)) {
            killchainM.killChain.set(kc, killchainM.killChain.get(kc) + 1);
          } else {
            killchainM.killChain.set(kc, 1);
          }
          this.caseMap.set(dateStr, killchainM);
        });
      }
    }
  };

}
