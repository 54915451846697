import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constants } from 'src/app/shared/constants';
import { Activity } from 'src/app/shared/model/activity';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-latest-activity',
  templateUrl: './latest-activity.component.html',
  styleUrls: ['./latest-activity.component.scss']
})
export class LatestActivityComponent extends WidgetBaseComponent implements OnInit {

  perPage = constants.recordsPerPage.activity;
  currentPage: number;

  activities: Activity[];
  subsetActivities: Activity[];
  loadedActivities: boolean;
  public ocdLabel: string;

  constructor(
    private activitySearch: ActivityService,
    private alertService: AlertService,
    private translateService: TranslateService
    ) { 
      super();
    }

  ngOnInit(): void {
    this.currentPage = 1;
    this.loadedActivities = false;
    this.ocdLabel = this.translateService.instant('pages.dashboard.latestActivity.OCD');
    this.activitySearch.loadActivityDays(7, true)
      .then(res => {
        this.activities = res.slice(0, 30);
        this.subsetActivities = this.activities.slice(this.perPage*(this.currentPage-1), this.perPage);
        this.loadedActivities = true;
      })
      .catch(err => {
        delete this.activities;
        delete this.subsetActivities;
        this.loadedActivities = true;
        this.alertService.handlerError(err);
      });
  }

  /** make update in the pagination */
  pageActivity(event: any): void {
    this.subsetActivities = this.activities.slice(this.perPage*(this.currentPage-1), (this.perPage*(this.currentPage-1)) + this.perPage);
  }

}
