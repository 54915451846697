import { Component } from '@angular/core';
import { SecurityCaseActivity } from '../../chartModels';
import { Utilities } from 'src/app/shared/utilities';
import { Case } from 'src/app/shared/model/itsm';
import { ChartView, ReportBaseChartComponent } from '../base-chart/report-base-chart.component';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-threat-assets-chart',
  templateUrl: '../base-chart/report-base-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class ThreatAssetsChartComponent extends ReportBaseChartComponent {

  constructor(
    public widgetService: WidgetService
  ) {
    super(widgetService);
    this.chartName = 'threatAssets';
    this.attributeName = 'asset';
    this.isTruePositive = true;
    this.selectedView = ChartView.bar;
    this.multipleViews = true;
    this.secondaryAttribute = 'u_asset';
    this.chartsDesc = [
      {type: ChartView.ring, attrName: 'u_asset', series: 'u_asset'},
      {type: ChartView.assetBar, attrName: 'u_asset', series: 'u_asset'}
    ];
    this.parseCaseContentOverload = this.parseCaseContent;
   }

   /**
    * Feed caseMap attribute with parsed data from cases attribute
    * Used as an overload of ReportBaseChartComponent.parseCaseContent()
    */
   public parseCaseContent = (caseContent: Case) => {
    const cd = new Date(caseContent.createdDate);
    const actualMonth = cd.getMonth() + 1;
    const dateStr = cd.getFullYear() + '-' + actualMonth;

    if (!this.caseMap.get(dateStr)) {
      this.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
    }

    if (Utilities.checkTruePositive(caseContent.caseNumber, caseContent.status, caseContent.closureCode)) {
      const caseM = this.caseMap.get(dateStr);
      caseM.truePositives.push(caseContent);
      if (caseContent.u_asset) {
        const assetM = this.caseMap.get(dateStr);
        let u_asset = [];
        if (caseContent.u_asset.includes(';')) {
          u_asset = caseContent.u_asset.split(';');
        } else {
          u_asset.push(caseContent.u_asset);
        }

        u_asset.forEach(kc => {
          if (assetM.u_asset.get(kc)) {
            assetM.u_asset.set(kc, assetM.u_asset.get(kc) + 1);
          } else {
            assetM.u_asset.set(kc, 1);
          }
          this.caseMap.set(dateStr, assetM);
        });
      }
      if (caseContent.asset && caseContent.asset.name) {
        const assNameM = this.caseMap.get(dateStr);
        if (assNameM.assetName.get(caseContent.asset.name)) {
          assNameM.assetName.set(caseContent.asset.name, assNameM.attributes.get(caseContent.asset.name) + 1);
        } else {
          assNameM.assetName.set(caseContent.asset.name, 1);
        }
        this.caseMap.set(dateStr, assNameM);
      }

      if (caseContent.asset && caseContent.asset.vendor) {
        const assVenM = this.caseMap.get(dateStr);
        if (assVenM.assetVendor.get(caseContent.asset.vendor)) {
          assVenM.assetVendor.set(caseContent.asset.vendor, assVenM.attributes.get(caseContent.asset.vendor) + 1);
        } else {
          assVenM.assetVendor.set(caseContent.asset.vendor, 1);
        }
        this.caseMap.set(dateStr, assVenM);
      }
    }
  };

}
