<div class="question">
    <div class="list-order" cdkDropList (cdkDropListDropped)="drop($event)">
        <ol class="list-group list-group-numbered">
            @for (answer of question?.answers; track answer) {
            <li class="list-group-item" cdkDrag>{{answer}}</li>
            }
        </ol>
    </div>
    <div class="help">
        <app-help message="{{ 'modals.feedbackMenu.survey.dragAndDrop' | translate }}"></app-help>
    </div>
</div>

