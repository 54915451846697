<div class="row">
  <div class="col-12 pt-2">
    <h3>{{ 'pages.securitycase.benchmarking.incidentByActions' | translate }}</h3>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="row p-0">
      <div class="col-12 text-center p-0">
        <highcharts-chart [Highcharts]="Highcharts" [options]="columnComparisonChartOptions"
          [(update)]="updateDonutFlag" [oneToOne]=true
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
    </div>

  </div>
  <div class="col-12 text-center">
    <app-incident-panel [showCases]="showIncidentByActionCases"></app-incident-panel>
  </div>
</div>
