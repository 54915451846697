<div class="row pt-2" *ngIf="loaded">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pb-2" *ngFor="let cve of cves">
    <div class="card highlight h-100 boxshadow" [routerLink]="['/updates/vulnerabilities/viewVuln/' + cve._id]" routerLinkActive="activeLink">
      <div class="card-header" *ngIf="cve.impact && cve.impact.baseMetricV3 && cve.impact.baseMetricV3.cvssV3 && cve.impact.baseMetricV3.cvssV3.baseSeverity" [ngClass]="cve.impact.baseMetricV3.cvssV3.baseSeverity | lowercase">{{cve._id}}</div>
      <div class="card-header" *ngIf="cve.impact && !cve.impact.baseMetricV3 && cve.impact.baseMetricV2 && cve.impact.baseMetricV2.severity" [ngClass]="cve.impact.baseMetricV2.severity | lowercase">{{cve._id}}</div>
      <div class="vulnnone card-header" *ngIf="!cve.impact || ((!cve.impact.baseMetricV3 || !cve.impact.baseMetricV3.cvssV3 || !cve.impact.baseMetricV3.cvssV3.baseSeverity) && (!cve.impact.baseMetricV2 || !cve.impact.baseMetricV2.severity))">{{cve._id}}</div>
      <div class="card-body m-2">
        <p class="card-text preserve-whitespace text-truncate" style="height: 120px;">
          <span class="preserve-whitespace" style="height: 120px;" *ngIf="cve.cve && cve.cve.description && cve.cve.description.description_data">{{cve.cve.description.description_data[0].value}}</span>
        </p>
        <div class="row pt-2 me-0 ms-0" style="font-size: 0.9em;">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
              <div class="row">
                <div class="col-6 p-1 text-end">
                  {{ 'pages.shared.severity' | translate }}
                </div>
                <div class="col-6 p-1 text-start" *ngIf="cve.impact.baseMetricV3">
                  <span class="tag no-border tag-sm" [ngClass]="cve.impact.baseMetricV3.cvssV3.baseSeverity | lowercase">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
                </div>
                <div class="col-6 p-1 text-start" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">
                  <span class="tag no-border tag-sm" [ngClass]="cve.impact.baseMetricV2.severity | lowercase">{{cve.impact.baseMetricV2.severity}}</span>
                </div>
                <div class="col-6 p-1 text-start" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">
                  <span class="tag no-border tag-sm text-bg-light">N/A</span>
                </div>
              </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
              <div class="row">
                <div class="col-6 p-1 text-end">
                    {{ 'pages.shared.score' | translate }}
                </div>
                  <div class="col-6 p-1 text-start" *ngIf="cve.impact.baseMetricV3">
                    <span class="tag no-border tag-sm" [ngClass]="cve.impact.baseMetricV3.cvssV3.baseSeverity | lowercase">{{cve.impact.baseMetricV3.cvssV3.baseScore}}</span>
                  </div>
                  <div class="col-6 p-1 text-start" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">
                    <span class="tag no-border tag-sm" [ngClass]="cve.impact.baseMetricV2.severity | lowercase">{{cve.impact.baseMetricV2.cvssV2.baseScore}}</span>
                  </div>
                  <div class="col-6 p-1 text-start" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">
                      <span class="tag no-border tag-sm text-bg-light">N/A</span>
                  </div>
              </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
            <div class="row">
              <div class="col-6 p-1 text-end">
                {{ 'pages.shared.impact' | translate }}
              </div>
              <div class="col-6 p-1 text-startt">
                  <span class="tag no-border tag-sm tag-info" *ngIf="cve.impact.baseMetricV3">{{cve.impact.baseMetricV3.impactScore}}</span>
                  <span class="tag no-border tag-sm tag-info" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">{{cve.impact.baseMetricV2.impactScore}}</span>
                  <span class="tag no-border tag-sm text-bg-light" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">N/A</span>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
            <div class="row">
              <div class="col-6 p-1 text-end">
                {{ 'pages.shared.exploitable' | translate }}
              </div>
              <div class="col-6 p-1 text-start">
                  <span class="tag no-border tag-sm tag-info" *ngIf="cve.impact.baseMetricV3">{{cve.impact.baseMetricV3.exploitabilityScore}}</span>
                  <span class="tag no-border tag-sm tag-info" *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">{{cve.impact.baseMetricV2.exploitabilityScore}}</span>
                  <span class="tag no-border tag-sm text-bg-light" *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">N/A</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div style="float:left; width:100%" class="text-black"><small>{{ 'pages.shared.published' | translate }} {{cve.publishedDate | date: 'dd/MM/yyyy HH:mm'}} <br/> {{ 'pages.shared.lastModified' | translate }} {{cve.lastModifiedDate | date: 'dd/MM/yyyy HH:mm'}} </small></div>
        <span class="justify-content-end" style="display:flex;">
          <div class="spinner-grow spinner-grow-sm text-primary" style="margin-left: -100px" *ngIf="flaggingRef && flaggingRef === cve._id">>
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
          </div>
          <fa-icon [icon]="['far', 'flag']" (click)="flagSignal($event, cve._id)" *ngIf="(!flagRefs.includes(cve._id)) && (!flaggingRef || (flaggingRef && flaggingRef !== cve._id))"></fa-icon>
          <fa-icon [icon]="['fas', 'flag']" (click)="flagSignal($event, cve._id)" *ngIf="(flagRefs.includes(cve._id)) && (!flaggingRef || (flaggingRef && flaggingRef !== cve._id))"></fa-icon>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="!loaded">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="loaded && cves && cves.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.updates.vulnerabilities.noResults' | translate }}
  </div>
</div>
