import { Component, OnInit } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { Widget } from 'src/app/shared/model/widget';
import { ContextService } from 'src/app/shared/services/context-service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';


@Component({
  selector: 'app-customize-dashboard',
  templateUrl: './customize-dashboard.component.html',
  styleUrls: ['./customize-dashboard.component.scss']
})
export class CustomizeDashboardComponent implements OnInit {
  widgetNameToSelect: string = null;
  // list of all selectable widgets
  availableWidgets: Widget[] = [];
  // ordered list of widgets user has selected
  selectedWidgets: Widget[] = null;
  // navigation
  page: 'customize' | 'list' | 'test' = 'customize';

  constructor(
    private userPref: UserPreferenceService,
    private userActivity: UserActivityService,
    public contextService: ContextService
    ) { }

  ngOnInit(): void {
    this.userPref.selectedWidgets
      .subscribe(widgets => {

        this.selectedWidgets = widgets.sort((a,b) =>  a.rank - b.rank);
        this.reOrderRank();

        this.userPref.getRegisteredWidgets()
          .then(res => {
            this.availableWidgets = res;

            this.availableWidgets.forEach((widget) => {
              if(this.selectedWidgets.find(w => w.name === widget.name)) {
                widget.selected = true;
              }
            });
          });
      });

    this.userActivity.logActivity(TYPES.user, PAGES.customizeDashboard, ACTIONS.visit);
  }

  updateWidget(widget: Widget) {
    widget.selected = !widget.selected;
    if(widget.selected) {
      this.addWidget(widget);
      this.userActivity.logActivity(TYPES.dashboard, PAGES.customizeDashboard, ACTIONS.WidgetAdd, widget.name);
    } else {
      this.deleteWidget(widget.name);
      this.userActivity.logActivity(TYPES.dashboard, PAGES.customizeDashboard, ACTIONS.WidgetRemoval, widget.name);
    }
  }

  addWidget(widget) {

    const widgetRank = this.getWidgetRank(widget);

    const w = {
      name: widget.name,
      displayName: widget.displayName,
      width : widget.width,
      rank : widgetRank,
      class : '',
      style : ''
    };

    this.userPref.addUserSelectedWidgets(w);
    this.widgetNameToSelect = null;
  }

  private getWidgetRank(widget) {
    if (this.selectedWidgets.length === 0) {
      return 1;
    } else {
      const lastRank = Math.max(...this.selectedWidgets.map(wid => wid.rank));

      // check widget on the last rank, see if we can add it on the row
      let sum = 0;
      this.selectedWidgets
        .filter(w => w.rank === lastRank)
        .forEach(w => sum += w.width);
      return sum + widget.width <= 12 ? lastRank : lastRank + 1;
    }
  }

  isWidgetSelected(widgetName: string) {
    return this.selectedWidgets?.map(w => w.name).includes(widgetName);
  }

  deleteWidget(widgetName: string) {
    this.selectedWidgets = this.selectedWidgets.filter(w => w.name !== widgetName);
    // reorder all rank for all selected widgets
    this.reOrderRank();
    this.userPref.removeUserSelectedWidgets(widgetName);
  }

  onDrop(event: DndDropEvent) {
    const index = event.index;

    // get the widget from widget array
    const droppingWidget = this.selectedWidgets.find(w => w.name === event.data.name);

    // update selected widget rank
    droppingWidget.rank = index;

    // get all widgets after this one then update their rank
    this.selectedWidgets
    .filter(w => w.rank >= index && w.name !== event.data.name)
    .forEach(w => w.rank ++);

    this.reOrderRank();

    this.save();
  }

  save() {
    this.userPref.saveWidgetSelection(this.selectedWidgets);
  }

  reOrderRank() {

    this.selectedWidgets.sort((a,b) => a.rank - b.rank);
    let rank = 1;
    let rankWith = 0;
    this.selectedWidgets.forEach(widget => {
        // check if we can add widget to the current line
        if(rankWith + widget.width <= 12 ) {
          widget.rank = rank;
          rankWith += widget.width;
        } else {
          rank ++;
          widget.rank = rank;
          //add new row, reset total width
          rankWith = widget.width;
        }
    });
  }
}
