import { Injectable } from '@angular/core';
import { AxisColors, ChartColors, ChartType, ChartZoom } from '../model/activity';
import { PointOptionsObject, ResponsiveOptions, SeriesOptionsType } from 'highcharts';
import { constants } from '../constants';

export interface HighchartsOptions extends Highcharts.Options {
  xAxis: Highcharts.XAxisOptions;
}

export type PointObj = number | [string | number, number] | PointOptionsObject;

@Injectable({
  providedIn: 'root',
})
export class ChartService {

  public initColumnOptions(
    chartTitle: string,
    yAxisTitle: string,
    serieName: string,
    seriesData: PointObj[],
    drilldownSeries: SeriesOptionsType[],
    tooltipText?: string,
    labelRotation?: boolean
  ): Highcharts.Options {
    
    return {
      chart: {
        type: ChartType.column,
        plotBorderWidth: 1,
        // @ts-ignore Not available in v11 ?
        zoomType: ChartZoom.xy
      },
      title: {
        text: chartTitle,
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          rotation: labelRotation ? -45 : 0
        },
        gridLineColor: AxisColors.transparent,
        gridLineWidth: 1,
        type: 'category'
      },
      yAxis: {
        gridLineColor: AxisColors.transparent,
        title: {
          text: yAxisTitle
        },
        labels: {
          enabled: false
        },
        tickAmount: 10,
        min: 0,
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: true
            },
            inactive: {
              opacity: 1
            },
            select: {
              enabled: true
            }
          },
          allowPointSelect: true,
          point: {
            events: {}
          },
        },
        bubble: {}
      },
      tooltip: {
          pointFormat: `${tooltipText || 'Number of visits'}: <b>{point.y}</b>`
      },
      legend: {
        enabled: false
      },
      series: [{
        name: serieName,
        type: 'column',
        colorByPoint: true,
        groupPadding: 0,
        data: seriesData,
        dataLabels: {
            enabled: true,
            rotation: 0,
            color: '#FFFFFF',
            align: 'center',
            format: '{point.y}',
            y: 10,
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      }],
      drilldown: {
        series: drilldownSeries
      },
      colors: constants.graphColors,
      exporting: {enabled: false}
    };
  }

  public initColumnOptionsWithSeriesOptionsType(
    chartTitle: string,
    xAxisCategories: string[],
    yAxisTitle: string,
    series: Highcharts.SeriesOptionsType[],
    pointClick?: Highcharts.PointClickCallbackFunction,
  ): Highcharts.Options {
    return {
      chart: {
        type: ChartType.column,
      },
      title: {
        useHTML: true,
        text: `<div style="margin: 0.5rem">${chartTitle}</div>`
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      xAxis: {
        categories: xAxisCategories,
        crosshair: true,
        labels: {
          format: '{value}'
        }
      },
      yAxis: {
        title: {
          useHTML: true,
          text: yAxisTitle
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:5"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          point : {
            events: {
              click : pointClick,
              select : () => null,
              unselect : (_) => null
            }
          }
        }
      },
      series,
      // responsive rules
      responsive: {
        rules: [{
          condition: {
            maxWidth: 800
          },
          chartOptions: {
            title: {
              useHTML: true,
              text: `<div style="margin: 0.5rem">${yAxisTitle}</div>`,
              style: {
                fontSize: '10px'
              }
            },
            legend: {
              layout: 'vertical',
              itemMarginTop: 2,
              itemMarginBottom: 2,
              itemStyle: {
                fontSize: '10px'
              }
            },
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                style: {
                  fontSize: '10px'
                }
              }
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: '10px'
                }
              }
            },
            plotOptions: {
              variablepie: {
                dataLabels: {
                  enabled: false
                },  
              }
            }
          },
        }]
      }

    };
  }

  public initBarChartOptions(
    chartTitle: string,
    xAxisTitle: string,
    xAxisCategories: string[],
    tooltipText: string,
    chartSeries: SeriesOptionsType[],
    drilldownSeries?: SeriesOptionsType[],
    legendEnabled?: boolean,
    customColors?: string[]
  ): Highcharts.Options {
    return {
      chart: {
        type: ChartType.bar,
        plotBorderWidth: 1,
        // @ts-ignore Not available in v11 ?
        zoomType: ChartZoom.xy
      },
      title: {
        text: chartTitle,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: xAxisCategories,
        gridLineWidth: 1,
        gridLineColor: AxisColors.transparent,
        type: 'category',
        title: {
          text: xAxisTitle,
        },
        labels: {
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
        }
      },
      yAxis: {
        allowDecimals: false,
        gridLineColor: AxisColors.transparent,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        pointFormat: `${tooltipText || 'Number of visits'}: <b>{point.y}</b>`
      },
      legend: {
        enabled: legendEnabled
      },
      responsive: this.responsivePart(),
      plotOptions: {
        column: {
          colors: [ '#2caffe', '#544fc5', '#00e272', '#fe6a35', '#6b8abc', '#d568fb', '#2ee0ca', '#fa4b42', '#feb56a', '#91e8e12' ]
        }
      },
      series: chartSeries,
      colors: customColors || constants.graphColors,
      exporting: {enabled: false},
      drilldown: {
        series: drilldownSeries ?? []
      },
    };
  }

  public initLineChartOptions(
    chartTitle: string,
    yAxisTitle: string,
    chartSeries: SeriesOptionsType[],
    colors?: string[]
  ): any {
    return {
      title: {
        text: chartTitle,
        align: 'center',
      },
      yAxis: {
        title: {
          text: yAxisTitle,
        },
        allowDecimals: false
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b \'%y',
          year: '%Y'
        },
        title: {
          text: 'Date'
        }
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          marker: {
            enabled: false,
            radius: 2.5
          }
        }
      },
      series: chartSeries,
      responsive: this.responsivePart(),
      colors: colors ?? ['#6CF', '#39F', '#06C', '#036', '#000'],
      credits: {
        enabled: false,
      },
      exporting: {enabled: false},
      chart: {
        zoomType: 'x'
      }
    };
  }

  public responsivePart(): ResponsiveOptions  {
    return {
      rules: [
        {
          condition: {
            maxWidth: 1000,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    };
  }

  public initCatPieChartOptions( title: string, catPieSeries: Highcharts.SeriesOptionsType[], pointClick: Highcharts.SeriesClickCallbackFunction): Highcharts.Options {
    return {
      chart: {
        type: ChartType.pie,
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
       },
       backgroundColor: 'transparent'
      },
      credits: {
        enabled : false
      },
      exporting: {enabled: false},
      legend: {
        enabled : false
      },
      title: {
          text: title,
          useHTML: true
      },
      tooltip : {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      plotOptions : {
        series: {
          cursor: 'pointer',
          events: {
              click: pointClick
          }
      },
        pie: {
          innerSize: 100,
          depth: 45,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.y}'
          }
       }
      },
      series: catPieSeries
    };
  }

  /**
   * init worldwatch category pie chart and worldwatch annual categories pie chart
   * init worldwatch urgencies pie chart and worldwatch annual urgencies pie Chart
   **/
  public initWwPieChart(seriesData: PointObj[], title: string): Highcharts.Options {
    return {
      colors: constants.graphSixColors,
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: ChartColors.white,
      },
      title: {
        text: title,
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          type: ChartType.pie,
          data: seriesData,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  /** init worldwatch word cloud Chart */
  public initWwWordChart(seriesData: PointObj[]): Highcharts.Options {
    return {
      colors: constants.graphColors,
      chart: {
        type: ChartType.wordcloud,
        backgroundColor: ChartColors.white,
      },
      title: {
        text: 'Keywords',
      },
      series: [
        {
          data: seriesData,
          type: undefined,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  /** init worldwatch stacked bar Chart */
  public initStackedBarChart(title: string): HighchartsOptions {
    return {
      chart: {
        type: ChartType.column,
        backgroundColor: ChartColors.white,
      },
      colors: constants.graphSixColors,
      title: {
        align: 'right',
        text: title,
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        floating: true,
        borderWidth: 1,
      },
      xAxis: {
        categories: [],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Signals',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        valueSuffix: ' signals published',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
        series: {
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },
      series: [],
    };
  }
}
