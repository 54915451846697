import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { SessionStorageItem } from 'src/app/shared/model/shared-items';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supportcontactsadd',
  templateUrl: './supportcontactsadd.component.html',
  styleUrls: ['./supportcontactsadd.component.scss']
})
export class SupportcontactsaddComponent implements OnInit {

  public email = '';
  public firstName = '';
  public lastName = '';
  public editPage = false;
  public loader = false;

  private editContact: any = {};
  private contactId = '';

  constructor(
    private userActivity: UserActivityService,
    private itsmService: ItsmService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    if(this.router.getCurrentNavigation().extras.state){
      this.editContact=this.router.getCurrentNavigation().extras.state.userData;
      this.editPage=true;

      this.email = this.editContact.email;
      this.firstName = this.editContact.firstName;
      this.lastName = this.editContact.lastName;
      this.contactId = this.editContact.id;
    }
  }

  ngOnInit(): void {
    this.userActivity.logActivity(TYPES.support, PAGES.addContact, ACTIONS.visit);
  }

  submit(){
    this.loader=true;
    if(this.editPage){
      this.itsmService.editContact({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        sys_id: this.contactId
      })
      .then(() => {
        this.itsmService.contacts = null;
        this.alertService.addSuccess(this.translateService.instant('pages.support.contacts.contactupdated'));
      })
      .catch(() =>{
        this.alertService.addError(this.translateService.instant('pages.support.contacts.contactnotupdate'));
      })
      .finally(() => {
        this.loader = false;
      });
    }
    else{
      this.itsmService.addContact({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email
      })
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('pages.support.contacts.contactadded'));
        this.itsmService.contacts = null;
      })
      .catch(() => {
        this.alertService.addError(this.translateService.instant('pages.support.contacts.contactnotadd'));
      })
      .finally(() => {
        this.loader = false;
      });
    }

  }
}
