import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RssFeedEntry } from '../../../shared/model/rssFeedEntry';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RssFeedViewComponent } from '../rss-feed-view/rss-feed-view.component';
import { ContextService } from 'src/app/shared/services/context-service';
import { constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-rss-feed',
  templateUrl: './rss-feed.component.html',
  styleUrls: ['./rss-feed.component.scss']
})
export class RssFeedComponent implements OnInit, OnChanges {

  @Input()
  entries: RssFeedEntry[];

  @Input()
  type: string;

  @Input()
  loaded: boolean;

  public searchText;
  public filteredEntries = [];
  public sortedTyped = constants.rssFeed.defaultSortType;
  public sortedOrdered = constants.rssFeed.defaultSortOrder;

  constructor(
    private modalService: NgbModal,
    public contextService: ContextService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.entries && this.entries) {
      this.entries = this.entries
      .filter(entry => entry.description !== '')
      // sort posts by date
      .sort((a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime());
      this.filteredEntries = this.entries;
    }
  }

  public openItem(item) {
    const rssVieuwModal = this.modalService.open(RssFeedViewComponent, {backdrop: 'static',size: 'xl', keyboard: false, centered: true});
    rssVieuwModal.componentInstance.selectedRssFeed = item;
    rssVieuwModal.componentInstance.type = this.type;
  }

  /**
   * apply filter with search text
   */
  public search() {
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();
      this.filteredEntries = this.entries.filter(entry => {
        return entry.title.toLocaleLowerCase().match(searchValue)
      });
    }
  }

  /**
   * filter with 'date' or 'title' if needed
   */
  public updateFilter() {

    // default sort by date function
    const sortDateFunction = (a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime();
    // default sort by title function
    const sortTitleFunction = (a, b) => {
      if (a.title > b.title) return 1;
      else if (a.title < b.title) return -1;
      else return 0;
    };

    // sort entries
    this.entries.sort(this.sortedTyped === 'date' ? sortDateFunction : sortTitleFunction);

    // reverse the datas if desc
    if(this.sortedOrdered === 'desc') {
      this.entries.reverse();
    }

    this.filteredEntries = this.entries;

    // apply search text if search text is filled
    this.search();
  }
}
