import * as Highcharts from 'highcharts/highmaps';

// ENUMS

export enum OcdCases {
  new = 'new',
  inProgress = 'work in progress',
  open = 'open',
  onHold = 'on hold',
  pending = 'pending (orange cyberdefense)',
  pendingOcd1 = 'pending ocd',
  pendingOcd2 = 'pending (ocd)',
  pendingVendor1 = 'pending vendor',
  pendingVendor2 = 'pending (vendor)',
  resolved = 'resolved awaiting closure',
  answered = 'answered awaiting closure',
  closed = 'closed',
  investigating = 'investigating'
}

export enum ChartColors {
  red = '#e75b60',
  orange = '#ff7900',
  yellow = '#ffd200',
  blue = '#4bb4e6',
  purple = '#a885d8',
  green = '#50be87',
  white = '#F3F3F3'
}

export enum ChartType {
  bubble = 'bubble',
  pie = 'pie',
  variablePie = 'variablepie',
  column = 'column',
  wordcloud = 'wordcloud',
  bar = 'bar',
  line = 'line',
  spline = 'spline'
}

export enum ChartZoom {
  xy = 'xy'
}

export enum AxisColors {
  transparent = 'transparent'
}

export enum AxisType {
  customer = 'Customer',
  ocd = 'Orange Cyberdefense'
}

export enum PendingCases {
  pending1 = 'pending customer',
  pending2 = 'pending (customer)'
}

export enum PointStages {
  underInvestigation = 'Under Investigation',
  // escalated = 'Escalated',
  pendingCases = 'Pending Customer',
  pendingOCD = 'Pending Orange Cyberdefense',
  closedInLastHours = 'Closed In Last Hours'
}

export enum Priorities {
  critical = 'Business Critical',
  high = 'High',
  medium = 'Medium',
  low = 'Low',
  planning = 'Planning'
}

export enum PrioritiesOrder {
  all = 'All',
  critical = '1 - Business Critical',
  high = '2 - High',
  medium = '3 - Medium',
  low = '4 - Low'
}

// CLASSES

export class Charts {
  highcharts: typeof Highcharts = Highcharts;
  colors: ChartColors[];
  seriesColor0 = 'rgba(255,255,255,0.8)';
}

export class Activity {
  type: string;
  date: string;
  description: string;
  url: string;
  ref: string;
  iconStyle: string;
  iconAlt: string;
  priority: string;
  // below used only for last login list
  count: number;
  updatedBy: string;
}


// INTERFACES

export interface Axis {
  closedInLastHours: number;
  pendingOCD: number;
  escalated: number;
  underInvestigation: number;
  ocdCases: number;
  customerCases: number;
}
