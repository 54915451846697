import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../shared/services/data-share.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-assigned-applications',
  templateUrl: './assigned-applications.component.html',
  styleUrls: ['./assigned-applications.component.scss']
})
export class AssignedApplicationsComponent implements OnInit{

  appGroups: [];
  tdcLoginURL :string = environment.tdc.loginURL;
  
  constructor(private dataService: DataShareService){}

  ngOnInit(){
    this.appGroups = this.dataService.appGroups;
  }
}
