import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivityRoutingModule } from './activity-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ActivityDashboardComponent } from './activitydashboard/activitydashboard.component';
import { SecurityIncidentTimelineComponent } from './security-incident-timeline/security-incident-timeline.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    ActivityDashboardComponent,
    SecurityIncidentTimelineComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ActivityRoutingModule,
    FormsModule,
    AppSharedModule,
    FontAwesomeModule,
    HighchartsChartModule,
  ]
})
export class ActivityModule { }
