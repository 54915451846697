<div *ngIf="cpeRef">

    <div class="row">
        <div class="col-12"><h2>{{cpeRef}}</h2></div>
    </div>

    <div class="row pt-1">
        <div class="col-12 justify-content-end">
            <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1" (click)="changeViewType('list')"></fa-icon>
            <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1" (click)="changeViewType('grid')"></fa-icon>
        </div>
    </div>

    <div class="card-body p-t-1 p-3" *ngIf="userPreferenceService.userPreferences.vulnViewType === 'list'">
        <app-vuln-list [cves]="vulns" [flagRefs]="flagRefs" [flaggingRef]="flagRefs" [loaded]="vulnsLoaded" (flagEmitter)="flag($event);"></app-vuln-list>
    </div>

    <div class="card-body p-t-1 p-3" *ngIf="userPreferenceService.userPreferences.vulnViewType === 'grid'">
        <app-vuln-grid [cves]="vulns" [flagRefs]="flagRefs" [flaggingRef]="flagRefs" [loaded]="vulnsLoaded" (flagEmitter)="flag($event);"></app-vuln-grid>
    </div>

  </div>
