<div class="bg-black">  
  <!-- header -->
  @if(displayHeader) {
    <div class="header">  
      <div class="title">
        <h2>{{contextService.title | translate}}</h2>

        @if(contextService.helpTip) {
          <div class="ms-3">
            <app-help message="{{ contextService.helpTip | translate }}" size="xl" placement="right"></app-help>
          </div>
        }
        
      </div>
      <h3 *ngIf="contextService.subtitle" class="font-weight-normal">{{contextService.subtitle | translate}}</h3>
    </div>
  }
</div>