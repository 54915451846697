<div >
  <form class="question" [formGroup]="surveyForm">
    <div class="select-answer">
        <div class="form-check" *ngFor="let answer of question.answers">
            <input class="form-check-input" [value]="answer" type="radio"  [formControlName]="question.title" [name]="question.title">
            <label class="form-check-label" ngbTooltip={{answer}} openDelay="1500">
              {{answer}}
            </label>
          </div>
    </div>
  </form>
</div>