<div class="xtended-soc-main container p-0 mw-100 col-lg-11 bg-body-tertiary">
  <div class="container bg-body-tertiary p-3" style="max-width: 1800px">
    <app-card class="mw-100">
      <h3 id="entitiesTableDescription">
        {{ 'pages.cybersocxdr.menu.entities' | translate }}
      </h3>
      <div class="table-responsive">
        <table
          class="table table-hover"
          aria-describedby="entitiesTableDescription"
        >
          <thead>
            <tr>
              <th scope="col">
                {{ 'pages.cybersocxdr.entities.fieldTitles.name' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (entity of entities; track entity.id) {
            <tr (click)="onEntityClick(entity.id)" class="cursor-pointer">
              <td>{{ entity.name }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
