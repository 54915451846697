import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FeedbackSummaryComponent } from '../feedbacks/feedback-summary/feedback-summary.component';
import { SurveySummaryComponent } from '../survey/survey-summary/survey-summary.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-global-feedbacks',
  standalone: true,
  imports: [FormsModule, FeedbackSummaryComponent, SurveySummaryComponent, CommonModule],
  templateUrl: './global-feedbacks.component.html',
  styleUrl: './global-feedbacks.component.scss'
})
export class GlobalFeedbacksComponent {

  public selectedCategory = 'feedback';

  public switch(event) {
    this.selectedCategory = event;
  }

}
