import { Component, Input } from '@angular/core';
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';

@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
})
export class InsightComponent {
  @Input()
  insight: InsightData;

  isPositive(): boolean {
    if (this.insight.lowerIsBetter) {
      return this.insight.periodTotal - this.insight.previousPeriodTotal < 0;
    } else {
      return this.insight.periodTotal - this.insight.previousPeriodTotal > 0;
    }
  }

  getVariationPercent(): number {
    if (this.insight.periodTotal === 0) return 0;
    return Math.floor(
      ((this.insight.periodTotal - this.insight.previousPeriodTotal) * 100) / this.insight.previousPeriodTotal
    );
  }
}
