<ng-container *ngIf="loaded">
  <div class="row faketableheaderrow" *ngIf="contacts && loaded">
    <div class="col-xl-3 col-lg-3 d-none d-lg-block faketableheader">{{ 'pages.generic.firstName' | translate }}</div>
    <div class="col-xl-3 col-lg-3 d-none d-lg-block faketableheader">{{ 'pages.generic.lastName' | translate }}</div>
    <div class="col-xl-3 col-lg-3 d-none d-lg-block faketableheader">{{'pages.support.contacts.fieldTitles.email' |
      translate}}</div>
    <div class="col-xl-3 col-lg-3 d-none d-lg-block faketableheader">{{'pages.support.contacts.fieldTitles.actions' |
      translate}}</div>
  </div>

  <div *ngIf="contacts && loaded">
    <div class="row faketablerow" *ngFor="let cont of contacts">
      <div class="col-xl-3 col-lg-3 d-none d-lg-block faketablecell">{{cont.firstName}}</div>
      <div class="col-xl-3 col-lg-3 d-none d-lg-block faketablecell">{{cont.lastName}}</div>
      <div class="col-xl-3 col-lg-3 d-none d-lg-block faketablecell">{{cont.email}}</div>
      <div class="col-3 faketablecell centerme">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-info" id="dropdownMenu1"
            ngbDropdownToggle>{{'pages.support.contacts.fieldTitles.actions' | translate}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownMenu1">
            <button ngbDropdownItem (click)="editUser(cont)">{{'pages.support.contacts.editcontact' | translate
              }}</button>
            <button ngbDropdownItem (click)="deleteContact(cont.id)">{{'pages.support.contacts.deletecontact' |
              translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row justify-content-center p-5" *ngIf="!loaded">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="contacts && contacts.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.support.contacts.nocontacts' | translate }}
  </div>
</div>