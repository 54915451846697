<div class="card" style="max-width: 26rem;" *ngIf="!loading">
  <img class="card-img-top" src="assets/images/maliciousFileDetection.png" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">{{mfdAnalysis.titleKey | translate}}</h5>
    <p class="card-text"[innerHTML]="mfdAnalysis.textKey | translate"></p>
    <div class="row justify-content-between">
      <div class="d-flex justify-content-center">
        <a href={{mfdAnalysis.serviceRoute}} class="btn btn-primary me-1" >{{ 'pages.updates.certTools.scanFile' | translate }}</a>
        <a class="btn btn-primary" *ngIf="mfdAnalysis.displayHistory" href={{mfdAnalysis.historyRoute}}>{{ 'pages.updates.certTools.mfdResults.history' | translate }}</a>
      </div>
      <p *ngIf="mfdQuota !== null" class="mt-2">{{ 'pages.updates.certTools.quota' | translate }} : <b>{{mfdQuota}}</b></p>
    </div>
  </div>
</div>
