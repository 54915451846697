@if (isHelpOpen) {
<div
  [ngClass]="contextService.isDarkTheme() ? 'bg-dark' : 'bg-white'"
  class="xtended-help border border-light rounded shadow-sm position-absolute bottom-100 end-0 mb-2 d-flex flex-column"
>
  <div class="d-flex align-items-center mt-3 position-relative">
    @if (selectedFaqIndex !== null) {
    <button
      class="btn btn-link ms-3 text-decoration-none position-absolute start-0"
      (click)="onBack()"
    >
      <fa-icon [icon]="['fas', 'chevron-left']" class="me-2"></fa-icon>
      {{ 'pages.cybersocxdr.faq.back' | translate }}
    </button>
    }

    <h3
      class="w-100 text-center mb-0"
      [ngClass]="contextService.isDarkTheme() ? 'text-light' : 'text-dark'"
    >
      {{ 'pages.cybersocxdr.faq.title' | translate }}
    </h3>
  </div>
  <!-- Search if in faq list -->
  @if (selectedFaqIndex === null) {
  <div class="px-4 mx-1 my-3">
    <label class="visually-hidden" for="faqSearch">
      {{ 'pages.cybersocxdr.faq.search' | translate }}
    </label>
    <div class="input-group rounded-pill overflow-hidden border border-light">
      <span class="input-group-text border-0 bg-transparent ps-3">
        <fa-icon
          [icon]="['fas', 'search']"
          class="text-muted fs-6"
          aria-hidden="true"
        ></fa-icon>
      </span>
      <input
        id="faqSearch"
        type="search"
        class="form-control border-0 shadow-none bg-transparent py-2 fs-6"
        [placeholder]="'pages.cybersocxdr.faq.search' | translate"
        [(ngModel)]="searchTerm"
        (ngModelChange)="onSearch()"
        autocomplete="off"
      />
    </div>
  </div>
  }
  <div class="flex-grow-1 overflow-auto">
    <div class="pb-4">
      @if (selectedFaqIndex === null) {
      <app-xtended-faq-list
        [faqs]="filteredFaqs"
        (faqSelected)="viewFaq($event)"
      />
      } @else {
      <app-xtended-faq-preview [faq]="faqs[selectedFaqIndex]" />
      }
    </div>
  </div>
</div>
}
