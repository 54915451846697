import { constants } from 'src/app/shared/constants';

export const pieChartOption = {
  chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height:'65%'
  },
  credits : {
    enabled : false
  },
  title: {
    text: '7/10',
    align: 'center',
    verticalAlign: 'middle',
    y: 15,
    style: {
        fontWeight: 'bold',
        fontSize: '1.8rem'
    }
  },
  subtitle : {
    text : 'INFECTED',
    align: 'center',
    verticalAlign: 'middle',
    y: 40,
    style: {
        color: 'grey',
        fontWeight: 'bold',
        fontSize: '1.2rem'
    }
  },
  tooltip: {
      enabled : false
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      pie: {
          allowPointSelect: false,
//          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            overflow: 'allow',
            crop: false,
            style: {
                fontWeight: 'bold'
            }
          },
          center: ['50%', '50%'],
          size: '100%',
          states : {
            hover : {
                enabled : false
            }
          }
      }
  },
  series: [{
      name: 'Brands',
      colorByPoint: true,
      innerSize: '75%',
      data: [{
          name: 'Green',
          y: 7,
          color: 'green'
      },  {
          name: 'Red',
          y: 3,
          color : 'red'
      }]
  }]
};
