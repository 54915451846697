<div class="row mt-auto mb-auto">
  <div class="col-12">
      <div class="row mb-4">
          <p class="col-md-10 col-xs-12 mx-auto">
            {{ 'pages.updates.integrations.intro' | translate }}
          </p>
      </div>
      <div class="row mb-2">
        <h2>{{ 'pages.updates.integrations.dashboardTitle' | translate }}</h2>
      </div>
      <div class="row mb-2">
        <div class="card" style="max-width: 18rem;">
          <img class="card-img-top" src="assets/images/Azure-Active-Directory.jpg" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{ 'pages.updates.integrations.singleSignOn' | translate }}</h5>
            <p class="card-text">{{ 'pages.updates.integrations.enableSingleSignOon' | translate }}</p>
            <a href="/updates/integrations/azureadsso" class="btn btn-primary">{{ 'buttons.action.configure' | translate }}</a>
          </div>
        </div>
      </div>

  </div>
</div>
