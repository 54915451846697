import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { GenericModalComponent } from './templates/generic-modal/generic-modal.component';
import { ExportModalComponent } from './templates/export-modal/export-modal.component';
import { EditColumnsModalComponent } from './templates/edit-columns-modal/edit-columns-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SaveFilterModalComponent } from './templates/save-filter-modal/save-filter-modal.component';
// eslint-disable-next-line max-len
import { DynamicMfdAnalysisReportModalComponent } from 'src/app/updates/cert-tools/mfd-services/dynamic-analysis/dynamic-mfd-analysis-report/dynamic-mfd-analysis-report.component';
import { IconsModule } from '../modules/icons/icons.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NotificationComponent } from './templates/notification/notification.component';
import { AppSharedModule } from 'src/app/app-shared.module';

@NgModule({
  declarations: [
    GenericModalComponent,
    ExportModalComponent,
    EditColumnsModalComponent,
    SaveFilterModalComponent,
    DynamicMfdAnalysisReportModalComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    IconsModule,
    PdfViewerModule,
    AppSharedModule
  ]
})
export class ModalModule { }
