<div class="p-0">
    <!-- HEADER -->
    <div class="row">
        <div class="col-12 text-center container chart-header">
            @if (!widgetFormat) {
            <h3>{{title | translate}}<app-help message="{{helpTip | translate}}"></app-help></h3>
            } @else {
            <h5>{{title | translate}}</h5>
            }
            
            @if (multipleViews && !(widgetFormat && smallScreenMode)) {
            <div class="btn-group right-item graph" role="group" aria-label="Toggle view">
                <ng-template #ringView> {{'pages.securitycase.showRingFormat' | translate}} </ng-template>
                <ng-template #barView> {{'pages.securitycase.showBarFormat' | translate}} </ng-template>
    
                <button class="btn btn-icon" [ngbTooltip]="barView" (click)="selectedView = chartViews.bar"
                    [ngClass]="{'active':[chartViews.bar, chartViews.assetBar].includes(selectedView) }"><fa-icon [icon]="['fas', 'chart-bar']" size="2x"
                        class="text-center" [fixedWidth]="true"></fa-icon></button>
                <button class="btn btn-icon" [ngbTooltip]="ringView" (click)="selectedView = chartViews.ring"
                    [ngClass]="{'active':selectedView === chartViews.ring }"><fa-icon [icon]="['fas', 'chart-pie']"
                        size="2x" class="text-center" [fixedWidth]="true"></fa-icon></button>
            </div>
            }
            
            @if (widgetFormat) {
            <div class="col-2 right-item">
                <a routerLink="/reports/live/securitycase"class="btn btn-sm btn-primary">
                    {{ 'pages.generic.view' | translate }}
                </a>
            </div>
            }
        </div>
    </div>

    <!-- CHARTS -->
     @if ((selectedView === chartViews.ring && donutChartOptions) || (widgetFormat && smallScreenMode)) {
    <div class="row p-0">
        <div class="col-12 text-center p-0" [class.chart-container]="widgetFormat">
            <highcharts-chart class="chart" [Highcharts]="highcharts" [options]="donutChartOptions"
                [(update)]="updateDonutFlag" [oneToOne]=true [style.width]="width" [style.height]="height">
            </highcharts-chart>
        </div>
    </div>
    } @else if ([chartViews.bar, chartViews.assetBar].includes(selectedView) && barChartOptions) {
    <div class="row p-0">
        <div class="col-12 text-center p-0" [class.chart-container]="widgetFormat">
            <highcharts-chart class="chart" [Highcharts]="highcharts" [options]="barChartOptions"
                [(update)]="updateDonutFlag" [oneToOne]=true [style.width]="width" [style.height]="height">
            </highcharts-chart>
        </div>
    </div>
    }

</div>
