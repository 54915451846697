import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-barovertime',
  templateUrl: '../basechart/basechart.component.html',
})
export class BarovertimeComponent
  extends BaseChartComponent<Array<Highcharts.SeriesColumnOptions>>
  implements OnChanges
{
  @Input() createdTo?: string;
  @Input() createdFrom?: string;
  @Input() isStacking = false;
  @Input() dateFormat = '%d %b'; // that's the default highchart value
  @Input() titleY = 'Opened Incident';
  @Input() hovered: string;

  options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: undefined,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: (ctx: Highcharts.AxisLabelsFormatterContextObject): string =>
          Highcharts.dateFormat(this.dateFormat, ctx.value as number),
      },
      maxPadding: 0.05,
      minPadding: 0.05,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      // Put an emphasis on the bar height to help with stacked bar visualisation
      maxPadding: -0.1,
    },
    legend: {
      align: 'center',
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      column: {
        grouping: false,
        borderColor: null,
        stacking: this.isStacking ? 'percent' : 'normal',
        cursor: 'pointer',
        showInLegend: true,
      },
    },
    series: undefined,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if ('hovered' in changes) {
      if (!changes.hovered.firstChange) {
        const serie = this.chart.series.find((s) => s.name === this.hovered);
        if (serie) {
          serie.onMouseOver();
          this.chart.tooltip.refresh(serie.points);
        } else {
          this.chart.series.forEach((s) => s.onMouseOut());
          this.chart.tooltip.hide();
        }
      }
    } else {
      super.ngOnChanges(changes);
    }
  }

  draw(renderingId: string) {
    if (this.data[0].data) {
      if (!Array.isArray(this.options.xAxis)) {
        if (this.createdFrom) {
          this.options.xAxis.min = new Date(this.createdFrom).getTime();
        }
        if (this.createdTo) {
          this.options.xAxis.max = new Date(this.createdTo).getTime();
        }

        // Compute the new tick positions dynamically from the timestamps
        this.options.xAxis.tickPositions = this.data[0].data.map((point) => point[0]);
      }
      this.options.series = this.data;
      this.options.title.text = this.title;
    } else {
      this.options.series = [
        {
          name: 'No data found for this period',
          data: [],
        } as Highcharts.SeriesXrangeOptions,
      ];
    }

    this.options.plotOptions.column.stacking = this.isStacking ? 'percent' : 'normal';
  }
}
