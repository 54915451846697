import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { CVE } from 'src/app/shared/model/cve';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { VulnerabilitiesService } from 'src/app/shared/services/vulnerabilities.service';

@Component({
  selector: 'app-viewvuln',
  templateUrl: './viewvuln.component.html',
  styleUrls: ['./viewvuln.component.scss']
})
export class ViewVulnComponent implements OnInit {

  vulnRef: string;
  cve: CVE;
  loadingVuln: boolean;

  constructor(
    private route: ActivatedRoute,
    private vulnService: VulnerabilitiesService,
    private alertService: AlertService,
    private userActivity: UserActivityService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.vulnRef = params.cveRef);

    this.loadingVuln = true;
    this.vulnService.vulnByCVE(this.vulnRef)
      .then(res => {
        this.cve = res;
        this.loadingVuln = false;
      })
      .catch(err => {
        this.loadingVuln = false;
        this.alertService.handlerError(err);
      });

      this.userActivity.logActivity(TYPES.resources, PAGES.viewVulnerability, ACTIONS.visit);
  }

}
