<div class="modal-header mb-2">
  <h4 class="modal-title" id="modal-basic-title">{{params?.title}}</h4>
  <button type="button" class="btn-close" (click)="close()"><span class="visually-hidden"></span></button>
</div>
<div class="modal-body">
  <p [innerHTML]="params?.bodyMessage | formatHtml"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="success()" *ngIf="params?.successBtnLabel !== undefined">
    {{ params?.successBtnLabel }}
  </button>
  <button type="button" class="btn" (click)="close()" [ngClass]="{'btn-primary': params?.successBtnLabel === undefined, 'btn-secondary': params?.successBtnLabel !== undefined}">
    {{ params?.closeBtnLabel }}
  </button>
</div>
