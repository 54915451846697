import { Component } from '@angular/core';
import { ChartView, ReportBaseChartComponent } from '../base-chart/report-base-chart.component';
import { Utilities } from 'src/app/shared/utilities';
import { SecurityCaseActivity } from '../../chartModels';
import { Case } from 'src/app/shared/model/itsm';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-threat-attributes-chart',
  templateUrl: '../base-chart/report-base-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class ThreatAttributesChartComponent extends ReportBaseChartComponent {

  constructor(
    public widgetService: WidgetService
  ) {
    super(widgetService);
    this.chartName = 'threatAttributes';
    this.attributeName = 'attributes';
    this.isTruePositive = true;
    this.multipleViews = true;
    this.secondaryAttribute = 'attributes';
    this.selectedView = ChartView.assetBar;
    this.chartsDesc = [
      {type: ChartView.ring, attrName: 'attributes', series: 'attributes'},
      {type: ChartView.assetBar, attrName: 'attributes', series: 'attributes'}
    ];
    this.parseCaseContentOverload = this.parseCaseContent;
  }

   /**
    * Feed caseMap attribute with parsed data from cases attribute
    * Used as an overload of ReportBaseChartComponent.parseCaseContent()
    */
   public parseCaseContent = (caseContent: Case) => {
    const cd = new Date(caseContent.createdDate);
    const actualMonth = cd.getMonth() + 1;
    const dateStr = cd.getFullYear() + '-' + actualMonth;

    if (!this.caseMap.get(dateStr)) {
      this.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
    }

    if (Utilities.checkTruePositive(caseContent.caseNumber, caseContent.status, caseContent.closureCode)) {
      const caseM = this.caseMap.get(dateStr);
      caseM.truePositives.push(caseContent);
      if (caseContent.attributes) {
        const attrM = this.caseMap.get(dateStr);
        let attributes = [];
        if (caseContent.attributes.includes(';')) {
          attributes = caseContent.attributes.split(';');
        } else {
          attributes.push(caseContent.attributes);
        }

        attributes.forEach(kc => {
          if (attrM.attributes.get(kc)) {
            attrM.attributes.set(kc, attrM.attributes.get(kc) + 1);
          } else {
            attrM.attributes.set(kc, 1);
          }
          this.caseMap.set(dateStr, attrM);
        });
      }
      if (caseContent.asset && caseContent.asset.name) {
        const assNameM = this.caseMap.get(dateStr);
        if (assNameM.assetName.get(caseContent.asset.name)) {
          assNameM.assetName.set(caseContent.asset.name, assNameM.attributes.get(caseContent.asset.name) + 1);
        } else {
          assNameM.assetName.set(caseContent.asset.name, 1);
        }
        this.caseMap.set(dateStr, assNameM);
      }

      if (caseContent.asset && caseContent.asset.vendor) {
        const assVenM = this.caseMap.get(dateStr);
        if (assVenM.assetVendor.get(caseContent.asset.vendor)) {
          assVenM.assetVendor.set(caseContent.asset.vendor, assVenM.attributes.get(caseContent.asset.vendor) + 1);
        } else {
          assVenM.assetVendor.set(caseContent.asset.vendor, 1);
        }
        this.caseMap.set(dateStr, assVenM);
      }
    }
  };

}
