import { Injectable } from '@angular/core';
import { Alert, GPError } from '../model/alert';
import { Subject } from 'rxjs';

@Injectable()
export class AlertService {

    alerts: Alert[] = [];

    // subject for toast events
    public toastEvent$ = new Subject<Toast>();

    public handlerError(err) {
        const type = 'danger';
        const errorMessage = 'An error has occured: ';
        if (err instanceof GPError) {
            this.alerts.push(new Alert(type, errorMessage + err.message));
        } else if (err instanceof String) {
            this.addError(errorMessage + err);
        } else if (err.error) {
            this.addError(errorMessage + err.error);
        } else if (err.message) {
            this.addError(errorMessage + err.message);
        } else {
            this.addError( errorMessage + err);
        }
    }

    ////////////////////////////////////////////////////// ALERTS ////////////////////////////////////////////////////// 

    public addInfoAlert(info: string, unique = false) {
        this.addAlert('info', info, unique);
    }

    public addSuccessAlert(info: string, unique = false) {
        this.addAlert('success', info, unique);
    }

    public addErrorAlert(error: string, unique = false) {
        this.addAlert('danger', error, unique);
    }

    public close(alert: Alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    private addAlert(type: AlertType, info: string, unique = false) {
        if (unique && this.isAlertUnique(type, info)) {
            return;
        }
        this.alerts.push(new Alert(type, info));
    }

    private isAlertUnique(type: AlertType, info: string): boolean {
        return this.alerts.some((alert) => alert.message === info && alert.type === type);
    }

    ////////////////////////////////////////////////////// TOAST ////////////////////////////////////////////////////// 

    public addInfo(message: string) {
        this.addToast(new Toast(message, 'info'));
    }

    public addSuccess(message: string) {
        this.addToast(new Toast(message, 'success'));
    }

    public addWarning(message: string) {
        this.addToast(new Toast(message, 'warning'));
    }

    public addError(message: string) {
        this.addToast(new Toast(message, 'danger'));
    }

    public addToast(toast: Toast) {
        this.toastEvent$.next(toast);
    }
}

export type AlertType = 'info' | 'danger' | 'success' | 'warning';

export class Toast {
    message: string;
    level: AlertType;

    constructor(message: string, level: AlertType) {
        this.message = message;
        this.level = level;
    }
}
