<div class="row mt-auto mb-auto">
  <div class="col-12">

      <div class="mb-4 d-flex flex-row align-items-center">
        <ng-template #backTo> {{ 'pages.updates.integrations.azure.backToIntegrations' | translate }} </ng-template>
        <a class="btn btn-icon" [ngbTooltip]="backTo" href="/updates/integrations"><fa-icon [icon]="['fas', 'chevron-left']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon></a>
        <h2 class="mb-0">{{ 'pages.updates.integrations.azure.activeDirectorySingleSignOn' | translate }}</h2>
      </div>

      <div class="row mb-4">
        <div class="col-12">

          <p>{{ 'pages.updates.integrations.azure.azureAuthenticate' | translate }}</p>
          <p>{{ 'pages.updates.integrations.azure.registerAzure' | translate }}</p>
          <p>{{ 'pages.updates.integrations.azure.environnementSettings' | translate }}</p>

          <div class="card" *ngIf="view === 'notConfigured'">
            <div class="card-body">
                <div class="card-title">
                    <h4>{{ 'pages.updates.integrations.azure.azureAD' | translate }}</h4>
                </div>
                <p>{{ 'pages.updates.integrations.azure.azureActiveDirectory' | translate }}</p>
                <button class="btn btn-primary" (click)="setupSAML();">{{ 'pages.updates.integrations.setupSaml' | translate }}</button>
            </div>
          </div>

          <div class="card" *ngIf="view === 'editConfiguration'">
              <div class="card-body">
                  <div class="card-title">
                      <h4>{{ 'pages.updates.integrations.azure.azureAD' | translate }}</h4>
                  </div>
                  <div >
                    <h4>{{ 'pages.updates.integrations.azure.SAMLConfiguration' | translate }}</h4>
                    <form>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-9 col-sm-9 col-xs-12 mb-3" >
                                <label for="domain">{{ 'pages.updates.integrations.fieldTitles.domain' | translate }}
                                  <app-help message="{{'pages.updates.integrations.domainDesc' | translate}}"></app-help>
                                </label>
                                <input type="text" class="form-control" name="domain" aria-describedby="domain" placeholder="" [(ngModel)]="domain" placeholder="company.com">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-9 col-sm-9 col-xs-12 mb-3">
                                <label for="singleSignOnURL">{{ 'pages.updates.integrations.fieldTitles.singleSignOnURL' | translate }}
                                  <app-help message="{{'pages.updates.integrations.singleSignOnURLDesc' | translate}}"></app-help>
                                </label>
                                <input type="url" class="form-control" name="singleSignOnURL" aria-describedby="singleSignOnURL" [(ngModel)]="singleSignOnURL" placeholder="{{azureExampleUrl}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-9 col-sm-9 col-xs-12 mb-3">
                                <label for="x509certificate">{{ 'pages.updates.integrations.fieldTitles.x509certificate' | translate }}
                                  <app-help message="{{'pages.updates.integrations.x509certificateDesc' | translate}}"></app-help>
                                </label>
                                <textarea class="form-control" rows="10" name="x509certificate" aria-describedby="x509certificate" [(ngModel)]="x509certificate" placeholder="-----BEGIN CERTIFICATE-----&#10;&#10;&#10;&#10;-----END CERTIFICATE-----"></textarea>
                            </div>
                        </div>

                        <button class="btn btn-primary" (click)="save();">{{ 'pages.generic.save' | translate }}</button>

                    </form>
                  </div>

              </div>
          </div>

          <div class="card" *ngIf="view === 'configured'">
            <div class="card-body">
                <div class="card-title">
                  <h4>{{ 'pages.updates.integrations.azure.azureAD' | translate }}</h4>
                </div>

                <h4>{{ 'pages.updates.integrations.azure.AuthentificationConfiguration' | translate }}</h4>

                <div class="mt-1 mb-1">
                  <h6 class="mb-0">SAML</h6>
                  <p>{{ 'pages.updates.integrations.azure.enabledPortal' | translate }}</p>
                </div>

                <div class="mt-1 mb-1">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.azure.emailDomain' | translate }}</h6>
                  <p>company.com</p>
                </div>

                <div class="mt-1 mb-1">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.fieldTitles.singleSignOnURL' | translate }}</h6>
                  <p>{{azureExampleUrl}}</p>
                </div>

                <div class="mt-1 mb-1">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.azure.certificateFingerprint' | translate }}</h6>
                  <p><code>47:5D:A9:48:E4:BA:44:D9:B5:BC:31:AB:4B:80:06:11:3F:D5:F5:38</code></p>
                </div>

            </div>
          </div>

          <div class="card" *ngIf="view === 'notAvailable'">
            <div class="card-body">
                <div class="card-title">
                  <h4> {{ 'pages.updates.integrations.azure.azureAD' | translate }}</h4>
                </div>

                <h4>{{ 'pages.updates.integrations.azure.AuthentificationConfiguration' | translate }}</h4>

                <div class="mt-1 mb-3">
                  <h6 class="mb-0">SAML</h6>
                  <input type="text" class="fakeinput" placeholder="" disabled>
                </div>

                <div class="mt-1 mb-3">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.azure.emailDomain' | translate }}</h6>
                  <input type="text" class="fakeinput" placeholder="" disabled>
                </div>

                <div class="mt-1 mb-3">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.fieldTitles.singleSignOnURL' | translate }}</h6>
                  <input type="url" class="fakeinput" placeholder="" disabled>
                </div>

                <div class="mt-1 mb-3">
                  <h6 class="mb-0">{{ 'pages.updates.integrations.azure.certificateFingerprint' | translate }}</h6>
                  <input type="text" class="fakeinput large" placeholder="" disabled>
                </div>

                <div *ngIf="alertNotAvailable" class="alert alert-danger" role="alert">
                  <span class="alert-icon"><span class="visually-hidden">Error</span></span>
                  <p>{{ 'pages.updates.integrations.notAvailable' | translate }}</p>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Close"><span class="visually-hidden">Close</span></button>
                </div>

                <button class="btn btn-primary" (click)="return();">
                  {{ 'pages.updates.integrations.returnToInitial' | translate }}
                </button>
            </div>
          </div>

        </div>
    </div>

  </div>
</div>

