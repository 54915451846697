import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrl: './free-text.component.scss'
})
export class FreeTextComponent extends AbstractQuestionComponent {

}

