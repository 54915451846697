<div class="page-description">
  <p>{{ "pages.reports.CSOCReports.threatDetectionAndResponseDesc" | translate }}</p>
</div>

<app-metrics-filter class="m-5"
  [defaultParameters]="filterParameters"
  [customRangeRadioButtons]="rangeRadioButtons"
  [customDateRadioButtons]="dateRadioButtons"
  (filter)="doFilter($event)">
</app-metrics-filter>


<div class="graph-section">
  @for (mttType of mttTypesArr; track $index) {
  <app-mtt-report
    [mttType]="mttType"
    [filterSignal] = "filterSignal"
    class="graph">
  </app-mtt-report>
}
</div>

