<div class="row rowfiller">
    <div class="col-xl-1 hidden-lg"></div>
    <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row pt-1">
                <div class="col-12 contact" >
                    <button class="btn btn-primary" (click)="addContactNav()"> {{ 'pages.support.contacts.addcontact' | translate }}</button>
                </div>
            </div>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{ 'active' : page === 'contacts' }" (click)="page = 'contacts'; loadContacts();">{{ 'pages.support.contacts.contacts' | translate }}</a>
                </li>
            </ul>
            <br/><br/>
            <div class="card-body p-t-1 p-3" *ngIf="viewType === 'list' && (page === 'contacts' || page === 'flagged')">
                <app-contact-list [contacts]="subsetContacts" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="contactsLoaded"
                (reloadContacts)="realoadContacts();" (messageEmitter)="messageEmitter($event);" (sortEmitter)="sortAssets($event);"></app-contact-list>
            </div>
            <div class="card-body justify-content-center p-t-1 p-3" *ngIf="(page === 'contacts' || page === 'flagged') && contactsLoaded">
                <ngb-pagination [collectionSize]="allContacts.length" pageSize="12" page="currentPage"
                 [(page)]="currentPage" aria-label="Pagination" (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious></ng-template>
                    <ng-template ngbPaginationNext></ng-template>
                </ngb-pagination>
            </div>
    </div>
    <div class="col-xl-1 hidden-lg"></div>
</div>
