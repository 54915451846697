<app-card
  class="mw-100"
  cardTitle="pages.cybersocxdr.menu.4A3W"
  actionTitle="pages.cybersocxdr.menu.view4A3W"
  actionRoute="/xtended-soc/4A3W"
>
  <div class="row">
    <app-treemap [data]="actions"></app-treemap>
  </div>
</app-card>
