/* eslint-disable @typescript-eslint/naming-convention */

export class MFDAnalysis {
  titleKey: string;
  textKey: string;
  serviceRoute: string;
  historyRoute: string;
  displayHistory: boolean;

  constructor(titleKey, textKey, serviceRoute, historyRoute, displayHistory) {
    this.titleKey = titleKey;
    this.textKey = textKey;
    this.serviceRoute = serviceRoute;
    this.historyRoute = historyRoute;
    this.displayHistory = displayHistory;
  }
};

export const dynamicsValues = {
  hooks: ['NetworkHooks', 'File hooks', 'Memory hooks', 'Process hooks', 'Registry hooks', 'Exception hooks', 'Hash hooks', 'Mutex hooks',
   'Namedpipe hooks', 'Semaphore hooks', 'Events hooks', 'Locales hooks', 'Outputdebugstring hooks', 'Resources hooks', 'Service hooks',
    'Systeminfo hooks', 'Additional hooks', 'Print functions hooks'],

  specificLaunchEnv: [
    { label : 'AUTO', value : '' },
    { label : 'Batch', value : 'BatchEnvironment' },
    { label : 'Browser', value : 'BrowserEnvironment' },
    { label : 'BrowserFile', value : 'BrowserEnvironmentFile' },
    { label : 'Custom', value : 'CustomEnvironment' },
    { label : 'Dll', value : 'DllEnvironment' },
    { label : 'Eml', value : 'EmlEnvironment' },
    { label : 'Excel', value : 'ExcelEnvironment' },
    { label : 'Exe', value : 'ExeEnvironment' },
    { label : 'Freemode', value : 'FreemodeEnvironment' },
    { label : 'JScript', value : 'JScriptEnvironment' },
    { label : 'Jar', value : 'JarEnvironment' },
    { label : 'Lnk', value : 'LnkEnvironment' },
    { label : 'Msi', value : 'MsiEnvironment' },
    { label : 'Onenote', value : 'OnenoteEnvironment' },
    { label : 'Pdf', value : 'PdfEnvironment' },
    { label : 'PowerPoint', value : 'PowerPointEnvironment' },
    { label : 'PowerShell', value : 'PowerShellEnvironment' },
    { label : 'Rtf', value : 'RtfEnvironment' },
    { label : 'Script', value : 'ScriptEnvironment' },
    { label : 'Vbs', value : 'VbsEnvironment' },
    { label : 'Word', value : 'WordEnvironment' },
    { label : 'Wsf', value : 'WsfEnvironment' },
  ],

   vms : [
     { label : 'Win7 x64', value : 'Windows7SP1x64'  },
     { label : 'Win10 x64', value : 'Windows10x64'  }
   ],

   targettedCountry : [
     { name : 'France', value : 1036  },
     { name : 'Germany', value : 1036  },
     { name : 'England', value : 1036  },
     { name : 'Australia', value : 1036  },
     { name : 'Brazil', value : 1036  },
     { name : 'Spain', value : 1036  },
     { name : 'United States', value : 1036  },
     { name : 'Italie', value : 1036  },
     { name : 'Japan', value : 1036  },
     { name : 'Russia', value : 1036  }
   ],

   iatReconstruction : [
     { label : 'No IAT recons.', value : 0  },
     { label : 'Resolve back names', value : 1  },
     { label : 'Resolve all refs', value : 2  }
   ],

   useBankerEnvironment : [
     { label : 'Default', value : '' },
     { label : 'Internet Explorer', value : 'iexplorer' },
     { label : 'Firefox', value : 'firefox' },
     { label : 'Google Chrome', value : 'chrome' },
     { label : 'Fake Browser', value : 'fakebrowser' },
   ]
};

export class ScanFile {
  file: File;
  analysisId: number;
  status: FileStatus;
  error: string;
  reportAnalysis: Blob;
  attempt: number;
}

export enum FileStatus {
  onHold = 'onHold',
  uploadInSuccess = 'uploadInSuccess',
  inAnalysis = 'inAnalysis',
  inError = 'inError',
  inSuccess = 'inSuccess'
}

export class ScanAnalysisReport {
  analysed_on: string;
    analysis_duration: string;
    analysis_environment: string;
    analysis_error: string;
    analysis_result: string;
    analysis_target_module: string;
    analysis_timeout: string;
    analyzed_by_worker: string;
    artifacts: {
       cryptographic_keys_and_data: string;
       dropped_files_ssdeep: any;
       written_registry_entries: any[];
    };
    artifacts_number: string;
    command_line_arguments: string;
    dropped_files_nb: number;
    events_collected: string;
    file_name: string;
    file_size: string;
    job_id: string;
    md5: string;
    mfd_id: string;
    mwcfg: any;
    network_dumps: any[];
    network_locations: any[];
    num_process_dumps: string;
    os_interactions: [
        {
          file: any[];
          registry: any[];
        }
      ];
    p2a_version: string;
    packets_seen_nb: number;
    private: string;
    process_list: string;
    process_snapshots: string;
    rulesets: string;
    rulesets_infos: any;
    sha1: string;
    sha256: string;
    signatures: any[];
    specific_configuration: any;
    ssdeep: string;
    status: number;
    subparts: string;
    url_web: string;
    url_web_shared: string;
    virtual_machine: string;
    written_registry_entries_nb: number;
}
