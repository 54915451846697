<div *ngIf="tag">

    <div class="row">
        <div class="col-12"><h2>{{tag}}</h2></div>
    </div>

    <div class="row pt-1">
        <div class="col-12 justify-content-end">

            <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1" (click)="changeViewType('list')"></fa-icon>
            <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1" (click)="changeViewType('grid')"></fa-icon>

        </div>
    </div>

    <div class="card-body p-t-1 p-3" *ngIf="userPreferenceService.userPreferences?.signalViewType === 'list'">
        <app-signal-list [signals]="signals" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="signalsLoaded" (flagEmitter)="flag($event);"></app-signal-list>
    </div>

    <div class="card-body p-t-1 p-3" *ngIf="userPreferenceService.userPreferences?.signalViewType === 'grid'">
        <app-signal-grid [signals]="signals" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="signalsLoaded" (flagEmitter)="flag($event);"></app-signal-grid>
    </div>

    <div class="card-body justify-content-center p-t-1 p-3" *ngIf="signalsLoaded">
        <ngb-pagination [collectionSize]="signalsCount" pageSize="12" page="currentPage" [(page)]="currentPage" [maxSize]="10" aria-label="Pagination" (pageChange)="onPageChange($event)">
            <ng-template ngbPaginationPrevious></ng-template>
            <ng-template ngbPaginationNext></ng-template>
        </ngb-pagination>
    </div>


</div>
