<div (contextmenu)="onRightClick($event)" style="position: relative">
  <ng-content />
  <!-- The value "50" here is just to ensure the child component has been fully loaded -->
  <fa-icon
    *ngIf="
      disabled === false && element.nativeElement.children[0].clientHeight > 50
    "
    [icon]="['fas', 'hand-pointer']"
    size="2xl"
    title="{{ 'pages.cybersocxdr.dashboard.hintRightClick' | translate }}"
    class="position-absolute bottom-0 end-0 px-3 py-2 fa-rotate-by"
    style="--fa-rotate-angle: -35deg"
  />

  <div
    *ngIf="isMenuVisible"
    [ngStyle]="getRightClickMenuStyle()"
    style="z-index: 100; cursor: pointer"
  >
    <div ngbDropdown class="d-inline-block dropdown-menu rounded">
      <p class="dropdown-item m-0" [disabled]="true">
        {{ this.filterItem.name }}
      </p>

      <hr class="dropdown-divider m-0" />
      @for (facet of filterItem.facets; let i = $index; track facet) {
      <a (click)="filterIn(i)" class="dropdown-item pb-1 pt-1">
        <fa-icon [icon]="['fas', 'plus-circle']" />
        Filter In {{ facet }}
      </a>
      }
      <a (click)="filterOut($event)" class="dropdown-item pb-1 pt-1">
        <fa-icon [icon]="['fas', 'minus-circle']" />
        Filter Out
      </a>
    </div>
  </div>
</div>
