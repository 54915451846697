<div class="p-0">
    <div class="row">
    <!-- HEADER -->
        <div class="col-12 text-center container chart-header">
            <h3  *ngIf="!widgetFormat; else largeTitle;">
                {{'pages.securitycase.tpvfaDesc' | translate}}
                <app-help
                    message="{{ 'pages.dashboard.widgets.truePositivesVSFalsePositivesDesc' | translate }}">
                </app-help>
            </h3>
            <ng-template #largeTitle>
                <h5 *ngIf="widgetFormat">{{'pages.securitycase.tpvfaDesc' | translate}}</h5>
            </ng-template>

            <div class="col-2 right-item" *ngIf="widgetFormat">
                <a routerLink="/reports/live/securitycase"class="btn btn-sm btn-primary">
                    {{ 'pages.generic.view' | translate }}
                </a>
            </div>
        </div>
    </div>

    <!-- CHART -->
    <div *ngIf="loaded" [class.chart-container]="widgetFormat">
        <highcharts-chart class="chart"
            [Highcharts]="highcharts"
            [options]="tpvfpDonutChartOptions"
            [oneToOne]=true
            [style.width]="width"
            [style.height]="height">
        </highcharts-chart>
    </div>

    <div class="row justify-content-center p-5" *ngIf="!loaded">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
    </div>
</div>