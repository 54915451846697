import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { graphconstants } from 'src/app/reports/reports-live/report-live-securitycase/graphconstants';
import { constants } from '../../constants';
import { Case } from '../../model/itsm';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'app-incidents-by-action',
  templateUrl: './incidents-by-action.component.html',
  styleUrls: ['./incidents-by-action.component.scss']
})
export class IncidentsByActionComponent implements OnInit {

  //data
  @Input()
  currentCases: Case[];
  @Input()
  previousCases: Case[];
  @Input()
  currentPeriodName: string;
  currentPeriodNameExpanded: string;
  @Input()
  previousPeriodName: string;
  previousPeriodNameExpanded: string;

  //Bar Charts - deprecated
  actionBarChartOptions = JSON.parse(JSON.stringify(graphconstants.barChartOptions));
  previousActionBarChartOptions = JSON.parse(JSON.stringify(graphconstants.barChartOptions));
  Highcharts: typeof Highcharts = Highcharts;
  updateDonutFlag = false;

  //Column Comparison
  columnComparisonChartOptions = JSON.parse(JSON.stringify(graphconstants.columnComparisonChartOptions));


  //show Cases
  showIncidentByActionCases: Case[] = [];

  constructor(private translateService: TranslateService, private widgetService: WidgetService) { }

  ngOnInit(): void {
    this.currentPeriodNameExpanded = this.currentPeriodName === 'last30days' ? this.translateService.instant('pages.generic.last30days') : this.translateService.instant('pages.generic.lastFullCalendarMonth');
    this.previousPeriodNameExpanded = this.previousPeriodName === 'previous30days' ? this.translateService.instant('pages.generic.previous30days') : this.translateService.instant('pages.generic.previousFullCalendarMonth');

    //criteria selected for actions
    const preparedBarData = this.prepareDataForTwoBarChart(this.currentCases, this.previousCases, 'action');
    this.actionBarChartOptions.series = preparedBarData.dp1;
    this.previousActionBarChartOptions.series = preparedBarData.dp2;
    const self = this;
    this.actionBarChartOptions.plotOptions.series.events = {
      click(e) {
        self.selectCasesByPeriod('current', this.name);
      }
    };
    this.previousActionBarChartOptions.plotOptions.series.events = {
      click() {
        self.selectCasesByPeriod('previous', this.name);
      }
    };
    //column comparison
    const actionsColors =  constants.graphColors;
    const getData = (data,current: boolean) => data.map((actor, i) => ({
      ...actor,
      color: current ? actionsColors[i] : 'rgba(158, 159, 163, 0.5)'
    }));
    this.columnComparisonChartOptions.series = [{
      color: 'rgba(158, 159, 163, 0.5)',
      pointPlacement: -0.2,
      linkedTo: 'main',
      data: getData(preparedBarData.dp2, false),
      name: this.previousPeriodName,
      events :{
        click: (e) => {self.selectCasesByPeriod('previous', e.point.options.name);}
      }
    }, {
      name: this.currentPeriodName,
      id: 'main',
      dataLabels: [{
          enabled: true,
          inside: true,
          style: {
              fontSize: '16px'
          }
      }],
      data: getData(preparedBarData.dp1, true),
      events :{
        click: (e) => {self.selectCasesByPeriod('current', e.point.options.name);}
      }
    }];
    this.columnComparisonChartOptions.plotOptions.point = {
      events : {
        click: (e) => {},
        select: (e) => {},
      }
    };
    this.widgetService.updateWidgetStyle(this.actionBarChartOptions, null);
    this.widgetService.updateWidgetStyle(this.previousActionBarChartOptions, null);
    this.widgetService.updateWidgetStyle(this.columnComparisonChartOptions, null);
  }

  prepareDataForTwoBarChart(data1: Case[], data2: Case[], fieldName: string){
    const dic = {};
    data1.forEach((item) => {
      if (item[fieldName]){
        if(dic[item[fieldName].toLowerCase()]!== undefined){
          dic[item[fieldName].toLowerCase()].data1 = dic[item[fieldName].toLowerCase()].data1 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()] = { data1:1, data2:null};
        }
      }
    });
    data2.forEach((item) => {
      if (item[fieldName]){
        if(dic[item[fieldName].toLowerCase()]=== undefined) {
          dic[item[fieldName].toLowerCase()] = { data1:null, data2:1};
        }
        else if(dic[item[fieldName].toLowerCase()].data2 !== undefined){
          dic[item[fieldName].toLowerCase()].data2 = dic[item[fieldName].toLowerCase()].data2 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()].data2 = 1;
        }
      }
    });
    const obj1 = []; const obj2 = [];
    Object.keys(dic).forEach((item: string, i)=>{
      if(dic[item]?.data1) {
        obj1.push({
          name: item,
          y: dic[item].data1,
          color: constants.graphColors[i]
        });
      }
      if(dic[item]?.data2) {
        obj2.push({
          name: item,
          y: dic[item].data2,
          color: constants.graphColors[i]
        });
      }
    });

    return {dp1: obj1, dp2 : obj2};
  }


  selectCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      this.showIncidentByActionCases = this.currentCases.filter( c => c.action.toLowerCase() === selected);
    } else {
      this.showIncidentByActionCases = this.previousCases.filter( c => c.action.toLowerCase() === selected);
    }
  }

}
