<div *ngIf="asset">
  <div class="p-3" data-toggle="tooltip" data-placement="top"
    title="{{ 'pages.assets.assetView.assetContractDesc' | translate }}">
    <div class="row">
      <div class="col-8 col-xl-9">
        <h2>{{ asset.model ? asset.model +  ' | ' + asset.name: ass.name}}</h2>
      </div>
      <div class="col-4 col-xl-3">
        <a class="btn btn-secondary" [routerLink]="['/support/log/']" [queryParams]="{asset:assetRef}"
          *ngIf="entitlementsLoaded && asset.entitlements.length > 0 && asset.active === 'true'">
          {{ 'buttons.logticket' | translate }}
        </a>
        <a class="btn btn-secondary disabled" [routerLink]="['/support/log/']" [queryParams]="{asset:assetRef}"
          *ngIf="entitlementsLoaded && (asset.entitlements.length === 0 || asset.active !== 'true')">
          {{ 'buttons.logticket' | translate }}
        </a>
      </div>
    </div>

    <!-- name -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.name' | translate }}</h5>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{asset.name}}</div>
    </div>

    <!-- serialNumber -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.serialNumber' | translate }}</h5>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{asset.serialNumber}}</div>
    </div>

    <!-- vendor -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.vendor' | translate }}</h5>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{asset.assetVendor ? asset.assetVendor : '--'}}</div>
    </div>

    <!-- osVersion -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.osVersion' | translate }}</h5>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{asset.osVersion ? asset.osVersion : '--'}}</div>
    </div>

    <!-- friendlyName -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.friendlyName' | translate }}</h5>
      </div>
      <div class="col-xl-6 col-lg-4 col-md-4 col-sm-4 col-xs-12">{{asset.friendlyName ? asset.friendlyName : '--'}}
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-12"><button type="button"
          (click)="editAsset(content, 'u_asset_friendly_name', true);" class="btn btn-primary">Edit</button></div>
    </div>

    <!-- hostName -->
    <div class="row p-1 banding">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <h5>{{ 'pages.assets.dashboard.fieldTitles.hostName' | translate }}</h5>
      </div>
      <div class="col-xl-6 col-lg-4 col-md-4 col-sm-4 col-xs-12">{{asset.hostName ? asset.hostName : '--'}}</div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-12"><button type="button"
          (click)="editAsset(content, 'u_asset_host_name', false);" class="btn btn-primary">Edit</button></div>
    </div>

    <!-- entitlements -->
    @if(entitlementsLoaded) {
      <div class="row">
        <div class="col-12">
          <h4>{{ 'pages.assets.assetView.entitlements' | translate }}</h4>
        </div>
      </div>
  
      <div>
        <div class="row faketableheaderrow">
          <div class="col-4 faketableheader">{{ 'pages.assets.dashboard.fieldTitles.name' | translate }}</div>
          <div class="col-4 faketableheader">{{ 'pages.assets.dashboard.fieldTitles.startDate' | translate }}</div>
          <div class="col-4 faketableheader">{{ 'pages.assets.dashboard.fieldTitles.endDate' | translate }}</div>
        </div>
        <div class="row" *ngFor="let ent of entitlements">
          <div class="col-4 faketablecell">{{ent.serviceName || ent.name || ent.shortDescription}}</div>
          <div class="col-4 faketablecell">{{ent.startDate | date: 'dd/MM/yyyy'}}</div>
          <div class="col-4 faketablecell">{{ent.endDate | date: 'dd/MM/yyyy'}}</div>
        </div>
      </div>
    }
  </div>
</div>
<div *ngIf="asset">
  <ul class="nav nav-tabs">
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.assets.assetView.lisetassetCasesDesc' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : page === 'cases' }" (click)="page = 'cases'; loadCases();">{{
        'pages.support.dashboard.cases' | translate }}</a>
    </li>
  </ul>
  <div class="tools">
    <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1 click" (click)="changeViewType('list')"></fa-icon>
    <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1 click" (click)="changeViewType('grid')"></fa-icon>
  </div>
  <div class="card-body" *ngIf="userprefService.userPreferences.assetViewType === 'list' && (page === 'cases' || page === 'flagged')">
    <app-support-list [cases]="subsetCases" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="casesLoaded"
      (flagEmitter)="flag($event);" (sortEmitter)="sortResults($event);"></app-support-list>
  </div>

  <div class="card-body mt-4" *ngIf="userprefService.userPreferences.assetViewType === 'grid' && (page === 'cases' || page === 'flagged')">
    <app-support-grid [cases]="subsetCases" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="casesLoaded"
      (flagEmitter)="flag($event);"></app-support-grid>
  </div>
  <div class="card-body justify-content-center p-t-1 p-3"
    *ngIf="(page === 'cases' || page === 'flagged') && casesLoaded">
    <ngb-pagination [collectionSize]="cases.length" pageSize="{{perPage}}" page="currentPage" [(page)]="currentPage"
      [maxSize]="10" aria-label="Pagination" (pageChange)="onPageChange($event)">
      <ng-template ngbPaginationPrevious></ng-template>
      <ng-template ngbPaginationNext></ng-template>
    </ngb-pagination>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ 'pages.assets.assetView.editFieldModal' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label *ngIf="!displayField" for="contact">{{'pages.assets.dashboard.fieldTitles.hostName' | translate}}</label>
      <label *ngIf="displayField" for="contact">{{'pages.assets.dashboard.fieldTitles.friendlyName' |
        translate}}</label>
      <input type="text" class="form-control" name="fieldValue" aria-describedby="fieldValue" [(ngModel)]="fieldValue">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate
      }}</button>
  </div>
</ng-template>