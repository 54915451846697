<div class="card boxshadow">
    <div class="card-body">
        <div class="text-center">
            <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
            <div class="align-self-center max-width">
                <div class="heading">
                    <h4 class="text-muted m-t-10 m-b-0 m-h-2 me-2">{{heading}}</h4>
                    <fa-icon [icon]="['fas', 'arrow-up']" style="color:#cd3c14" size="lg" *ngIf="icon === 'up'" data-toggle="tooltip" data-placement="top"></fa-icon>
                    <fa-icon [icon]="['fas', 'arrow-down']" style="color:#32c832" size="lg" *ngIf="icon === 'down'" data-toggle="tooltip" data-placement="top"></fa-icon>
                    <fa-icon [icon]="['fas', 'equals']" style="color:#527edb" size="lg" *ngIf="icon === 'equal'" data-toggle="tooltip" data-placement="top"></fa-icon>
                </div>
                <div [class]="className">
                    <h5 class="h3">{{count}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card boxshadow footer" *ngIf="footerMonth">
    <div class="card-body">
        {{footerMonth}}
    </div>
</div>
