export class CookieInfo {
  subgroup: string;
  name: string;
  type: string;
  lifeSpan: string;

  constructor(subgroup: string, name: string, type: string, lifeSpan: string) {
    this.subgroup = subgroup;
    this.name = name;
    this.type = type;
    this.lifeSpan = lifeSpan;
  }
}

export enum Cookies {
  oauthNonce = 'Okta-oauth-nonce',
  oauthRedirectParms = 'Okta-oauth-redirect-parms',
  oauthState = 'Okta-oauth-state'
}
