import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-xtended-faq-preview',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './xtended-faq-preview.component.html',
})
export class XtendedFaqPreviewComponent {
  @Input() faq: { question: string; answer: string };

  constructor(public contextService: ContextService) {}
}
