import { ChartType } from 'src/app/shared/model/activity';
import * as Highcharts from 'highcharts';
import { constants } from '../../../../app/shared/constants';

export const graphconstants  = {
    barChartOptions: {
        colors: constants.graphColors,
        chart: {
            type: 'bar'
          },
        xAxis: {
            categories: [
                'Threat Actions'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
        },
        credits: {
            enabled : false
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 15
        },
        plotOptions: {
            series: {
                maxPointWidth: 25
            }
        },
        series : []

    },
    pieChartOption: {
        colors: constants.graphColors,
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
        },
        credits: {
            enabled : false
        },
        legend: {
            enabled : true,
            floating: true,
            align: 'left',
            verticalAlign: 'top',
            itemDistance: 250,
            layout: 'vertical',
            x: 0,
            y: 0,
            width: 200,
            overlap: true
        },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 15
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: {point.y}%'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    overflow: 'allow',
                    crop: false,
                    style: {
                        fontWeight: 'bold'
                    }
                },
                startAngle: -90,
                endAngle: -180,
                center: ['50%', '50%'],
                size: '75%',
                showInLegend: true
            }
        },
        series: [],
        // responsive rules
      responsive: {
        rules: [{
          condition: {
            maxWidth: 600
          },
          chartOptions: {
            legend: {
              itemStyle: {
                fontSize: '10px'
              }
            }
          }
        }]
      }
    },
    columnChartOptions: {
        colors: constants.graphColors,
        chart: {
            type: 'column',
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: [
                'No Impact',
                'Confidentiality',
                'Integrity',
                'Availability'
            ],
            crosshair: true
        },
        yAxis: {
            title: {
                useHTML: true,
                text: 'Percentage'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
            pointFormat: '<tr><td style="padding:0">Percentage: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>' +
                '<tr><td>Number of cases: </td>' +
                '<td style="padding:0"><b>{point.cases}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                colorByPoint : true
            }
        },
        series: []
    },
    columnComparisonChartOptions:  {
        colors: constants.graphColors,
        chart: {
            type: 'column'
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: 'Comparing incidents from current period and previous period',
            align: 'center'
        },
        plotOptions: {
            series: {
                grouping: false,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        tooltip: {
            shared: true,
            headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b> threats<br/>'
        },
        xAxis: {
            type: 'category',
            accessibility: {
                description: 'Threat Actors'
            },
            /* max: 4, */
            labels: {
                useHTML: true,
                animate: true,
                /* formatter: ctx => {
                    let flag;

                    const countries = ['germany', 'france']

                    countries.forEach(function (country) {
                        if (country.name === ctx.value) {
                            flag = country.flag;
                        }
                    });

                    return `${flag.toUpperCase()}<br><span class="f32">
                        <span class="flag ${flag}"></span>
                    </span>`;
                }, */
                style: {
                    textAlign: 'center'
                }
            }
        },
        yAxis: [{
            title: {
                text: 'Cases'
            },
            showFirstLabel: false
        }],
        exporting: {
            allowHTML: true
        }
    },
    catColulmnChartOptions: {
        colors: constants.graphColors,
        chart: {
          type: ChartType.column
        },
        credits: {
          enabled : false
        },
        legend: {
          enabled : true
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis:{
            categories: [],
            crosshair: true,
        },
        yAxis : {
            min: 0,
            title: {
              text: 'Cases'
            },
            stackLabels: {
              enabled: true,
              style: {
                  fontWeight: 'bold',
                  color: ( // theme
                      Highcharts.defaultOptions.title.style &&
                      Highcharts.defaultOptions.title.style.color
                  ) || 'gray'
              }
          }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
      },
        series: []
      }
};
