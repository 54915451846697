import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

type SerieType = Highcharts.SeriesAreaOptions | Highcharts.SeriesSplineOptions | Highcharts.SeriesAreasplineOptions;

@Component({
  selector: 'app-insightchart',
  templateUrl: '../basechart/basechart.component.html',
})
export class InsightchartComponent extends BaseChartComponent<
  [number, number][],
  { series: SerieType[] } & Highcharts.Options
> {
  @Input()
  isPositive: boolean;

  series: Highcharts.SeriesXrangeOptions;
  options: { series: SerieType[] } & Highcharts.Options = {
    chart: {
      type: 'areaspline',
      height: 100,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: { enabled: false },
      title: { text: null },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      title: { text: null },
      labels: { enabled: false },
    },
    tooltip: {
      pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y} </b><br/>`,
      shared: true,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: { areaspline: { fillOpacity: 0.5 } },
    series: undefined,
  };

  draw(renderingId: string) {
    // Default to no data
    //this.options.series = [{ name: 'No data found for this period', data: [], type: 'area' }];
    this.options.plotOptions.series = {};
    this.options.title.text = this.title;

    this.options.series = [
      { name: '', data: this.data, type: 'areaspline', color: this.isPositive ? '#34c833' : '#FF0000' },
    ];
  }
}
