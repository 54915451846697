<div class="search-section" (clickOutside)="closeSearchPanel()">
  <form role="search">
    <!-- INPUT -->
    <fa-icon
      id="glass"
      [icon]="['fas', 'magnifying-glass']"
      size="lg"
      data-toggle="tooltip"
      data-placement="top" (click)="focusSearch()">
    </fa-icon>
    <input
      type="text"
      name="search"
      id="inputSearch"
      class="form-control search-input"
      (input)="triggerSearch($event.target.value)"
      [(ngModel)]="inputValue"
      placeholder="{{ 'pages.dashboard.searchTicketsHere' | translate }}"
      attr.aria-label="{{ 'pages.dashboard.searchTicketsHere' | translate }}"
      autocomplete="off"/>
  </form>

  <div id="searchResults" class="search-results dark" *ngIf="searchCanvas">

    <!-- LOADING WHEEL -->
    @if (resultsLoading) {
      <div class="spinner-container">
        <div class="spinner-border text-primary" aria-live="polite" aria-busy="true">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
      </div>
    
    } @else {
      <!-- RESULTS LIST -->
    <div>
      <div class="results-title" [hidden]="resultsLoading">
        {{ 'pages.dashboard.supportCases' | translate | uppercase }}
      </div>
      <div class="no-data" [hidden]="cases.length > 0 || resultsLoading">
        {{ 'pages.dashboard.noResults' | translate }}
      </div>
      <ul id="resultsList" [ngClass]="cases.length > 0 ? 'got-results' : 'no-results'"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false">

        @for (case of cases; track $index) {
          <!-- Key navigation with onKeyDown handled with @HostListener in component -->
        <li
          id="caseIndex_{{$index}}"
          [hidden]="resultsLoading"
          (click)="goToCase(case.id)"
          title="{{ case.caseNumber }} : {{ case.subject }}"
          class="search-item cursorpointer"
          [ngClass]="$index === selectedCaseIndex && 'focused'">
            <div class="case-number">{{ case.caseNumber }}</div>  
            <div class="case-subject">{{ case.subject }}</div>
          </li>
        }
        
      </ul>
    </div>
    }
    
  </div>

</div>