<div class="section">

  <div>
    <h3 class="title">{{ title | translate }}</h3>
  </div>

  @if (mttLoading) {
  <div class="row justify-content-center p-5">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
  } @else if (!errorMessage) {
  <div [class]="['wrapper', mttType]">
    <div [class]="['incipit', widgetTheme]">
      {{ incipit | translate }}
    </div>
    <div [class]="['company-mean-time', widgetTheme]">
      @if (companyAverageValue !== 0) {
      <div class="value">{{ companyAverageValue}}</div>
      <div class="time-unit">{{ timeUnit | translate }}</div>
      } @else {
        <div class="value no-data">{{ 'pages.dashboard.widgets.mtt.noData' | translate }}</div>
      }
    </div>
    <div class="industry-mean-time">{{ ocdAverageText | translate: {average: ocdAverageValue} }}</div>
  </div>
  } @else {
  <div class="alert alert-danger alert-sm" role="alert">
    <fa-icon [icon]="['fas', 'info-circle']" class="info-icon mx-2" size="xl" placement="left"></fa-icon>
    <p [ngClass]="{'error-message': true, 'light-theme': theme === 'light'}">{{ errorMessage }}</p>
  </div>
  }
    
</div>
