import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FileStatus, ScanFile } from 'src/app/updates/cert-tools/page/cert-tools-items';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './mdf-drag-and-drop.component.html',
  styleUrls: ['./mdf-drag-and-drop.component.scss'],
})
export class MFDDragAndDropComponent {

  @Input() scanFiles: ScanFile[];
  @Input() validForm: boolean;
  @Input() mfdQuota: number;

  @Output() submitForm = new EventEmitter<File[]>();
  @Output() clickFile = new EventEmitter<File>();
  @Output() refreshFile = new EventEmitter<File>();

  public onHold = FileStatus.onHold;
  public inAnalysis = FileStatus.inAnalysis;
  public inSuccess = FileStatus.inSuccess;
  public inError = FileStatus.inError;

  public sufficientQuota = true;

  public onFileClick(file) {
    this.clickFile.emit(file);
  }

  public onRefreshFile(file) {
    this.refreshFile.emit(file);
  }

  /**
   * on file drop handler
   */
  public onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile(index: number) {
    this.scanFiles.splice(index, 1);
    this.checkQuotas();
  }

  /**
   * check start scan button is available
   */
  public validFiles() {
    if(this.mfdQuota > 0 && this.sufficientQuota) {
      this.submitForm.emit();
    }
  }

  public canLaunch() {
    // can only launch scan when files are in error or on hold
    return this.scanFiles.filter((file) => file.status === FileStatus.onHold || file.status === FileStatus.inError).length > 0;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
    public formatBytes(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesList(files: Array<any>) {
    for (const item of files) {
      const scanfile = new ScanFile();
      scanfile.file = item;
      scanfile.status = FileStatus.onHold;
      this.scanFiles.push(scanfile);
    }
    this.checkQuotas();
  }

  private checkQuotas() {
    const filesToAnalyse = this.scanFiles.filter(
      (file) => file.status === FileStatus.onHold || file.status === FileStatus.inError
    );
    this.sufficientQuota = filesToAnalyse.length <= this.mfdQuota;
  }
}
