import { ChartType } from 'src/app/shared/model/activity';

export class SecurityCaseActivity {
  date: string;
  action: Map<string, number>;
  subAction: Map<string, number>;
  actor: Map<string, number>;
  attributes: Map<string, number>;
  u_asset: Map<string, number>;
  killChain: Map<string, number>;
  whatFalsePositive: Map<string, number>;
  whoFalsePositive: Map<string, number>;
  whyFalsePositive: Map<string, number>;
  assetName: Map<string, number>;
  assetVendor: Map<string, number>;

  truePositives: any[];
  falsePositives: any[];

  constructor(date: string) {
    this.date = date;
    this.action = new Map();
    this.subAction = new Map();
    this.actor = new Map();
    this.attributes = new Map();
    this.killChain = new Map();
    this.u_asset = new Map();
    this.whatFalsePositive = new Map();
    this.whoFalsePositive = new Map();
    this.whyFalsePositive = new Map();
    this.assetName = new Map();
    this.assetVendor = new Map();
    this.truePositives = [];
    this.falsePositives = [];
  }
}

export class DataPoint {
  name: string;
  data: any[];
  type?: string;
  innerSize?: string;
  showInLegend?: boolean;
}

export class DataPointY {
  name: string;
  y: number;
}

export interface PieFormat {
  colors: string[];
  chart: {
    plotBackgroundColor?: string;
    plotBorderWidth: number;
    plotShadow: boolean;
  };
  credits: {
    enabled: boolean;
  };
  legend: {
    enabled: boolean;
  };
  title: {
    text: string;
    align: string;
    verticalAlign: string;
    y: number;
  };
  tooltip: {
    headerFormat: string;
    pointFormat: string;
  };
  plotOptions: {
    pie: {
      allowPointSelect: boolean;
      cursor: string;
      dataLabels: {
        enabled: boolean;
        style: {
          fontWeight: string;
        };
      };
      startAngle: number;
      endAngle: number;
      center: any[];
      size: string;
      showInLegend: boolean;
    };
  };
  series: any[];
}

export interface ColumnFormat {
  colors: string[];
  chart: {
    type: ChartType;
  };
  credits: {
    enabled: boolean;
  };
  legend: {
    enabled: boolean;
  };
  title: {
    text: string;
  };
  subtitle: {
    text: string;
  };
  xAxis: {
    categories: string[];
    crosshair: boolean;
  };
  yAxis: {
    min: number;
    title: {
      text: string;
    };
    stackLabels: {
      enabled: boolean;
      style: {
        fontWeight: string;
        color: string;
      };
    };
  };
  tooltip: {
    headerFormat: string;
    pointFormat: string;
  };
  plotOptions: {
    column: {
      stacking: string;
      dataLabels: {
        enabled: boolean;
      };
    };
  };
  series: DataPoint[];
}
