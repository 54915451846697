<div class="container bg-body-tertiary p-3" style="max-width: 1800px">
  <div class="row justify-content-between">
    <div class="col-12">
      <app-card
        class="h-100"
        cardTitle="pages.cybersocxdr.incidentDetail.incidentStateOvertime"
      >
        <app-incident-timeline [data]="xdrCase.timeline" />
      </app-card>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100"
        cardTitle="pages.cybersocxdr.dashboard.fieldTitles.incidentInformation"
      >
        <div class="row mb-3">
          <div class="col-6">
            <h4 class="mb-2">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.incidentUrl'
                  | translate
              }}
            </h4>
            <a [href]="xdrCase.incidentUrl" class="col-6">
              {{ xdrCase.incidentUrl | shortUrl | anonymise }}
            </a>
          </div>
          <div class="col-6">
            <h4 class="mb-2">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.occured' | translate
              }}
            </h4>
            <p>{{ xdrCase.createdDate | date : 'short' }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <h4 class="mb-3">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.severity' | translate
              }}
            </h4>
            <span
              class="py-1 px-2 rounded-pill fw-bold"
              [ngClass]="'P' + xdrCase.severity | lowercase | removeWhiteSpace"
            >
              {{ xdrCase.severity | severity | translate }}
            </span>
          </div>
          <div class="col-6">
            <h4 class="mb-2">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.incidentOwner'
                  | translate
              }}
            </h4>
            <p>{{ (xdrCase.owner | anonymise) || 'None' }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <h4 class="mb-3">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.category' | translate
              }}
            </h4>
            <p>{{ xdrCase.category }}</p>
          </div>
          <div class="col-6">
            <h4 class="mb-2">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.description'
                  | translate
              }}
            </h4>
            <p>{{ xdrCase.subject | anonymise }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h4 class="mb-2">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.status' | translate }}
            </h4>
            <p>
              {{ xdrCase.socXdrStatus }}
            </p>
          </div>
        </div>
      </app-card>
      <app-card
        class="mw-100 mb-lg-0"
        cardTitle="pages.cybersocxdr.dashboard.fieldTitles.slaFull"
      >
        <div class="row">
          <div
            *ngIf="
              xdrCase.notificationSla && xdrCase.investigationSla;
              else noSla
            "
            class="row"
          >
            <div class="col-6 text-center">
              <h4 class="font-weight-bold">
                {{
                  'pages.cybersocxdr.dashboard.fieldTitles.slaNotificationTime'
                    | translate
                }}
              </h4>
              <p
                class="display-3"
                [ngClass]="{
                  'text-danger':
                    xdrCase.notificationSla > xdrCase.allowedNotificationSla,
                  'text-success':
                    xdrCase.notificationSla <= xdrCase.allowedNotificationSla
                }"
              >
                {{ parseTimeLeft(xdrCase.notificationSla) }}
              </p>
            </div>
            <div class="col-6 text-center">
              <h4 class="font-weight-bold">
                {{
                  'pages.cybersocxdr.dashboard.fieldTitles.slaInvestigationTime'
                    | translate
                }}
              </h4>
              <p
                class="display-3"
                [ngClass]="{
                  'text-danger':
                    xdrCase.investigationSla > xdrCase.allowedInvestigationSla,
                  'text-success':
                    xdrCase.investigationSla <= xdrCase.allowedInvestigationSla
                }"
              >
                {{ parseTimeLeft(xdrCase.investigationSla) }}
              </p>
            </div>
          </div>
          <ng-template #noSla>
            <p>
              {{ 'pages.cybersocxdr.dashboard.noSla' | translate }}
            </p>
          </ng-template>
        </div>
      </app-card>
    </div>
    <div class="col-12 col-lg-6">
      <app-card
        class="h-100 mw-100"
        cardTitle="pages.cybersocxdr.dashboard.investigation"
      >
        <p
          id="investigationContent"
          *ngIf="xdrCase.investigationContent; else noInvest"
        >
          {{ xdrCase.investigationContent | anonymise }}
        </p>
        <ng-template #noInvest>
          <p>
            {{ 'pages.cybersocxdr.dashboard.noInvestigation' | translate }}
          </p>
        </ng-template>
      </app-card>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100 mb-lg-0"
        cardTitle="{{
          'pages.cybersocxdr.charts.preciseCategoryOvertime'
            | translate : { category: xdrCase.category }
        }}"
      >
        <app-barovertime
          [data]="categoryHighchartsData"
          [createdFrom]="oldDate"
          [createdTo]="currentDate"
        />
      </app-card>
    </div>
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100 h-100"
        cardTitle="pages.cybersocxdr.charts.similarOvertime"
      >
        <app-barovertime
          [data]="similarHighchartsData"
          [createdFrom]="oldDate"
          [createdTo]="currentDate"
        />
      </app-card>
    </div>
  </div>
  @if (xdrCase) {
  <div class="row">
    <app-card>
      <app-microsoccase-list
        title="{{ 'pages.cybersocxdr.menu.similarIncident' | translate }}"
        [loaded]="true"
        [cases]="similarXdrCases"
        [main]="'false'"
      />
    </app-card>
  </div>
  }
</div>
