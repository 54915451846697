import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../../model/itsm';
import { ItsmService } from '../../services/itsm.service';
import { SessionStorageItem } from '../../model/shared-items';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent {

  @Input()
  contacts: Contact[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  @Output()
  reloadContacts = new EventEmitter<any>();

  @Input()
  loaded: boolean;
  constructor(
    private itsmService: ItsmService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  //Error messaged
  notdeleted = false;
  deleted = false;

  deleteContact(id: string): void{

    this.loaded=false;
    this.itsmService.deleteContact(id)
    .then(() => {
      this.alertService.addSuccess(this.translateService.instant('pages.support.contacts.contactdeleted'));
      this.itsmService.contacts = null;
      this.reloadContacts.emit();
    })
    .catch(() => {
      this.alertService.addError(this.translateService.instant('pages.support.contacts.contactnotdelete'));
    })
    .finally(() => {
      this.loaded=true;
    });
  }
  editUser(contact: {}): void{
    this.router.navigateByUrl('/support/contacts/addcontacts', {state:{userData:contact}});
  }

}
