import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CaseXDR } from '../../../shared/model/cybersocxdr/microsocticket';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';

@Component({
  selector: 'app-microsoccase-list',
  templateUrl: './microsocCase-list.component.html',
  styleUrls: ['./microsocCase-list.component.scss'],
})
export class MicrosocCaseListComponent {
  @Input()
  cases: CaseXDR[];

  @Input()
  title: string;

  @Input()
  badgeAnnotation: string | number;

  @Output()
  newFlagEmitter: EventEmitter<CaseXDR> = new EventEmitter<CaseXDR>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  @Input()
  loaded: boolean;

  @Input()
  main: string;

  @Input()
  scrollLoading: boolean;

  @Input()
  exportable: boolean = false;

  @Output()
  exportEmitter = new EventEmitter<void>();

  @Input()
  selectableColumns: boolean = false;

  constructor(private cybersocxdrService: CybersocxdrService) {}

  flagCase(event: Event, incident: CaseXDR): boolean {
    event.stopPropagation();
    this.newFlagEmitter.emit(incident);
    return false;
  }

  sortResults(event: Event, field: string): void {
    event.stopPropagation();
    this.sortEmitter.emit(field);
  }

  exportCSV(event: Event): void {
    event.stopPropagation();
    this.exportEmitter.emit();
  }
}
