import { Component, OnInit } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { constants } from 'src/app/shared/constants';
import { SignalSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { Signal } from 'src/app/shared/model/signal';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';

@Component({
  selector: 'app-viewtag',
  templateUrl: './viewtag.component.html',
  styleUrls: ['./viewtag.component.scss']
})
export class ViewTagComponent extends FlaggedListComponent implements OnInit {

  tag: string;
  searchCriteria: SignalSearchCriteria;
  searched: boolean;
  signalsLoaded: boolean;
  signals: Signal[];
  signalsCount: number;

  // pagination
  perPage = constants.recordsPerPage.vulns;
  currentPage: number;

  constructor(
    private route: ActivatedRoute,
    private worldWatchService: WorldWatchService,
    public userprefService: UserPreferenceService,
    public alertService: AlertService) 
  {
    super(userprefService, alertService);
    this.flagType = constants.local.flags.tag;
    this.viewType = constants.local.views.signals;
    super.checkPreferences();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.tag = params.tag);
    this.currentPage = 1;

    this.refreshSignalUserPrefs();

    this.searchCriteria = new SignalSearchCriteria();
    this.searchCriteria.tags = [this.tag];
    this.searchCriteria.returnStub = true;
    this.searchCriteria.limit = this.perPage;
    this.searchCriteria.skip = this.perPage * (this.currentPage-1);

    this.signalSearch();

  }

   /**
    * Utility method to load count of results as well as results themselves
    * Used for pagination
    */
  private signalSearch(): Promise<void> {
    this.signalsLoaded = false;
    return new Promise((resolve,reject) => {
      this.searchCriteria.count = true;
      const tempLimit = this.searchCriteria.limit;
      delete this.searchCriteria.limit;

      this.worldWatchService.signalSearch(this.searchCriteria)
        .then(res => {
          if(res && res[0]){
            this.signalsCount = res[0].matching_vulns;
          }else{
            this.signalsCount = 0;
          }

          this.searchCriteria.count = false;
          this.searchCriteria.limit = tempLimit;
          this.worldWatchService.signalSearch(this.searchCriteria)
            .then(res => {
              this.signals = res;
              this.signalsLoaded = true;
              resolve();
            })
            .catch(err => {
              throw err;
            });
        })
        .catch(err => {
          reject();
          this.handlerError(err);
        });
    });
  }

  /**
   * Refresh the local flagRefs and viewType variable from user preferences
   */
  refreshSignalUserPrefs() {
    // load the flagged signals from user preferences
    const preference = this.userprefService.userPreferences;
    if (preference?.flags && !preference?.flags.tag) {
      this.userprefService.userPreferences.flags[this.flagType] = [];
    }
    if (preference?.signalViewType) {
      this.viewType = preference?.signalViewType;
    } else {
      this.viewType = 'list';
    }
  }

  /**
   * Pagination
   *
   * @param event
   */
  onPageChange(event): void {
    this.searchCriteria.skip = this.perPage * (this.currentPage-1);
    this.signalSearch();
  }  

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
  private handlerError(err){
    this.alertService.handlerError(err);
  }
}
