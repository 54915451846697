<div class="content">
  <div class="modal-header">
    <fa-icon [icon]="['fas', 'newspaper']" class="icon" size="3x" *ngIf="type === 'news'"></fa-icon>
    <fa-icon [icon]="['fas', 'blog']" class="icon" size="3x" *ngIf="type === 'blog'"></fa-icon>
    <fa-icon [icon]="['fas', 'calendar-alt']" class="icon" size="3x" *ngIf="type === 'event'"></fa-icon>
    <fa-icon [icon]="['fas', 'scroll']" class="icon" size="3x" *ngIf="type === 'whitepaper'"></fa-icon>
    <a [href]="selectedRssFeed.link" target="_new">{{selectedRssFeed.title}}</a>
    <span class="date">{{ selectedRssFeed.pubDate | date:'dd/MM/yyyy HH:mm' }}</span>
    <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Close" (click)="close()">
      <span class="visually-hidden">Close</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p [innerHTML]="selectedRssFeed.description"></p>
    </div>
  </div>
  <div class="modal-footer">
    <a [href]="selectedRssFeed.link" target="_new" class="btn btn-primary">{{'buttons.action.originalPost' | translate}}</a>
    <button type="button" class="btn btn-secondary" (click)="close()">{{'buttons.action.close' | translate}}</button>
  </div>
</div>
