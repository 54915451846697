import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BacklogStatus } from 'src/app/shared/model/cybersocxdr/backlogStatus';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { DateTime } from 'luxon';
import { IncidentStatus, Severity, SLANotification } from 'src/app/shared/model/cybersocxdr/facetsValues';

@Component({
  selector: 'app-backlog-sanity-score',
  templateUrl: './backlog-sanity-score.component.html',
})
export class BacklogSanityScoreComponent implements OnChanges {
  @Input()
  backlogStatus: BacklogStatus;
  actionTitleWithTotal: string;

  criteriaToApplyOlderThanAMonth: Partial<CybersocXDRDashboardSearchCriteria> = {
    createdFrom: DateTime.now().minus({ months: 7 }).toFormat('yyyy-MM-dd'),
    createdTo: DateTime.now().minus({ months: 1 }).toFormat('yyyy-MM-dd'),
    severitys: [Severity.HIGH, Severity.CRITICAL],
    slaNotification: [SLANotification.BREAK, SLANotification.AT_RISK, SLANotification.IN],
  };

  criteriaToApplyOngoingTickets: Partial<CybersocXDRDashboardSearchCriteria> = {
    status: [IncidentStatus.NEW, IncidentStatus.INVESTIGATION, IncidentStatus.REMEDIATION],
    clientTicketCreated: 'true',
  };

  getBarStyle() {
    return { width: `${this.backlogStatus?.sanityScore ?? 0}%` };
  }

  ngOnChanges(changes: SimpleChanges) {
    // Vérifiez si backlogStatus a changé
    if (changes.backlogStatus) {
      this.updateActionTitle();
    }
  }

  updateActionTitle() {
    const olderThanAMonth = this.backlogStatus?.olderThanAMonth ?? 0;
    this.actionTitleWithTotal = `${olderThanAMonth} ` + `pages.cybersocxdr.backlog.olderThanAMonth`;
  }
}
