<div class="row justify-content-center p-5" *ngIf="loadingVuln">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>

<div class="row" *ngIf="cve">
    <div class="col-1">&nbsp;</div>
    <div class="col-10 p-3">

        <div class="row">
            <div class="col-12"><h2>{{cve._id}}</h2></div>
        </div>
        <div class="row p-1" *ngIf="cve.publishedDate">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.published' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{cve.publishedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        </div>

        <div class="row p-1" *ngIf="cve.lastModifiedDate">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.modified' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">{{cve.lastModifiedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        </div>

        <div class="row p-1">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.severity' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">

                <div *ngIf="cve.impact.baseMetricV3">
                    <span class="tag no-border tag-info" *ngIf="cve.impact.baseMetricV3.cvssV3.baseSeverity === 'LOW'">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
                    <span class="tag no-border tag-success" *ngIf="cve.impact.baseMetricV3.cvssV3.baseSeverity === 'MEDIUM'">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
                    <span class="tag no-border tag-warning" *ngIf="cve.impact.baseMetricV3.cvssV3.baseSeverity === 'HIGH'">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
                    <span class="tag no-border tag-danger" *ngIf="cve.impact.baseMetricV3.cvssV3.baseSeverity === 'CRITICAL'">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</span>
                </div>
                <div *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">
                    <span class="tag no-border tag-info" *ngIf="cve.impact.baseMetricV2.severity === 'LOW'">{{cve.impact.baseMetricV2.severity}}</span>
                    <span class="tag no-border tag-success" *ngIf="cve.impact.baseMetricV2.severity === 'MEDIUM'">{{cve.impact.baseMetricV2.severity}}</span>
                    <span class="tag no-border tag-warning" *ngIf="cve.impact.baseMetricV2.severity === 'HIGH'">{{cve.impact.baseMetricV2.severity}}</span>
                    <span class="tag no-border tag-danger" *ngIf="cve.impact.baseMetricV2.severity === 'CRITICAL'">{{cve.impact.baseMetricV2.severity}}</span>
                </div>
                <div *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">
                    <span class="tag no-border text-bg-light">N/A</span>
                </div>

            </div>
        </div>

        <div class="row p-1">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <div *ngIf="cve.impact.baseMetricV3">{{cve.impact.baseMetricV3.impactScore}}</div>
                <div *ngIf="!cve.impact.baseMetricV3 && cve.impact.baseMetricV2">{{cve.impact.baseMetricV2.impactScore}}</div>
                <div *ngIf="!cve.impact.baseMetricV3 && !cve.impact.baseMetricV2">N/A</div>
            </div>
        </div>

        <div class="row p-1" *ngIf="cve.cve.problemtype && cve.cve.problemtype.problemtype_data">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.problemType' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <div *ngFor="let prob of cve.cve.problemtype.problemtype_data">
                    <span *ngFor="let problemType of prob.description">
                        {{problemType.value}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row p-1" *ngIf="cve.cve.references.reference_data">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.references' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <div *ngFor="let ref of cve.cve.references.reference_data">
                    <a href="{{ref.url}}" target="_new">{{ref.url}}</a>
                </div>
            </div>
        </div>
        <div class="row p-1" *ngIf="cve.cve.description.description_data">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.description' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <div *ngFor="let desc of cve.cve.description.description_data" class="preserve-whitespace">
                    {{desc.value}}
                </div>
            </div>
        </div>

        <div class="row" *ngIf="cve.impact.baseMetricV3 || cve.impact.baseMetricV2">
            <div class="col-12"><h4>{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}</h4></div>
        </div>

        <div class="row p-1" *ngIf="cve.impact.baseMetricV3">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.baseMetricV3' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <div class="row">
                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.exploitability' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.exploitabilityScore}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.impactScore}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.exploitability' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.exploitabilityScore}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.impactScore}}</div>
                </div>
            </div>
        </div>

        <div class="row p-1" *ngIf="cve.impact.baseMetricV3">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.cvssv3' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">

                <div class="row">
                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.vectorString' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.vectorString}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.attackVector' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.attackVector}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.attackComplexity' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.attackComplexity}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.privilegesReq' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.privilegesRequired}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.userInteraction' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.userInteraction}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.scope' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.scope}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.confidentialityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.confidentialityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.integrityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.integrityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.availabilityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.availabilityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.baseScore' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.baseScore}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.baseSeverity' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV3.cvssV3.baseSeverity}}</div>
                </div>

            </div>
        </div>

        <div class="row p-1" *ngIf="cve.impact.baseMetricV2">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.baseMetricV2' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">

                <div class="row">
                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.severity' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.severity}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.exploitability' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.exploitabilityScore}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.impactScore}}</div>
                </div>

            </div>
        </div>

        <div class="row p-1" *ngIf="cve.impact.baseMetricV2">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.cvssv2' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">

                <div class="row">
                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.vectorString' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.vectorString}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.accessVector' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.accessVector}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.accessComplex' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.accessComplexity}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.authentication' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.authentication}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.confidentialityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.confidentialityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.integrityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.integrityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.availabilityImpact' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.availabilityImpact}}</div>

                    <div class="col-md-5 col-sm-6">{{ 'pages.updates.vulnerabilities.fieldTitles.baseScore' | translate }}</div>
                    <div class="col-md-5 col-sm-6">{{cve.impact.baseMetricV2.cvssV2.baseScore}}</div>
                </div>

            </div>
        </div>

        <div class="row p-1" *ngIf="cve.configurations.nodes">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"><h5>{{ 'pages.updates.vulnerabilities.fieldTitles.cpe' | translate }}</h5></div>
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <span *ngFor="let node of cve.configurations.nodes">
                    <div class="row" *ngFor="let cpe of node.cpe_match">
                        <div class="col-12">
                           <p>{{cpe.cpe23Uri}}</p>
                        </div>
                    </div>
                </span>
            </div>
        </div>

    </div>
    <div class="col-1">&nbsp;</div>
  </div>
