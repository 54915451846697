import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ClientEntityDto {
  id: number;
  name: string;
  members: UserInEntityDto[];
}

export interface UserInEntityDto {
  id: number;
  mail: string;
}

const ROOT_URL = environment.endpoints.cybersocxdr;
const CLIENT_URL = ROOT_URL + 'clients';
const ENTITIES_URL = CLIENT_URL + '/entities';

@Injectable({
  providedIn: 'root',
})
export class XtendedClientService {
  constructor(private http: HttpClient) {}

  async getEntities(): Promise<ClientEntityDto[]> {
    return firstValueFrom(this.http.get<ClientEntityDto[]>(ENTITIES_URL));
  }

  async getEntityById(entityId: number): Promise<ClientEntityDto> {
    return firstValueFrom(this.http.get<ClientEntityDto>(`${ENTITIES_URL}/${entityId}`));
  }

  async addUserToEntity(entityId: number, userMail: string): Promise<void> {
    return firstValueFrom(this.http.post<void>(`${ENTITIES_URL}/${entityId}/users`, null, { params: { userMail } }));
  }

  async removeUserFromEntity(userInEntityId: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`${ENTITIES_URL}/users/${userInEntityId}`));
  }
}
