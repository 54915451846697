import { Component, Input, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { MFDAnalysis } from '../../../page/cert-tools-items';

@Component({
  selector: 'app-mfs-service',
  templateUrl: './mfs-service.component.html',
  styleUrls: ['./mfs-service.component.scss']
})
export class MfsServiceComponent implements OnInit {

  @Input() mfdAnalysis: MFDAnalysis;

  mfdQuota: number;
  loading=true;

  constructor(
    private mfdService: MfdService,
    private alertService: AlertService,
    private userActivity: UserActivityService
  ) { }

  ngOnInit() {
    this.mfdService.getQuota()
    .then(quota => {
      this.mfdQuota = quota;
      this.loading = false;
    })
    .catch(e => {
      this.alertService.handlerError(e);
    });

    this.userActivity.logActivity(TYPES.services, PAGES.certToolbox, ACTIONS.visit);
  }

}
