import { Component, Input } from '@angular/core';
import Highcharts from 'highcharts';
import { IncidentScale } from 'src/app/shared/model/cybersocxdr/incidentScale';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-scatterpyramid',
  templateUrl: '../basechart/basechart.component.html',
})
export class ScatterpyramidComponent extends BaseChartComponent<IncidentScale> {
  @Input() width = 2.5;
  height = 2;

  // Since graphs have a bit of padding, we allow points to go overboard
  startPointPosition = -0.5;
  endPointPosition = 1.25;

  options: Highcharts.Options = {
    colors: ['#FFD200', '#FF7900', '#CD3C14'],
    chart: {
      type: 'scatter',
      inverted: true,
    },
    legend: {
      enabled: true,
    },
    title: { text: '' },

    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      scatter: {
        showInLegend: false,
        marker: {
          radius: 3.2,
          symbol: 'circle',
        },
        tooltip: {
          pointFormat: '',
        },
      },
    },
  };

  isDataEmpty(data: IncidentScale): boolean {
    return (
      super.isDataEmpty(data) || (data.totalIncidents === 0 && data.treatedIncidents === 0 && data.trueIncidents === 0)
    );
  }

  draw(renderingId: string) {
    this.options.series = this.buildScatterSeries();
    this.options.title.text = this.title;

    this.options.yAxis = {
      categories: [
        `${this.data.totalIncidents} Alerts`,
        `${this.data.treatedIncidents} Managed Incidents`,
        `${this.data.trueIncidents} True Positives`,
      ],
      startOnTick: false,
      min: 0,
      max: this.height,
      endOnTick: false,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontWeight: 'bold',
          fontSize: '25px',
        },
      },
    };

    this.options.xAxis = {
      visible: false,
      //min: 0.25,
      //max: this.width - 0.25,
    };
  }

  /**
   * Builds 3 series back to back to form a pyramid shape
   *
   * @returns A usable Highchart series option object
   */
  buildScatterSeries(): Highcharts.SeriesScatterOptions[] {
    const endTotalIncident = 0.25;
    const endTreatedIncident = 0.75;

    return [
      {
        turboThreshold: 1,
        type: 'scatter',
        marker: { radius: 5 },
        name: `${this.data.totalIncidents} Alerts`,
        data: this.buildPointSeries(
          Math.min(this.data.totalIncidents, 1000),
          this.startPointPosition,
          endTotalIncident
        ),
      },
      {
        turboThreshold: 1,
        type: 'scatter',
        marker: { radius: 5 },
        name: `${this.data.treatedIncidents} Managed Incidents`,
        data: this.buildPointSeries(Math.min(this.data.treatedIncidents, 500), endTotalIncident, endTreatedIncident),
      },
      {
        turboThreshold: 1,
        type: 'scatter',
        marker: { radius: 5 },
        name: `${this.data.trueIncidents} True Positives`,
        data: this.buildPointSeries(Math.min(this.data.trueIncidents, 250), endTreatedIncident, this.endPointPosition),
      },
    ];
  }

  buildPointSeries(numberOfPoints: number, startFactor: number, endFactor: number): number[][] {
    const points: number[][] = [];
    for (let i = 0; i < numberOfPoints; i++) {
      const heightFactor = this.map(Math.random(), 0, 1, startFactor, endFactor);
      const y = this.lerp(0, this.height, heightFactor);

      const widthFactor = this.#computeWidth(heightFactor);
      const widthOffset = (this.width - widthFactor) / 2;

      const x = Math.random() * widthFactor + widthOffset;
      points.push([x, y]);
    }
    return points;
  }

  lerp(start: number, end: number, t: number): number {
    return start + (end - start) * t;
  }

  map(x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  #computeWidth(heightFactor: number) {
    // Make the input always in the 0..1 range. This is better for more complex functions
    heightFactor = this.map(heightFactor, this.startPointPosition, this.endPointPosition, 0, 1);

    // For now, we just change the width linearly
    return this.lerp(this.width, 0, heightFactor);
  }
}
