import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { graphconstants } from '../../graphconstants';
import { DataPoint, SecurityCaseActivity } from '../../chartModels';
import { Utilities } from 'src/app/shared/utilities';
import { Router } from '@angular/router';
import { Case } from 'src/app/shared/model/itsm';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { UserTheme } from 'src/app/shared/model/shared-items';

@Component({
  selector: 'app-tpvsfp-chart',
  templateUrl: './tpvsfp-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class TpvsfpChartComponent implements OnInit {
  @Input() width = '100%';
  @Input() height = '500px';
  @Input() data!: Case[];
  @Input() widgetFormat: boolean;
  @Input() theme: UserTheme;

  public caseMap: Map<string, SecurityCaseActivity>;
  public highcharts: typeof Highcharts = Highcharts;
  public loaded: boolean;

  private totalTP: number;
  private totalFP: number;
  private tpvfpDonutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));

  constructor(
    private router: Router,
    private widgetService: WidgetService) { }

  ngOnInit() {
    this.loaded = !!this.data;
    this.totalFP = 0;
    this.totalTP = 0;

    this.caseMap = new Map();
    this.data.forEach(c => {
      const cd = new Date(c.createdDate);
      const actualMonth = cd.getMonth() + 1;
      const dateStr = cd.getFullYear() + '-' + actualMonth;

      if (!this.caseMap.get(dateStr)){
        this.caseMap.set(dateStr, new SecurityCaseActivity(dateStr));
      }
      const caseM = this.caseMap.get(dateStr);
      if (Utilities.checkTruePositive(c.caseNumber, c.status, c.closureCode)){
        caseM.truePositives.push(c);
        this.totalTP++;
      } else if (Utilities.checkFalsePositive(c.status, c.closureCode)){
        caseM.falsePositives.push(c);
        this.totalFP++;
      }
    });
    this.caseMap = new Map([...this.caseMap.entries()].reverse());
    this.updateGraph();
  }

  updateGraph() {
    const dataItems = [];

    const pieSeries = new DataPoint();
    pieSeries.type = 'pie';
    pieSeries.innerSize = '50%';
    pieSeries.name = 'True Positive vs False Positive';

    const item1 = {
      name: 'True Positive',
      y: this.totalTP,
      indicator: 'TPO'
    };
    dataItems.push(item1);
    const item2 = {
      name: 'False Positive',
      y: this.totalFP,
      indicator: 'FPO'
    };
    dataItems.push(item2);

    // switch count to percentage
    dataItems.forEach(di => {
      di.y = +((di.y / (this.totalFP + this.totalTP)) * 100).toFixed(1);
    });
    pieSeries.data = dataItems;
    const series = [];
    series.push(pieSeries);
    this.tpvfpDonutChartOptions.plotOptions.series = {
      cursor: 'pointer',
      events: {
          click: (event) => {
              this.router.navigateByUrl('/support/dashboard/' + event.point.indicator);
          }
      }
    };
    this.tpvfpDonutChartOptions.series = series;
    this.tpvfpDonutChartOptions.title.text = '<strong>' + (this.totalFP + this.totalTP) + '<br/>cases</strong>';

    this.widgetService.updateWidgetStyle(this.tpvfpDonutChartOptions, this.theme)
  }

}
