import { NgModule } from "@angular/core";
import { ClickOutsideDirective } from "./clickOutside.directive";
import { DragAndDropDirective } from "./dragAndDrop.directive";

@NgModule({
  imports: [  ],
  declarations: [
    ClickOutsideDirective
  ],
  exports: [
    ClickOutsideDirective
  ],
})
export class AppDirectivesModule {}
