import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Utilities } from 'src/app/shared/utilities';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { Case, Milestone } from 'src/app/shared/model/itsm';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-reports-live-index',
  templateUrl: './reports-live-index.component.html',
  styleUrls: ['./reports-live-index.component.scss']
})
export class ReportsLiveIndexComponent implements OnInit {

  // search fields
  type: string;
  dateFrom: string;
  dateTo: string;

  ticketError: boolean;
  loading: boolean;

  loadingPDF: boolean;
  caseTypeCV: string[];
  milestoneType: Milestone[];

  tickets: Case[];

  incidents: Case[];
  changes: Case[];
  serviceReqs: Case[];
  problems: Case[];
  alerts: Case[];

  slaResolvedMet: number;
  slaResponseTotal: number;
  slaResponseMet: number;

  sev1Total: number;
  sev1Met: number;
  sev2Total: number;
  sev2Met: number;
  sev3Total: number;
  sev3Met: number;
  sev4Total: number;
  sev4Met: number;

  // data
  cases: any;
  casesCount: number;
  searchCriteria: SupportCaseSearchCriteria;
  searched: boolean;
  casesLoaded: boolean;

  form: FormGroup;
  public isMSCollapsed = false;

  get typesFormArray() {
    return this.form.controls.types as FormArray;
  }

  constructor(private itsmService: ItsmService,
              private alertService: AlertService,
              private httpService: HttpClient,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dateFrom = Utilities.get12MonthsAgo();
    this.dateTo = Utilities.getToday();
    this.loadCache();
    this.form = this.formBuilder.group({
      types: new FormArray([])
    });

  }

  /**
   * Cache the CV values for the dropdowns
   */
   loadCache(){
    Promise.all([
      this.itsmService.getCachedCV('type'),
      this.itsmService.getCachedMilestones()

    ])
    .then(res => {
      this.caseTypeCV = res[0];
      this.milestoneType = res[1];
      this.milestoneType.forEach(() => this.typesFormArray.push(new FormControl(false)));
      this.milestoneType.forEach(m => {
      });

    })
    .catch(err => {
      this.alertService.handlerError(err);
    });
  }



   /**
    * Utility method to load count of results as well as results themselves
    * Used for pagination
    */
    private caseSearch(): Promise<void> {
      return new Promise((resolve,reject) => {
        this.searchCriteria.count = true;
        const tempLimit = this.searchCriteria.limit;
        this.searchCriteria.limit = undefined;

        this.itsmService.caseSearch(this.searchCriteria)
          .then(res => {
            if(res){
              this.casesCount = res;
            }else{
              this.casesCount = 0;
            }
            this.searchCriteria.count = false;
            this.searchCriteria.limit = tempLimit;
            this.itsmService.caseSearch(this.searchCriteria)
              .then(res2 => {
                this.cases = res2;
                resolve();
              })
              .catch(err => {
                throw err;
              });
          })
          .catch(err => {
            reject();
            this.alertService.handlerError(err);
          });
      });
    }

  private addCheckboxes() {
      this.milestoneType.forEach(() => this.typesFormArray.push(new FormControl(false)));
  }


  mcCheckBox() {
    //Needed to collect milestonetype ids when checkbox is uses
    this.form.value.types
      .map((checked, i) => checked ? this.milestoneType[i].id : null)
      .filter(v => v !== null);
  }

  generate(){
    this.itsmService.getLiveReport(null).then(data => {
      this.cases = data.list;

      this.incidents = this.cases.filter(a => a.Case.Type === 'Incident');
      this.changes = this.cases.filter(a => a.Case.Type  === 'Change Request');
      this.serviceReqs = this.cases.filter(a => a.Case.Type  === 'Service Request');
      this.problems = this.cases.filter(a => a.Case.Type  === 'Problem');
      this.alerts = this.cases.filter(a => a.Case.Type  === 'Alert' );
    });

  }

  generatePDF(){
    this.loadingPDF = true;

    //TODO update with logged in customer company details
    this.itsmService.getLivePDFReport(null)
      .then(val => {
        const file = new Blob([val], {type: val.type});
        FileSaver.saveAs(file, 'OrangeCyberdefense-ServiceManagementReport-' + this.dateFrom + '.pdf');
        this.loadingPDF = false;
      })
      .catch(() => {
        this.loadingPDF = false;
      });
  }

}
