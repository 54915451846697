import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { DynamicDate } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { CaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { PaginatedList } from 'src/app/shared/model/cybersocxdr/paginatedList';
import { SlaGauge } from 'src/app/shared/model/cybersocxdr/slagauge';
import { CybersocXDRDashboardSearchCriteria } from '../../../shared/model/searchCriteria';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';
import { Tab } from '../../components/tabbar/tabbar.component';
import { handlePromises } from '../../utils';
import { XtendedHighchartService } from '../../xtended-highchart.service';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { XtendedSearchable } from '../xtended-page';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { HighChartsHistogramSerie } from 'src/app/shared/model/cybersocxdr/charts/common';

@Component({
  selector: 'app-xtended-sla-page',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss'],
})
export class XtendedSOCSLAComponent implements OnInit, XtendedSearchable {
  //page: 'notification' | 'investigation';
  tab: string;
  private contractChangeSubscription: Subscription;
  contract: Contract | null = null;

  tabs: Tab[] = [
    { titleKey: 'pages.cybersocxdr.sla.tabNotification', value: 'notification', url: '/xtended-soc/sla/notification' },
    {
      titleKey: 'pages.cybersocxdr.sla.tabInvestigation',
      value: 'investigation',
      url: '/xtended-soc/sla/investigation',
    },
  ];

  slaNotifGaugeData: SlaGauge;
  slaInvestGaugeData: SlaGauge;
  slaNotifStatusRateData: any;
  slaBreakClosureVerdictOverTimeNotification: any;
  slaBreakClosureVerdictOverTimeInvestigation: any;
  slaGlobalMttD: any;
  slaGlobalMttI: any;
  slaInvestRateData: any;
  mttdData: any;
  mttiData: any;
  brokenNotificationCases: CaseXDR[];
  brokenInvestigationCases: CaseXDR[];
  createdFrom: string;
  createdTo: string;
  severyValuesmttdHigh: number;
  severyValuesmttdCritical: number;
  severyValuesmttiHigh: number;
  severyValuesmttiCritical: number;
  investigationIncidentsCurrentPage: PaginatedList<CaseXDR>;
  notificationIncidentsCurrentPage: PaginatedList<CaseXDR>;
  flagCurrentPage: PaginatedList<CaseXDR>;

  constructor(
    private cybersocxdrService: CybersocxdrService,
    private globalFilters: XtendedFiltersService,
    private route: ActivatedRoute,
    private router: Router,
    private xtendedHighchartService: XtendedHighchartService
  ) {}

  async onSearch(searchCriteria: CybersocXDRDashboardSearchCriteria) {
    this.createdFrom = searchCriteria.createdFrom;
    this.createdTo = searchCriteria.createdTo;

    if (this.tab === 'investigation') {
      await this.searchInvestigation(searchCriteria);
      this.searchNotification(searchCriteria);
    } else {
      await this.searchNotification(searchCriteria);
      this.searchInvestigation(searchCriteria);
    }
  }

  /** Update all the info used for the notification SLA tab */
  async searchNotification(criteria: CybersocXDRDashboardSearchCriteria) {
    let breackVerdictN;
    let mttdTime;
    [
      this.slaNotifGaugeData,
      this.slaNotifStatusRateData,
      this.slaGlobalMttD,
      mttdTime,
      breackVerdictN,
      this.mttdData,
      this.brokenNotificationCases,
    ] = await handlePromises([
      this.cybersocxdrService.getSLANotifGauge(criteria),
      this.cybersocxdrService.getSLANotifStatusRate({
        ...criteria,
        createdFrom: DynamicDate.LAST_180D,
        createdTo: undefined,
      }),
      this.cybersocxdrService.getSLAGlobalMttd(criteria),
      this.cybersocxdrService.getMTTDTime(criteria),
      this.cybersocxdrService.getSlaBreakClosureVerdictOverTimeNotification(criteria),
      this.cybersocxdrService.getMTTDOverTime(criteria),
      this.cybersocxdrService.getCases({ ...criteria, slaNotificationStatusBroken: true, skip: 0, limit: 45 }),
    ]);

    this.slaBreakClosureVerdictOverTimeNotification = this.processVerdictData(breackVerdictN);

    const severityValues = mttdTime.reduce((acc, item) => {
      acc[item.severity] = item.value;
      return acc;
    }, {});

    this.severyValuesmttdHigh = severityValues.High ? severityValues.High : 0;
    this.severyValuesmttdCritical = severityValues.Critical ? severityValues.Critical : 0;
  }

  /** Update all the info used for the investigation SLA tab */
  async searchInvestigation(criteria: CybersocXDRDashboardSearchCriteria) {
    let breackVerdictI;
    let mttiTime;
    [
      this.slaInvestGaugeData,
      this.slaInvestRateData,
      this.slaGlobalMttI,
      mttiTime,
      breackVerdictI,
      this.mttiData,
      this.brokenInvestigationCases,
    ] = await handlePromises([
      this.cybersocxdrService.getSLAInvestGauge(criteria),
      this.cybersocxdrService.getSLAInvestStatusRate({
        ...criteria,
        createdFrom: DynamicDate.LAST_180D,
        createdTo: undefined,
      }),
      this.cybersocxdrService.getSLAGlobalMtti(criteria),
      this.cybersocxdrService.getMTTITime(criteria),
      this.cybersocxdrService.getSlaBreakClosureVerdictOverTimeInvestigation(criteria),
      this.cybersocxdrService.getMTTIOverTime(criteria),
      this.cybersocxdrService.getCases({ ...criteria, slaInvestigationStatusBroken: true, skip: 0, limit: 45 }),
    ]);

    this.slaBreakClosureVerdictOverTimeInvestigation = this.processVerdictData(breackVerdictI);

    const severityValues = mttiTime.reduce((acc, item) => {
      acc[item.severity] = item.value;
      return acc;
    }, {});

    this.severyValuesmttiHigh = severityValues.High ? severityValues.High : 0;
    this.severyValuesmttiCritical = severityValues.Critical ? severityValues.Critical : 0;
  }

  async ngOnInit() {
    this.tab = 'notification';
    const searchCriteria = this.globalFilters.searchCriteria;
    await this.loadInvestigationIncidentPage(0);
    await this.loadNotificationIncidentPage(0);

    this.contract = this.globalFilters.selectedContract;
    this.contractChangeSubscription = this.globalFilters.contractChangeEvent.subscribe((contract) => {
      this.contract = contract;
    });

    this.onSearch(searchCriteria);

    this.route.paramMap.subscribe((params: ParamMap) => {
      const tab = params.get('tab');
      if (['notification', 'investigation'].includes(tab)) this.tab = tab;
      else {
        this.router.navigate(['/xtended-soc', 'sla', 'notification']);
      }
    });
  }

  private processVerdictData(breackVerdict: Record<string, Record<string, number>>): Highcharts.SeriesColumnOptions[] {
    const verdictKeys = Array.from(new Set(Object.values(breackVerdict).flatMap(Object.keys)));

    const data: HighChartsHistogramSerie[] = verdictKeys.map((verdict) => ({
      name: verdict,
      data: Object.values(breackVerdict).map((objectVerdict, index) => [index, objectVerdict[verdict] || 0]),
    }));

    return this.xtendedHighchartService.barOvertimeChartSerie(data, XtendedFacet.CLOSURE_VERDICT);
  }

  public async loadInvestigationIncidentPage(page: number) {
    this.investigationIncidentsCurrentPage = await this.cybersocxdrService.getPaginatedCases({
      ...this.globalFilters.searchCriteria,
      limit: this.investigationIncidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
      slaInvestigationStatusBroken: true,
    });

    return this.investigationIncidentsCurrentPage;
  }

  public async loadNotificationIncidentPage(page: number) {
    this.notificationIncidentsCurrentPage = await this.cybersocxdrService.getPaginatedCases({
      ...this.globalFilters.searchCriteria,
      limit: this.investigationIncidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
      slaNotificationStatusBroken: true,
    });

    return this.notificationIncidentsCurrentPage;
  }

  async onFlag(incident: CaseXDR) {
    if (!incident.isFlagged) {
      await this.cybersocxdrService.flagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    } else {
      await this.cybersocxdrService.deleteFlagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    }
    this.loadPages();
  }

  async ngOnDestroy() {
    if (this.contractChangeSubscription) this.contractChangeSubscription.unsubscribe();
  }

  public async loadPages(reset: boolean = false) {
    if (reset) {
      if (this.tab === 'notification') {
        this.flagCurrentPage = await this.loadNotificationIncidentPage(0);
      } else {
        this.flagCurrentPage = await this.loadInvestigationIncidentPage(0);
      }
    }
    if (this.tab === 'notification') {
      this.flagCurrentPage = await this.loadNotificationIncidentPage(this.notificationIncidentsCurrentPage?.page ?? 0);
    } else {
      this.flagCurrentPage = await this.loadInvestigationIncidentPage(
        this.investigationIncidentsCurrentPage?.page ?? 0
      );
    }
  }
}
