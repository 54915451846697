<p *ngIf="loading">{{ 'pages.generic.loading' | translate }}</p>

<p *ngIf="!loading">{{ 'pages.updates.certTools.mfd.numberFilesAnalysed' | translate }} : {{search.pages.total}}</p>

<div class="row faketableheaderrow" *ngIf="!loading && search.records">
  <div class="col-xl-6 col-lg-6 col-md-9 col-sm-12 faketableheader click" style="text-align: left;" >{{ 'pages.generic.name' | translate }}
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 d-none d-md-block faketableheader click">{{ 'pages.generic.date' | translate }}
  </div>
  <div class="col-xl-1 col-lg-2 d-none d-lg-block faketableheader click">{{ 'pages.generic.type' | translate }}
  </div>
  <div class="col-xl-2 d-none d-xl-block faketableheader click">{{ 'pages.generic.size' | translate }}
  </div>
</div>

<div *ngIf="!loading && search.records">
  <div class="row faketablerow" *ngFor="let record of search.records" [routerLink]="['/updates/services/certtools/mfdresults/' + record.fileinfo.SHA256]" routerLinkActive="activeLink" >

    <div class="col-xl-6 col-lg-6 col-md-9 col-sm-12 faketablecell" style="text-align: left;">{{record.meta.filenames[0]}}</div>
    <div class="col-xl-2 col-lg-3 col-md-3 d-none d-md-block faketablecell">{{ record.fileinfo.dateAnalyseLast | date: 'mediumDate' }}</div>
    <div class="col-xl-1 col-lg-2 d-none d-lg-block faketablecell">{{record.fileinfo['mime-type']}}</div>
    <div class="col-xl-2 d-none d-xl-block faketablecell">{{ record.fileinfo.size | humanFileSize }}</div>
  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="!loading && search.records && search.records.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.support.dashboard.noResults' | translate }}
  </div>
</div>
