<div class="content">

  <!-- since last login-->
  <div class="card news">
    <ul class="p-0 m-0 news" style="width: 100%">
      <a class="click" (click)="loadSinceLastVisit()">
        <li class="mb-2">
          <span [ngClass]="context.isDarkTheme() ? 'lighter-yellow' : 'darker-yellow'">
            {{ sinceLastLogin }}
          </span> {{'pages.updates.vulnerabilities.sinceLastVisitPost' | translate }}
        </li>
      </a>
    </ul>
  </div>

  <!-- filters-->
  <div class="card">
    <div class="card-body">
      <div class="card-title">

        <!-- advanced search -->
        <div class="form-check form-switch custom-switch right me-3" data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.support.dashboard.enableAdSearchDesc' | translate }}">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="advanceSearch"
            [attr.aria-checked]="advSearch"
            [(ngModel)]="advSearch" />
          <label class="custom-control-label" for="advanceSearch">{{ 'pages.generic.advancedSearch' | translate
            }}
          </label>
        </div>

        <!-- saved searches -->
        <div class="form-check form-switch custom-switch right" data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.support.dashboard.enableSavedSearchDeas' | translate }}">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="hideSaveSearch"
            [attr.aria-checked]="hideSaveSearch"
            [(ngModel)]="hideSaveSearch" />
          <label class="custom-control-label" for="hideSaveSearch">{{
            'pages.generic.savedSearches' | translate
            }}</label>
        </div>

      </div>
      <form>
        <!--simple search -->
        <div class="row" data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.updates.vulnerabilities.vulnNumberSearchDesc' | translate }}">

          <!-- CVE -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
            title="{{ 'pages.updates.vulnerabilities.vulnNumberSearchDesc' | translate }}">
            <label for="cveInput">{{ 'pages.updates.vulnerabilities.fieldTitles.cve' | translate }} </label>
            <input type="text" class="form-control" id="cveInput" name="cve" placeholder="CVE-"
              [(ngModel)]="cve">
          </div>

          <!-- vendor -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="vendorInput">{{ 'pages.updates.vulnerabilities.fieldTitles.vendor' | translate }}</label>
            <input id="typeahead-basic" type="text" class="form-control" id="vendorInput" name="vendor"
              [editable]="false" [placeholder]="'pages.generic.typeahead' | translate" [(ngModel)]="vendor"
              [ngbTypeahead]="vendorTA" />
          </div>

          <!-- product -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="productInput">{{ 'pages.updates.vulnerabilities.fieldTitles.product' | translate }}</label>
            <input id="typeahead-basic" type="text" class="form-control" id="productInput" name="product"
              [editable]="false" [placeholder]="'pages.generic.typeahead' | translate" [(ngModel)]="product"
              [ngbTypeahead]="productTA" />
          </div>

          <!-- watch product button -->
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 mb-3 d-flex align-items-end">
            <button class="btn btn-primary" [disabled]="!product" (click)="watch(product, false);">Watch</button>
          </div>
        </div>

        <!--advanced search -->
        <div [ngbCollapse]="!advSearch" id="advSearch">
          <div class="row" data-toggle="tooltip" data-placement="top"
            title="{{ 'pages.updates.vulnerabilities.vulnFilterSearchDesc' | translate }}">

            <!-- description -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
              <label for="descriptionInput">{{ 'pages.updates.vulnerabilities.fieldTitles.description' | translate
                }}</label>
              <input type="text" class="form-control" id="descriptionInput" name="description"
                [(ngModel)]="description">
            </div>

            <!-- severity -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="custom-label" id="severitySelect">{{ 'pages.updates.vulnerabilities.fieldTitles.severity' | translate }}</div>
              <ng-multiselect-dropdown
                [settings]="dropdownSettings"
                [data]="severityData"
                [(ngModel)]="selectedSeverities"
                name="selectSeverity"
                aria-labelledby="severitySelect">
              </ng-multiselect-dropdown>
            </div>

            <!-- cpe -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
              <label for="cpeInput">{{ 'pages.updates.vulnerabilities.fieldTitles.cpe' | translate }}</label>
              <input type="text" class="form-control" id="cpeInput" name="cpe" [(ngModel)]="cpe">
            </div>

            <!-- date from to -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.updates.vulnerabilities.vulnDateSearchDesc' | translate }}">
              <div class="custom-label">
                {{ 'pages.generic.dateFromTo' | translate }}
                <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
              </div>
              
              <div class="row">
                <span class="visually-hidden" id="dateFromInput">{{ 'pages.generic.dateFrom' | translate }}</span>
                <div class="col-6" style="padding-right: 0.5rem; padding-left:0">
                  <input type="date" class="form-control" name="dateFrom" aria-labelledby="dateFromInput"
                    [(ngModel)]="dateFrom" (change)="validateDateRange()"
                    [ngClass]="{'is-invalid': !validDateRanges}" />
                </div>
                <span class="visually-hidden" id="dateToInput">{{ 'pages.generic.dateTo' | translate }}</span>
                <div class="col-6" style="padding-right: 0; padding-left:0.5rem">
                  <input type="date" class="form-control" name="dateTo" [(ngModel)]="dateTo" aria-labelledby="dateToInput"
                    (change)="validateDateRange()" [ngClass]="{'is-invalid': !validDateRanges}" />
                </div>
              </div>
            </div>

            <!-- sort -->
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-6 mb-3">
              <label for="sortSelect">{{ 'pages.generic.sort' | translate }}</label>
              <select class="form-select" style="float:left;" name="sort" id="sortSelect" [(ngModel)]="sort">
                <option></option>
                <option value="publishedDate">{{ 'pages.updates.vulnerabilities.fieldTitles.published' |
                  translate }}</option>
                <option value="lastModifiedDate">{{ 'pages.updates.vulnerabilities.fieldTitles.modified' |
                  translate }}</option>
                <option value="baseSeverity">{{ 'pages.updates.vulnerabilities.fieldTitles.severity' | translate
                  }}</option>
                <option value="baseScore">{{ 'pages.updates.vulnerabilities.fieldTitles.score' | translate }}
                </option>
                <option value="impactScore">{{ 'pages.updates.vulnerabilities.fieldTitles.impact' | translate }}
                </option>
                <option value="exploitabilityScore">{{
                  'pages.updates.vulnerabilities.fieldTitles.exploitability' | translate }}</option>
              </select>
            </div>
            <!-- sort ASC DESC-->
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-6 mb-3">
              <label for="sortOrder">{{ 'pages.generic.orderBy' | translate }}</label>
              <select class="form-select order-by" name="sortOrder" id="sortOrder"
                [(ngModel)]="sortOrder">
                <option></option>
                <option value="-1">DESC</option>
                <option value="1">ASC</option>
              </select>
            </div>
          </div>
        </div>
        <div class="ms-2">
          <button class="btn btn-primary" (click)="search();" [disabled]="!validDateRanges">{{ 'pages.generic.filter'
            | translate }}</button>
          <button class="btn btn-primary" (click)="reset();">{{ 'pages.generic.reset' | translate }}</button>
          <button class="btn btn-primary" (click)="openModal(exportContent);" [disabled]="!validDateRanges">{{
            'buttons.action.generateReport' |
            translate }}</button>
          <button class="btn btn-primary" (click)="openSearchModal(saveSearch)" *ngIf="searched" data-toggle="tooltip"
            data-placement="top" title="{{ 'pages.updates.vulnerabilities.vulnSaveDesc' | translate }}">{{
            'pages.generic.saveSearch' |
            translate }}</button>
        </div>
      </form>
    </div>
  </div>

  <!-- saved search -->
  <div class="pt-1" *ngIf="savedSearches && savedSearches.length > 0" [ngbCollapse]="!hideSaveSearch" id="savedSearch">
    <div class="card">
      <div class="card-body p-1">
        <span style="float:left" *ngFor="let search of savedSearches">
          <button class="btn btn-primary" (click)="loadSearch(search);">{{search.name}} <fa-icon
              [icon]="['fas', 'trash']" class="ps-2"
              (click)="deleteSearch($event, confirmDeleteSearch, search)"></fa-icon></button>
        </span>
      </div>
    </div>
  </div>

  <!-- vulns list -->
  <div class="rowfiller" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="loadNextValues()">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === vulnsPage }"
          (click)="page = vulnsPage; loadLatestVulns();">{{ 'pages.updates.vulnerabilities.vulns' | translate }}
          <span class="tag no-border text-bg-primary ms-2" style="padding:0rem 0.5rem;"
            *ngIf="vulnsCount > 0">{{vulnsCount}}</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === watchedPage }" (click)="loadWatched(false);"
          data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.updates.vulnerabilities.vulnWatchButtonDesc' | translate }}">{{
          'pages.updates.vulnerabilities.watched' | translate }}
          <span class="tag no-border text-bg-primary ms-2" style="padding:0rem 0.5rem;">{{watchCount}}</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === flaggedPage }" (click)="loadFlagged(false);"
          data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.updates.vulnerabilities.vulnFlagDesc' | translate }}">{{ 'pages.generic.flagged' |
          translate
          }}
          <span class="tag no-border text-bg-primary ms-2" style="padding:0rem 0.5rem;">
            {{flagRefs.length}}</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === analyticsPage }"
          (click)="page = analyticsPage;  loadLatestVulns(); loadAnalytics();">{{ 'pages.generic.analytics' |
          translate
          }}</a>
      </li>
    </ul>
    <div class="tools" *ngIf="page !== analyticsPage">
      <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1 click" (click)="changeViewType('list')"></fa-icon>
      <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1 click" (click)="changeViewType('grid')"></fa-icon>
    </div>
    <div class="row p-5 justify-content-center p-5" *ngIf="!analyticsDataLoaded && page === analyticsPage">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
    <div class="pt-3" *ngIf="analyticsDataLoaded && page === analyticsPage">
      <h2> {{ 'pages.generic.thisWeek' | translate }} </h2>
      <div class="row p-1">
        <div class="col-12">
          <highcharts-chart [Highcharts]="highcharts" [options]="wordChartOptions" [(update)]="updateWordChart"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
      <h2> {{ 'pages.generic.last12months' | translate }} </h2>
      <div class="row p-1 status-table">
        <div class="col-xl-4 col-lg-4 col-sm-12 item first-col-status">
          <h4> {{ 'pages.generic.atAGlance' | translate }} </h4>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{lastUpdated | date: 'dd/MM/yyyy'}}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.generic.lastUpdated' | translate }}</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">{{totalVulnerabilities}}</h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.vulnerabilities.totalVulns' | translate }}
              </h6>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{totalVendors}}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.vulnerabilities.totalVends' | translate }}
              </h6>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{totalProducts}}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.vulnerabilities.totalProds' | translate }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <highcharts-chart [Highcharts]="highcharts" [options]="urgPieChartOptions" [(update)]="updateUrgencyPie"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12" *ngIf="loadStackedBarSeverity">
          <highcharts-chart [Highcharts]="highcharts" [options]="stackedBarSeverityOptions"
            [(update)]="updateStackedBarSeverity"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
    </div>
    <div class="card-body p-t-1 p-3" *ngIf="page === watchedPage">
      <span *ngFor="let prod of watchedProducts">
        <button class="btn btn-primary">{{prod}}<fa-icon [icon]="['fas', 'trash']" class="ps-2"
            (click)="watch(prod, true);"></fa-icon></button>
      </span>
    </div>
    <div class="card-body p-t-1 p-0"
      *ngIf="userPreferenceService.userPreferences?.vulnViewType === 'list' && (page === vulnsPage || page === flaggedPage || page === watchedPage)">
      <app-vuln-list [cves]="vulns" [flagRefs]="flagRefs" 
      [flaggingRef]="flaggingRef" [loaded]="vulnsLoaded"
        (flagEmitter)="flag($event);" (sortEmitter)="sortCVEs($event);"></app-vuln-list>
    </div>
    <div class="card-body p-t-1 p-0"
      *ngIf="userPreferenceService.userPreferences?.vulnViewType === 'grid' && (page === vulnsPage || page === flaggedPage || page === watchedPage)">
      <app-vuln-grid [cves]="vulns" [flagRefs]="flagRefs" 
      [flaggingRef]="flaggingRef" [loaded]="vulnsLoaded"
        (flagEmitter)="flag($event);"></app-vuln-grid>
    </div>
    <div class="row justify-content-center p-5"
      *ngIf="scrollLoading && (page === vulnsPage || page === flaggedPage || page === watchedPage)">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
  </div>
</div>

<!-- save search template -->
<ng-template #saveSearch let-modal>
  <form>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ 'modals.saveFilter.title' | translate }}</h4>
      <button type="button" class="cancel" aria-label="cancel" (click)="modal.dismiss('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12">
        <label for="reference">{{ 'modals.saveFilter.filterName' | translate }}</label>
        <input type="text" class="form-control" name="searchName" aria-describedby="searchName"
          [(ngModel)]="searchName">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" [disabled]="!searchName" (click)="modal.close('save')">{{
        'pages.generic.save' | translate }}</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('close')">{{ 'pages.generic.close' |
        translate }}</button>
    </div>
  </form>
</ng-template>

<!-- confirm delete search template -->
<ng-template #confirmDeleteSearch let-modal>
  <form>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ 'pages.generic.deleteSearchModal' | translate }}</h4>
      <button type="button" class="cancel" aria-label="cancel" (click)="modal.dismiss('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{ 'pages.generic.deleteSearchConfirm' | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('delete')">{{ 'pages.generic.confirm' |
        translate }}</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('close')">{{ 'pages.generic.close' |
        translate }}</button>
    </div>
  </form>
</ng-template>

<!-- export report template -->
<ng-template #exportContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.export.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label for="contact">{{ 'modals.export.reportName' | translate }}</label>
      <input type="text" class="form-control" name="PDFName" aria-describedby="PDFName" [(ngModel)]="PDFName">
    </div>
    <div class="row">
      <div class="col-6">
        <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="reportOption">
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select>
      </div>
      <div class="col-6">
        <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="pdfAccessLevel">
          <option title="{{ 'pages.generic.ACCESS_INTERNAL_REPORTS' | translate }}" value="0">{{
            'pages.generic.internal' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_CONFIDENTIAL_REPORTS' | translate }}" value="1">{{
            'pages.generic.confidential' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_RESTRICTED_REPORTS' | translate }}" value="2">{{
            'pages.generic.restricted' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate
      }}</button>
  </div>
</ng-template>