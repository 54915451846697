import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatHtml' })
export class FormatHTML implements PipeTransform {

  /** pipe text into Html */
  transform(value: string) {
    if(value){
      value = value.replace(/\r?\n/g, '<br/>');
    }
    return value;
  }

}
