<!-- To avoid adding the css reference to each child component, force the style here, inline -->
<div *ngIf="hasNoData" class="d-flex flex-column align-items-center justify-content-center" style="height: 400px;">
    <!-- Style copied from highcharts style data -->
    <h4 style="font-size: 16px; font-family: Trebuchet MS, Verdana, sans-serif; color: rgb(0, 0, 0); fill: rgb(0, 0, 0);"> {{title}} </h4>
    <img  style="height: 300px; padding: 1.8rem;"  alt="{{ 'pages.cybersocxdr.dashboard.noResults' | translate }}" src="/assets/images/icons/no_data.svg" class="no-data">
    <p> {{ 'pages.cybersocxdr.dashboard.noResults' | translate }} </p>
</div>
<div *ngIf="!hasNoData" id="{{internalRenderingId}}"></div>



