import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountOvertime } from 'src/app/shared/model/cybersocxdr/countOvertime';
import { CaseXDR, ExtendedCaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { Utilities } from 'src/app/shared/utilities';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';

@Component({
  selector: 'app-xtended-incident-detail-page',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss'],
})
export class XtendedSOCIncidentDetailsComponent implements OnInit {
  xdrCase: ExtendedCaseXDR | undefined;
  similarXdrCases: Array<CaseXDR> | undefined;
  similarCaseCountOvertime: Array<CountOvertime> | undefined;
  categoryCountOvertime: Array<CountOvertime> | undefined;
  currentDate: string;
  oldDate: string;

  similarHighchartsData: Array<Highcharts.SeriesOptionsType> | undefined = undefined;
  categoryHighchartsData: Array<Highcharts.SeriesOptionsType> | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private cybersocxdrService: CybersocxdrService,
    private xtendedFilterService: XtendedFiltersService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.init(params.incidentId);
    });
  }

  async init(id: number) {
    this.currentDate = Utilities.getToday();
    this.oldDate = Utilities.get6MonthsAgo();

    const contract = this.xtendedFilterService.selectedContract;
    this.xdrCase = await this.cybersocxdrService.getCase(
      id,
      contract?.incidentDataSource ?? IncidentDataSource.ELASTIC
    );

    this.similarXdrCases = await this.cybersocxdrService.getSimilarCases(id);
    this.similarCaseCountOvertime = await this.cybersocxdrService.getSimilarCasesCountOvertime(id);
    this.categoryCountOvertime = await this.cybersocxdrService.getCategoryCountOvertime(id);

    this.similarHighchartsData = await this.parseCountOvertimeToHighchartsData(
      this.similarCaseCountOvertime,
      await firstValueFrom(this.translate.get('pages.cybersocxdr.charts.similarOvertime'))
    );
    this.categoryHighchartsData = await this.parseCountOvertimeToHighchartsData(
      this.categoryCountOvertime,
      this.xdrCase.category
    );
  }

  async parseCountOvertimeToHighchartsData(
    data: Array<CountOvertime> | undefined,
    columnName: string
  ): Promise<Array<Highcharts.SeriesOptionsType> | undefined> {
    if (!data) {
      return undefined;
    }

    return [
      {
        type: 'column',
        name: columnName,
        data: data.map((caseCount) => [caseCount.date, caseCount.count]),
      },
    ];
  }

  /** Transform the time into a readable human form (minutes and seconds) */
  parseTimeLeft(timeLeft: number): string {
    return `${timeLeft < 0 ? '-' : ''}${new Date(Math.abs(timeLeft * 1000)).toISOString().substring(11, 16)}`;
  }
}
