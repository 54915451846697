import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'signalify'})
export class SignalPipe implements PipeTransform {
  transform(link: string): string {
    return this.signalify(link);
  }

  private signalify(plainText): string{
    let replacedText;
    let replacePattern1;

    replacePattern1 = /(SIG-\d*)/gim;
    if(plainText){
      replacedText = plainText.replace(replacePattern1, '<a href="/updates/worldwatch/viewSignal/$1" routerLinkActive="activeLink" target="_ref">$1</a>');
    }

    return replacedText;
   }
}
