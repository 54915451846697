@if(loaded) {
<div>
    <!-- filters -->
    <div class="card filter">
        <div class="body">
            <!-- search -->
            <div class="input-group">
                <label for="searchInput">Search</label>
                <input type="text" class="form-control" placeholder="Search" id="searchInput"
                    [(ngModel)]="feedbackFilter.search">
            </div>

            <!-- page -->
            <div class="input-group">
                <div class="custom-label" id="pageSelect">Page</div>
                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="pageType"
                    [(ngModel)]="feedbackFilter.pages" name="typeFilter" aria-labelledby="pageSelect">
                </ng-multiselect-dropdown>
            </div>

            <!-- category -->
            <div class="input-group">
                <div class="custom-label" id="categorySelect">Category</div>
                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="categoryTypes"
                    [(ngModel)]="feedbackFilter.categories" name="typeFilter" aria-labelledby="categorySelect">
                </ng-multiselect-dropdown>
            </div>
        </div>
    </div>


    <nav class="mt-3">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link" [ngClass]="{'active': mode === 'open'}" id="nav-open-feedbacks"
                data-bs-toggle="tab" data-bs-target="#nav-open" type="button" role="tab" aria-controls="nav-open"
                aria-selected="true" (click)="changeMode('open')">Open</button>
            <button class="nav-link" [ngClass]="{'active': mode === 'archived'}" id="nav-archive-feedbacks"
                data-bs-toggle="tab" data-bs-target="#nav-archived" type="button" role="tab"
                aria-controls="nav-archived" aria-selected="false" (click)="changeMode('archived')">Archived</button>
        </div>
    </nav>

    <div class="grid">
        <div *ngFor="let feedback of feedbacks | feedbackPipe:feedbackFilter" class="card">
            <div class="content highlight" (click)="openFeedback(feedback)">
                <div class="header">
                    <span class="title">{{feedback.title}}</span>
                    <span class="created">{{feedback.created | date:'short'}}</span>
                </div>
                <div class="content">
                    <span class="metadata">{{feedback.metadata}}</span>
                    <span class="id"> by {{feedback.login}}</span>
                </div>
            </div>
            <div class="actions">
                <div class="highlight">
                    <fa-icon [icon]="['fas', 'box-archive']" size="lg" data-toggle="tooltip" data-placement="top"
                        [title]="actionlabel" (click)="updateFeedbackState(feedback)"></fa-icon>
                </div>
                <div class="highlight">
                    <fa-icon [icon]="['fas', 'trash']" size="lg" data-placement="top"
                        title="{{ 'buttons.action.delete' | translate }}"
                        (click)="deleteFeedback(feedback._id)"></fa-icon>
                </div>
            </div>

        </div>
    </div>
</div>

} @else {
<div class="row justify-content-center p-5">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>
}