import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { ReportPdfAccessLevel } from 'src/app/shared/model/files';
import { Case } from 'src/app/shared/model/itsm';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { Utilities } from 'src/app/shared/utilities';


@Component({
  selector: 'app-report-live-benchmarking',
  templateUrl: './report-live-benchmarking.component.html',
  styleUrls: ['./report-live-benchmarking.component.scss'],
})
export class ReportLiveBenchmarkingComponent implements OnInit {
  // search fields
  type: string;
  currentPeriod: 'last30days' | 'lastFullCalendarMonth' = 'last30days';
  comparisonPeriod: 'previous30days' | 'previousFullCalendarMonth' =
    'previous30days';

  loading: boolean;

  // data
  casesCount: number;
  searchCriteria: SupportCaseSearchCriteria;
  currentCases: Case[];
  previousCases: Case[];
  monthCases: Case[];
  currentName: string;
  previousName: string;
  monthName: string;

  //Modal
  closeResult: string;
  PDFName = '';
  reportOption = 'xlsx';
  pdfAccessLevel = '0';
  generationReportLoading = false;

  //navigation
  page = 'ibp';
  displayIncidentChart = false;

  constructor(
    private itsmService: ItsmService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private userActivity: UserActivityService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userActivity.logActivity(TYPES.reports, PAGES.securityIncidentsBenchmarking, ACTIONS.visit);
  }

  generate() {
    this.loading = true;

    //For current period
    this.searchCriteria = new SupportCaseSearchCriteria();
    if (this.currentPeriod === 'last30days') {
      this.searchCriteria.createdFrom = Utilities.getTodayMinusDays(30);
      this.searchCriteria.createdTo = Utilities.getToday();
    } else {
      // this.currentPeriod === "lastFullCalendarMonth"
      this.searchCriteria.createdFrom = Utilities.getFirstOfLastMonth();
      this.searchCriteria.createdTo = Utilities.getLastOfLastMonth();
    }
    this.searchCriteria.securityAnaltyics = true;
    this.searchCriteria.limit = null;

    //For 3 month Data
    const threeMonth = new SupportCaseSearchCriteria();
    threeMonth.createdFrom = Utilities.get3MonthsAgo();
    threeMonth.createdTo = Utilities.getToday();
    threeMonth.securityAnaltyics = true;
    threeMonth.limit = null;

    // For comparison period
    const comparisonPeriod = new SupportCaseSearchCriteria();
    if (this.comparisonPeriod === 'previous30days') {
      comparisonPeriod.createdFrom = Utilities.getTodayMinusDays(60);
      comparisonPeriod.createdTo = Utilities.getTodayMinusDays(31);
    } else {
      comparisonPeriod.createdFrom = Utilities.getPreviousMonthStart();
      comparisonPeriod.createdTo = Utilities.getPreviousMonthLast();
    }
    comparisonPeriod.securityAnaltyics = true;
    comparisonPeriod.limit = null;

    Promise.all([
      this.itsmService.getLiveReport(this.searchCriteria),
      this.itsmService.getLiveReport(comparisonPeriod),
      this.itsmService.getLiveReport(threeMonth),
    ])
      .then((responses) => {
        //setting data
        this.currentCases = responses[0];
        this.previousCases = responses[1];
        this.monthCases = responses[2];

        this.loading = false;
        this.displayIncidentChart = true;
      })
      .catch((err) => {
        this.alertService.handlerError(err);
        this.loading = false;
      });
  }

  exportReport(content) {
    this.openModal(content);
  }

  openModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

          if (result == 'save') {
            this.generateXlsxReport();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  generateXlsxReport() {
    this.generationReportLoading = true;
    this.searchCriteria = new SupportCaseSearchCriteria();

    this.searchCriteria.count = false;
    this.searchCriteria.limit = undefined;
    this.searchCriteria.export = true;
    this.searchCriteria.pdfName =
      this.PDFName +
      ' - ' +
      constants.accessLevels[this.pdfAccessLevel] +
      '.' +
      this.reportOption;
    this.searchCriteria.pdfAccessLevel = this.pdfAccessLevel;
    this.searchCriteria.benchmarking = true;
    this.searchCriteria.currentPeriod = this.currentPeriod;
    this.searchCriteria.comparisonPeriod = this.comparisonPeriod;
    this.searchCriteria.securityAnaltyics = true;

    this.itsmService
      .caseSearch(this.searchCriteria)
      .then((res) => {
        this.alertService.addSuccess(this.translateService.instant('pages.generic.reportGenerated'));
        this.generationReportLoading = false;
        this.userActivity.logActivity(TYPES.reports, PAGES.securityIncidentsBenchmarking, ACTIONS.generateReport);
        const fullName =
          `${Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.searchCriteria.pdfAccessLevel])}/${this.searchCriteria.pdfName}`;
        this.notificationService.sendNotification(
          NotificationCategory.generateReport,
          {title: fullName, content: fullName},
          NotificationAudience.admins
        );
      })
      .catch((err) => {
        this.generationReportLoading = false;
        this.alertService.handlerError(err);
      });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
