import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { pieChartOption } from './graphconstants';
import { ContextService } from 'src/app/shared/services/context-service';
import { WidgetService } from 'src/app/shared/services/widget.service';

@Component({
  selector: 'app-mfd-results',
  templateUrl: './mfd-results.component.html',
  styleUrls: ['./mfd-results.component.scss']
})
export class MfdResultsComponent implements OnInit {

  page: 'details' | 'engines' | 'links' = 'engines';

  pieChartOptions = JSON.parse(JSON.stringify(pieChartOption));
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = true;
  updateDonutFlag = true;

  allEnginesNames = ['avira', 'bitdefender', 'clamav', 'comodo', 'drweb', 'eset', 'fsecure', 'kaspersky', 'mdatp', 'mfetp', 'sophos', 'trend', 'yara', 'yara_45283'];
  enginesScan: {name: string; version: string; analysisDate: Date; updateDate: string; error?: string; virus: string[]}[];
  mfdLinks: string[] = [];
  mfdBasicProperties: {
    md5: string;
    sha1: string;
    sha256: string;
    ssdeep: string;
    mimeType: string;
    mimeEncoding: string;
    fileSize: number;
  } | null;
  mfdHistory: {
    firstSubmission: Date;
    lastSubmission: Date;
    lastAnalysis: Date;
  } | null;
  mfdNames: string[] | null;
  mfdExifTools: {name: string; value: string}[] | null;
  mfdTrid: string[] | null;

  scanSuccess = true;
  signaturesClean: number;
  signaturesInfected: number;

  hash: string;
  scanNotFound: boolean;

  constructor(
    private mfdService: MfdService,
    private route: ActivatedRoute,
    private router: Router,
    private userActivityService: UserActivityService,
    public contextService: ContextService,
    private widgetService: WidgetService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hash = params.uploadId;
      const record = this.mfdService.getLocalRecord(this.hash);
      if( record == null) {
        this.scanNotFound = true;
      }
      else {
        this.mfdLinks= record?.link;
        this.mfdBasicProperties = {
          md5: record.fileinfo.MD5,
          sha1: record.fileinfo.SHA1,
          sha256: record.fileinfo.SHA256,
          ssdeep: record.fileinfo.ssdeep,
          mimeType: record.fileinfo['mime-type'],
          mimeEncoding: record.fileinfo['mime-encoding'],
          fileSize: record.fileinfo.size,
        };
        this.mfdHistory = {
          firstSubmission : new Date(record.meta.dateSubmissionFirst),
          lastSubmission : new Date(record.meta.dateSubmissionLast),
          lastAnalysis : new Date(record.meta.dateAnalyseLast),
        };
        this.mfdExifTools= record.fileinfo.exifTools;
        this.mfdNames= record.meta.filenames;
        this.mfdTrid = record.fileinfo.trid;
        this.enginesScan = [];
        Object.keys(record).forEach(key => {
          if(this.allEnginesNames.includes(key)) {
            const engine = record[key];
            this.enginesScan.push({
              name: key,
              version : engine.engine,
              updateDate : engine.update,
              analysisDate : new Date(engine.dateAnalyseLast),
              error: engine.error ? 'Trojan.AndroidOS.1234' : null,
              virus: engine.virus
            });
          }
        });

        // result chart
        this.signaturesInfected = this.enginesScan?.filter(s => s.virus.length > 0).length ?? 0;
        this.signaturesClean = this.enginesScan?.filter(s => s.virus.length === 0).length ?? 0;
        const pieSeries: any = {};
        pieSeries.innerSize = '75%';
        pieSeries.colorByPoint = true;
        pieSeries.data = [
          {name : 'red', y:this.signaturesInfected, color: 'red'},
          {name : 'grey', y:this.signaturesClean, color : this.signaturesInfected > 0 ? 'grey' : 'green'},
        ];
        this.pieChartOptions.series[0] = pieSeries;
        this.pieChartOptions.title.text = `${this.signaturesInfected}/${Math.max(this.signaturesInfected+this.signaturesClean,1)}`;
        this.pieChartOptions.subtitle.text = this.signaturesInfected > 0 ? 'INFECTED' : 'CLEAN';

        this.widgetService.updateWidgetStyle(this.pieChartOptions, null);
      }
    });

    this.userActivityService.logActivity(TYPES.services, PAGES.mfdResult, ACTIONS.visit);
  }

  /** trigger a rescan of the file */
  rescan() {}

  /** reload the page */
  reload(id: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    this.router.navigateByUrl(`/updates/services/certtools/mfdresults/${id}`);
    this.ngOnInit();
  }

  /** check if a date is well defined */
  isDateDefined(d: Date | number | object) {
    return d && `"${String(d)}"` !== 'Invalid Date';
  }

}
