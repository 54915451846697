/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileAccessService } from 'src/app/shared/services/fileaccess.service';
import { GCPFile } from '../../shared/model/files';
import FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss']
})
export class IntelligenceComponent implements OnInit {

  filesList: GCPFile[];
  fileNames: string[] = [];
  fileStructure: {};

  currentPath: string[];
  viewedFileStructure: {};
  loading: boolean;
  months = ['January','February','March','April','May', 'June','July','August','September','October','November','December'];

  private directory = 'sec-intel';
  private extension = '.pdf';

  constructor(
    private fileAccessService: FileAccessService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.fileAccessService.getFilesByFolder('')
      .then(res => {
        this.filesList = res;
      })
      .then(() => {
        // sort files by recently created
        this.filesList.sort((f1, f2) => (f1.created > f2.created) ? -1 : 1);
      })
      .then(() => {
        //create array of file names
        this.filesList.forEach(a => a.title !== '' ? this.fileNames.push(a.title) : {});
      })
      .then(() => {
        // create folder heirarchy for display
        const fa = this.fileNames;
        const obj = {};
        fa.forEach(function(path) {
          if(!path.endsWith('/')){
            const _ = path.split('/').reduce(function(r, e) {
              return r[e] || (r[e] = {});
            }, obj);
          }
        });
        this.fileStructure = obj;
        this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
        this.loading = false;
      })
      .catch(err => {
        this.handlerError(err);
      });
  }

  convertFileName(filename: string) {
    const year = filename.substring(0,4);
    let month = filename.substring(4,6);
    if(month.startsWith('0')){
      month = month.substring(1);
    }
    return this.months[Number(month)-1] + ' ' + year + this.extension;
  }

  downloadFile(fileName: string): void {
    const ff = this.filesList.filter(f => f.title = fileName)[0];

    if(ff){
      this.fileAccessService.downloadFileByDirectory(fileName, this.directory)
        .subscribe(val => {
          const file = new Blob([val], {type: ff.contentType});
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(file);
          }else {
            FileSaver.saveAs(file, fileName.substring(fileName.lastIndexOf('/')+1));
          }
      });
    }else{
      this.alertService.handlerError(this.translateService.instant('pages.generic.downloadError'));
    }
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
   private handlerError(err){
    this.alertService.handlerError(err);
  }

}
