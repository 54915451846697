import { Component, OnInit } from '@angular/core';
import { RssService } from 'src/app/shared/services/rss.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { RssFeedEntry } from '../../../shared/model/rssFeedEntry';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blogs',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})

export class BlogComponent implements OnInit {
  loading: boolean;
  feedData: RssFeedEntry[];
  postType = 'orange_blog';
  public errorMessage: string;

  constructor(
    private userprefService: UserPreferenceService,
    private rssService: RssService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.errorMessage = undefined;
    let region;
    if (this.userprefService.userPreferences && this.userprefService.region) {
      region = this.userprefService.region;
    } else {
      region = 'global';
    }
    this.rssService.rss(region, this.postType)
      .then(res2 => {
        this.feedData = res2 as RssFeedEntry[];
      })
      .catch(() => {
        const origin = this.translateService.instant('menu.updates.rss.blog');
        this.errorMessage = this.translateService.instant('pages.rss.dashboard.rssIssue', { rssItem: origin });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
