<div>
  <form class="question" [formGroup]="surveyForm">
    <div class="select-answer" [formArrayName]="question.title">
      <div class="form-check" [formGroupName]="i" *ngFor="let item of surveyForm.get(question.title)['controls']; let i = index">
        <input class="form-check-input" type="checkbox" formControlName="checked">
        <label class="form-check-label" ngbTooltip={{question.answers[i]}} openDelay="1500"> {{question.answers[i]}}
        </label>
      </div>
    </div>
  </form>
</div>