<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-12">
      <div
        class="p-4"
        [ngClass]="contextService.isDarkTheme() ? 'text-white' : 'text-dark'"
      >
        <h4 class="mb-4">{{ faq.question | translate }}</h4>
        <p [innerHTML]="faq.answer | translate"></p>
      </div>
    </div>
  </div>
</div>
