import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { CybersocxdrService } from '../shared/services/xtendedsoc/cybersocxdr.service';

@Injectable({
  providedIn: 'root',
})
export class CybersocxdrGuard implements CanActivate {
  constructor(public authService: UserAuthService, private socService: CybersocxdrService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return (await this.isAuthenticated()) || this.router.parseUrl('/not-authorized');
  }

  async isAuthenticated(): Promise<boolean> {
    const userClaims = await this.authService.loadUserDetails();
    if (!userClaims) {
      return false;
    } // User not authenticated

    const groups = userClaims.groups as Array<string>;
    for (const group of groups) {
      if (!group.startsWith('COMPROLE_')) {
        continue;
      }
      if (group.split('_')[2] !== 'MicroSOCXDR') {
        continue;
      }
      if (await this.socService.isMicrosocXDRosmClient()) {
        return true;
      }
    }

    return false;
  }
}
