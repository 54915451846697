import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-order-answer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-order-answer.component.html',
  styleUrl: './list-order-answer.component.scss'
})
export class ListOrderAnswerComponent {

  @Input() data: any[];
  public widgetData:[];

}
