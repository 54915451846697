import { Component, OnInit } from '@angular/core';
import { PortailReleaseNoteService } from 'src/app/shared/services/portailReleaseNote.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PatchNoteDTO, ReleaseNoteDTO, TypePatchNoteDTO } from '../../../shared/model/cybersocxdr/portailReleaseNote';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-portail-release-note',
  templateUrl: './portail-release-note.component.html',
  styleUrls: ['./portail-release-note.component.scss'],
})
export class PortailReleaseNoteComponent implements OnInit {
  releaseNotes: ReleaseNoteDTO[] = [];
  isLoading: boolean = true;
  showPatchNotes: { [key: number]: boolean } = {};

  focusedReleaseId: number | null = null;

  constructor(
    private portailReleaseNoteService: PortailReleaseNoteService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      const releaseId = parseInt(params['id'], 10);
      if (!isNaN(releaseId)) {
        this.showPatchNotes = {
          [releaseId]: true,
        };
        this.focusedReleaseId = releaseId;

        // Focus on the accordion element after a short delay to ensure DOM is ready
        setTimeout(() => {
          const element = document.getElementById(`releaseNotesAccordion${this.focusedReleaseId}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 100);
      }
    });
    this.loadReleaseNotes();
  }

  loadReleaseNotes(): void {
    this.isLoading = true;
    this.portailReleaseNoteService.getReleaseNotes().subscribe({
      next: (data: ReleaseNoteDTO[]) => {
        this.releaseNotes = data;
        if (this.releaseNotes.length > 0 && Object.keys(this.showPatchNotes).length === 0) {
          this.showPatchNotes[this.releaseNotes[0].id] = true;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.handlerError(error.message);
        this.isLoading = false;
      },
    });
  }

  togglePatchNotes(releaseId: number): void {
    this.showPatchNotes[releaseId] = !this.showPatchNotes[releaseId];
  }

  getTypesOfPatchNotes(release: ReleaseNoteDTO): TypePatchNoteDTO[] {
    return Array.from(new Set(release.patchNotes.map((patch) => patch.type.id))).map(
      (id) => release.patchNotes.find((patch) => patch.type.id === id).type
    );
  }

  getPatchNotesByType(releaseNote: ReleaseNoteDTO, typeId: number): PatchNoteDTO[] {
    return releaseNote.patchNotes.filter((patchNote) => patchNote.type.id === typeId);
  }
}
