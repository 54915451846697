import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { XtendedNotificationDTO } from '../../model/cybersocxdr/xtended-notification';

const ROOT_URL = environment.endpoints.cybersocxdr + 'admin/portail-notification';

@Injectable()
export class XtendedNotificationService {
  constructor(private http: HttpClient) {}

  async getActiveNotifications(): Promise<XtendedNotificationDTO[]> {
    try {
      return await firstValueFrom(this.http.get<XtendedNotificationDTO[]>(`${ROOT_URL}/active`));
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
