<!-- if user activated, roles are available-->
<div *ngIf='!loading'>
    <button class="btn btn-primary float-end" (click)="back();">{{ 'pages.viewTenant.listUsers' | translate
        }}</button>
    <div *ngIf="activatedUserApps.length > 0">

        <!-- profiles -->
        <div class="mt-4 defined-roles">
            <h4 for="query">{{ 'pages.viewTenant.preDefinedRoles' | translate }}</h4>
            <div class="d-flex">
                <select class="form-select" aria-label="profile selection" [(ngModel)]="selectedProfile"
                    (change)="selectProfile()">
                    <option [ngValue]="customProfile">{{customProfile.label}}</option>
                    <option *ngFor="let profile of gpRoles.profiles | keyvalue" [ngValue]="profile.value">
                        {{profile.value.label}}</option>
                </select>

                <div class="form-check form-switch ms-5">
                    <label class="form-check-label pt-0" for="flexSwitchCheckDefault">Show defined profiles</label>
                    <input class="form-check-input ms-4" type="checkbox" role="switch" data-bs-toggle="collapse"
                        data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                        id="profilesSwitch">
                </div>
            </div>
        </div>

        <!-- profile table -->
        <div class="collapse mt-3" id="collapseExample">
            <div class="card card-body">
                <table class="table profiles table-striped-columns table-responsive">
                    <caption>{{ 'pages.viewTenant.profiles' | translate }}</caption>
                    <thead>
                        <tr>
                            <th>Roles</th>
                            <th class="text-center" *ngFor="let profile of gpRoles.profiles | keyvalue"
                                style="width: 9%;">
                                {{profile.value.label}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let role of roleLabels">
                            <td>{{role.label}}</td>
                            <td *ngFor="let profile of gpRoles.profiles | keyvalue">
                                <div class="icon">
                                    <fa-icon *ngIf="profile.value.roles.includes(role.name)" [icon]="['fas', 'check']"
                                        [styles]="{'color': 'green'}"></fa-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- availables roles -->
        <div class="mt-4 mb-3">
            <div class="roles-header">
                <h4 for="query">{{ 'pages.viewTenant.availRoles' | translate }}</h4>
            </div>

            <div *ngFor="let cat of getCategories()">
                <table *ngIf="categoryHasRoles(cat)" class="table roles table-hover me-7">
                    <caption>{{ gpRoles.userRolesCategoriesI18n[cat] |
                        translate }}</caption>
                    <thead class="mb-3">
                        <tr>
                            <th style="width:40%; padding-bottom: 1rem;" scope="col"></th>
                            <th style="width:40%" scope="col"></th>
                            <th style="width:20%" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr *ngFor="let r of availableUserRoles" (click)="selectRole($event, r.id)">
                            <ng-container *ngIf="categoryIncludesRole(cat, r.name)">
                                <td>{{r.appName}} - {{r.name}}</td>
                                <td>
                                    {{ gpRoles.selectableRoles?.[cat]?.[r.name]?.help | translate }}
                                </td>
                                <td>
                                    <div class="form-switch float-end">
                                        <input class="form-check-input" type="checkbox" role="switch" [id]="r.id"
                                            [checked]="selectedUserRoleIds.includes(r.id)">
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                </table>
            </div>
        </div>
        <div class="actions">
            <button class="btn btn-primary" (click)="updateRules();">{{ 'pages.generic.update' | translate }}</button>
        </div>
    </div>
</div>

<div *ngIf="loading" class="d-flex justify-content-center mt-5">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
