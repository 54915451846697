import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { defaultFilterParameters } from 'src/app/dashboard/analytics/analytics.component';
import { Utilities } from '../../utilities';
import { FilterParameters } from '../../model/filterParameters';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-metrics-filter',
  standalone: true,
  imports: [TranslateModule, FormsModule],
  templateUrl: './metrics-filter.component.html',
  styleUrl: './metrics-filter.component.scss'
})
export class MetricsFilterComponent implements OnInit {
  @Input() defaultParameters: FilterParameters;
  @Input() customRangeRadioButtons!: {label: string; key: string}[];
  @Input() customDateRadioButtons!: {label: string; key: string}[];
  @Output() filter = new EventEmitter<FilterParameters>();

  public dateRadioButtons = [
    { label: 'pages.dashboard.analytics.allTime', key: 'all'},
    { label: 'pages.dashboard.analytics.last6Months', key: 'lastSixMonth'},
    { label: 'pages.dashboard.analytics.lastMonth', key: 'lastMonth'},
    { label: 'pages.dashboard.analytics.last2Weeks', key: 'lastTwoWeek'},
    { label: 'pages.dashboard.analytics.lastWeek', key: 'lastWeek'},
    { label: 'pages.dashboard.analytics.custom', key: 'custom'},
  ];
  public rangeRadioButtons = [
    { label: 'pages.dashboard.analytics.allUsers', key: 'all'},
    { label: 'pages.dashboard.analytics.currentTenant', key: 'tenant'},
    { label: 'pages.dashboard.analytics.currentUser', key: 'user'},
  ];

  public selectedRadioDate: string;
  public selectedRadioRange: string;
  public createdFrom: string;
  public createdTo: string;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    if (this.customRangeRadioButtons) {
      this.rangeRadioButtons = this.customRangeRadioButtons;
    }
    if (this.customDateRadioButtons) {
      this.dateRadioButtons = this.customDateRadioButtons;
    }
    if (!this.defaultParameters) {
      this.defaultParameters = defaultFilterParameters;
    }
    [this.createdFrom, this.createdTo, this.selectedRadioRange, this.selectedRadioDate] = [...Object.values(this.defaultParameters)];
    this.onDateRadioChange(this.selectedRadioDate);
  }

  public onFilter() {
    this.filter.emit(this.getFilterParameters());
  }

  public getFilterParameters(): FilterParameters {
    return {
      createdFrom: this.createdFrom,
      createdTo: this.createdTo,
      selectedRadioRange: this.selectedRadioRange,
      selectedRadioDate: this.selectedRadioDate
    };
  }

  public onDateRadioChange(value: string) {
    if (this.selectedRadioDate !== value) {
      this.selectedRadioDate = value;
      switch(this.selectedRadioDate) {
        case 'all':
          this.createdFrom = Utilities.getZeroDate();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastTwelveMonth':
          this.createdFrom = Utilities.get12MonthsAgo();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastSixMonth':
          this.createdFrom = Utilities.get6MonthsAgo();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastThreeMonth':
          this.createdFrom = Utilities.get3MonthsAgo();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastTwoMonth':
          this.createdFrom = Utilities.get2MonthsAgo();
          this.createdTo = Utilities.getToday();
            break;
        case 'lastMonth':
          this.createdFrom = Utilities.get1MonthAgo();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastTwoWeek':
          this.createdFrom = Utilities.getTwoWeeksAgo();
          this.createdTo = Utilities.getToday();
          break;
        case 'lastWeek':
          this.createdFrom = Utilities.getOneWeekAgo();
          this.createdTo = Utilities.getToday();
          break;
      }
      this.onFilter();
    }
  }

  public onRangeRadioChange(value: string) {
    if (this.selectedRadioRange !== value) {
      this.selectedRadioRange = value;
      this.onFilter();
    }
  }
  
}
