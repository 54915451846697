<div class="container">
    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 text-start justify-content-start">

        <highcharts-chart class="chart"
            [Highcharts]="highcharts"
            [options]="catColumnChartOptions"
            [(update)]="updateFlag"
            [oneToOne]=true
            [style.width]="width"
            [style.height]="height">
        </highcharts-chart>

    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-center">
        <ng-template #actor>{{'pages.securitycase.threatActorDesc' | translate}}</ng-template>
        <ng-template #killchain>{{'pages.securitycase.threatKillchainDesc' | translate}} </ng-template>
        <ng-template #action>{{'pages.securitycase.threatActionDesc' | translate}}</ng-template>
        <ng-template #asset>{{'pages.securitycase.threatAssetDesc' | translate}}</ng-template>
        <ng-template #attributes>{{'pages.securitycase.threatAttributesDesc' | translate}}</ng-template>

        <button *ngFor="let chart of chartsList"
            class="btn"
            [ngbTooltip]="chart.name"
            (click)="updateGraph(chart.name)">
            <fa-icon [icon]="chart.icon" size="2x"></fa-icon>
        </button>

    </div>
</div>
