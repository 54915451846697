<div class="error-page overflow-y-scroll">
    <section class="section flex items-center error-section">
        <div class="container">
            <div class="row">
                <div class="desk:col-5 hidden desk:block">
                    <div class="image background-cover ratio:16-9"
                        style="background-image: url('https://orangecyberdefense.com/uk/wp-content/uploads/sites/10/2020/02/U301137_medium.jpg')">
                    </div>
                </div>
                <div class="desk:has-offset-1"></div>
                <div class="desk:col-6 desk:align-start col-12 align-center">
                    <h1 class="title-h1 mb-20">{{ 'error.404.code' | translate }}</h1>
                    <h2 class="title-h2 mb-40">{{ 'error.401.label' | translate }}</h2>
                    <button class="btn btn-primary" routerLinkActive="active" routerLink="/dashboard">{{ 'pages.shared.returnToDashboard' | translate }}</button>
                </div>
            </div>
        </div>
    </section>
</div>
