<div class="section">
  <div class="mtt">
    <h3 class="title">
      {{ title | translate }}
      <app-help message="{{ tooltipText | translate }}"></app-help>
    </h3>
    @if (mttLoading) {
    <div class="row justify-content-center p-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
    } @else if (!errorMessage) {
    <div class="row">
      <div class="col-12 text-start justify-content-start">
        <highcharts-chart
          class="chart mtt"
          [Highcharts]="Highcharts"
          [options]="chartOptions"
          [style.width]="'100%'"
          [style.max-width]="graphMaxWidth">
        </highcharts-chart>
      </div>
    </div>
    } @else {
    <div class="alert alert-danger alert-sm" role="alert">
      <fa-icon [icon]="['fas', 'info-circle']" class="info-icon mx-2" size="xl" placement="left"></fa-icon>
      <p [ngClass]="{'error-message': true, 'light-theme': theme === 'light'}">{{ errorMessage }}</p>
    </div>
    }
  </div>
</div>
