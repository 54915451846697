import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: [
    '../abstract-question/abstract-question.component.scss',
    './single-select.component.scss'
  ]
})
export class RadioSingleSelectQuestionComponent extends AbstractQuestionComponent {

}
