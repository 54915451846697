import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalFeedbackParameters, NotificationParameters } from '../../modal';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  @Input() params: NotificationParameters;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService
  ) {}

  public openFeedback() {
    const params = new GlobalFeedbackParameters('feedback', null, null);
    this.modalService.openGlobalFeedback(params);
    this.close();
  }

  public close() {
    this.activeModal.close();
    if(this.params.closeCallback) {
      this.params.closeCallback();
    }
  }
}
